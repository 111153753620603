import {
  ImageSquare,
  PaperPlaneRight,
  PlusCircle,
  StopCircle,
} from "@phosphor-icons/react";
import { theme } from "../../commons/styles";
import { AudioRecorder } from "../audio";
import { useRef, useState } from "react";
import { ReactComponent as MasterI } from "../../assets/icons/master-i.svg";

export const ChatInput = ({
  loading,
  handleStop,
  currentPrompt,
  setCurrentPrompt,
  handleKeyDown,
  getAudioBlob,
  handleChatSubmit,
  showStop = true,
  showAudio = true,
  showFiles = false,
  showFilesMenu,
  toggleFilesMenu,
  handleImageUpload = () => {},
  disabled = false,
}) => {
  const [recording, setRecording] = useState(false);
  const [speaking, setSpeaking] = useState(false);
  const textareaRef = useRef(null);
  const AudioRecorderRef = useRef(null);
  // const [showFilesMenu, setShowFilesMenu] = useState(false);
  function onSubmit (e) {
    textareaRef.current.style.height = "24px";
    e.target.style.height = "24px";
    if(AudioRecorderRef?.current?.browserSupportsSpeechRecognition){
      AudioRecorderRef?.current?.stopRecognition();
    }
    handleChatSubmit();
  }
  function autoSizeInput(e) {
    textareaRef.current.style.height = "24px";
    e.target.style.height = `${e.target.scrollHeight}px`;
    // In case you have a limitation
    e.target.style.height = `${Math.min(e.target.scrollHeight, 250)}px`;

    // Reset height to original if there is no text
    if (e.target.scrollHeight <= "24px" && e.target.value === "") {
      e.target.style.height = "24px";
    }
  }

  return (
    <div className="flex-1 flex flex-col -md:p-[10px] gap-2 justify-center relative">
      {disabled && (
        <div
          style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
          className="absolute inset-0 z-10 cursor-not-allowed"
        />
      )}
      <div className="flex justify-center relative">
        {loading && showStop && (
          <button
            className="bg-[#f6f6f6] text-black dark:text-white shadow-primary px-3 py-2 rounded-md text-sm flex items-center gap-2 border border-[#f6f6f6] bottom-[-100%]"
            onClick={() => handleStop()}
          >
            <StopCircle size={theme.iconSize.medium} color={"#000"} />
            Stop Generating
          </button>
        )}
      </div>
      <div className="flex items-center md:gap-3 gap-2 w-full -md:m-0 mb-4">
        {showFiles && (
          <div className="relative">
            <PlusCircle
              className={showFilesMenu ? "rotate-45" : ""}
              size={theme.iconSize.large}
              color={theme.iconColor.primary}
              onClick={() => handleImageUpload()}
            />
            {showFilesMenu && (
              <div
                className={`absolute bottom-full right-0 w-56 p-3 rounded-xl bg-[#F9F9F9] border border-[#D1D1D1] ${
                  showFilesMenu ? "mb-2" : ""
                }`}
              >
                <div
                  className="flex flex-col gap-2 cursor-pointer"
                  onClick={() => {
                    handleImageUpload();
                  }}
                >
                  <div className="flex gap-2 items-center">
                    <ImageSquare size={16} color="#1E1E1E" />
                    <span className="text-base">Upload Image</span>
                  </div>
                  <span className="text-[#666666]">Jpeg, Jpg, Png</span>
                </div>
              </div>
            )}
          </div>
        )}
        <div
          className={`text-area-container border border-primary-border rounded-md px-3 py-2 w-[90%] flex justify-between items-center h-fit ${
            disabled ? "bg-gray-100 cursor-not-allowed" : ""
          }`}
        >
          <textarea
            ref={textareaRef}
            placeholder={
              recording ? "Listening to your voice..." : "Type a message"
            }
            value={currentPrompt}
            onChange={(e) => {
              if (e.target.value !== "\n") {
                setCurrentPrompt(e.target.value);
              }
              autoSizeInput(e);
            }}
            className={`w-full bg-transparent outline-none resize-none text-base flex-1 ${
              disabled ? "pointer-events-none" : ""
            }`}
            onKeyDown={handleKeyDown}
            rows={1}
            disabled={disabled}
          />
          {showAudio && !disabled && (
            <AudioRecorder
            recording={recording}
            setRecording={setRecording}
            getAudioBlob={getAudioBlob}
            speaking={speaking}
            setSpeaking={setSpeaking}
            setCurrentPrompt={setCurrentPrompt}
            autoSizeInput = {autoSizeInput}
            textareaRef = {textareaRef}
            ref = {AudioRecorderRef}
          />
          )}
        </div>
          <PaperPlaneRight
            size={theme.iconSize.large}
            color={theme.iconColor.primary}
            cursor={"pointer"}
            onClick={onSubmit}
          />
      </div>
      {disabled && (
        <div className="absolute top-[20%] left-[39%] z-20">
        <div className="flex items-center gap-1">
        <MasterI/>  
        <span className="text-centertext-gray-500 text-sm  font-normal font-['Inter'] leading-snug">
         Select a persona to start conversation
        </span>
        </div>  
        </div>
      )}
      <span className="text-center disclaimer-text text-[#798897] text-xs">
        Tagore AI strives for accuracy, but users should consider verifying
        important information due to the possibility of incomplete data
      </span>
    </div>
  );
};