import { Link } from "react-router-dom";
import { BsArrowRightShort } from "react-icons/bs";
import ChatbotHero from "../assets/ChatbotHero.png";
import ImageHero from "../assets/ImageHero.png";
import ContentCreator from "../assets/ContentCreator.png";
import CustomPersona from "../assets/CustomPersona.png";
import sachLogo from "../assets/Factly-Favicon.png";
import { TopNav } from "../components/layout/sidebar";
import { usePostHog } from "posthog-js/react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import useWindowSize from "../hooks/useWindowSize";

export function HomePage() {
  const posthog = usePostHog();

  const menuOptions = [
    {
      title: "Factly's Personas",
      description:
        "Personas are modelled on official information and datasets from Dataful (our in-house data portal). They aren't just tools; they are indispensable partners in your information journey, each endowed with a distinct purpose and unparalleled expertise.",
      image: sachLogo,
      link: "/personas",
    },
    {
      title: "Create Your Own Custom Persona and Chat Away!",
      description:
        "Unleash your creativity and build a personalized chat persona that reflects your unique style. Dive into the world of customized conversations by creating your own persona and engage in captivating chats tailored to your preferences. Click the card to  enjoy meaningful interactions with your very own custom chat persona.",
      image: CustomPersona,

      link: "/personas/create",
    },
    {
      title: "Explore the universe with our Chatbot",
      description:
        "Discover the wonders of AI chatbots with our versatile multipurpose companion. Engage in captivating conversations that offer assistance, insights, and entertainment, opening up a world of immersive experiences.",
      image: ChatbotHero,
      link: "/chats/dashboard",
    },
    {
      title: "Generate an Image",
      description:
        "Unleash Your Imagination with AI-Powered Image Generation: Create stunning, high-quality images effortlessly and explore endless visual possibilities",
      image: ImageHero,
      link: "/images",
    },
  ];

  const { email } = useSelector((state) => {
    return {
      email: state?.profile?.details?.email,
    };
  });

  useEffect(() => {
    if (email) {
      posthog.identify(email);
    }
  }, [email]);
  const { isMobileScreen } = useWindowSize();

  return (
    <div className="flex flex-col min-h-full scrollbar-hide">
      {!isMobileScreen && (
        <div className="flex pl-10 pr-9 py-7 flex-row justify-end">
          <TopNav />
        </div>
      )}
      <div className="px-10 flex-1 bg-white rounded-[24px]">
        <div
          className="w-full grid grid-cols-1
        my-8 gap-4 md:grid-cols-2"
        >
          {menuOptions.map((option, index) => (
            <Link
              key={option.title + "_" + index}
              to={option.link}
              className="p-4 cursor-pointer flex flex-col md:flex-row gap-4 transition-all focus:ring-gray-400 focus:shadow-xl duration-150 rounded-lg shadow-sm hover:shadow-lg relative hover:ring-gray-300 hover:ring-2 ring-1 ring-gray-200  bg-white dark:bg-background-sidebar-alt dark:text-white dark:ring-background-secondary-alt"
              onClick={() => {
                posthog.capture("homepage_card_clicked", {
                  title: option.title,
                });
              }}
            >
              <img
                className="md:max-w-[12rem] w-full h-full"
                src={option.image}
                alt={option.title}
              />
              <div className="flex gap-2 flex-col">
                <h3 className="text-lg font-semibold text-gray-700 dark:text-white">
                  {option.title}
                </h3>
                <p className="text-gray-500 flex-1 line-clamp-6 text-base dark:text-gray-200">
                  {option.description}
                </p>
                <BsArrowRightShort className="w-8 h-8 text-gray-700 ml-auto dark:text-white" />
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}
