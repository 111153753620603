import { CiCirclePlus } from "react-icons/ci";
export function CreateButton({
  text = "Create",
  onClick = () => {},
  icon = true,
}) {
  return (
    <button
      type="button"
      className={`w-fit p-1 md:px-3 md:py-2 bg-black dark:bg-white  text-base dark:text-black text-white rounded-full md:rounded-lg cursor-pointer flex flex-row items-center justify-center ${
        text ? "gap-2" : ""
      }`}
      onClick={onClick}
    >
      {icon && <CiCirclePlus className="w-6 h-6" />}
      <p className="whitespace-nowrap hidden md:block">{text}</p>
    </button>
  );
}
