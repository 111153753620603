import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as DotsThreeCircle } from "../../assets/icons/DotsThreeCircle.svg";
import { RxCross1 } from "react-icons/rx";
import Modal from "../../components/Modal";
import { UserCircleGear } from "@phosphor-icons/react";

export default function PersonaCard({
  name,
  desc,
  image,
  id,
  isDefault,
  handleDelete,
  onCardClick,
  isOwner,
  meta_id,
}) {
  const navigate = useNavigate();
  const [menuOptionsVisible, setMenuOptionsVisible] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // handleCardClick is a function that is passed as a prop to the PersonaCard component
  // It is called when the PersonaCard is clicked
  const handleCardClick = () => {
    onCardClick();
  };

  // handleSettingsClick is a function that is passed as a prop to the PersonaCard component
  // It is called when the settings icon is clicked
  // It navigates to the access page for the persona
  const handleSettingsClick = (e) => {
    e.stopPropagation();
    navigate(`/personas/access?meta_id=${meta_id}`);
  };

  return (
    <>
      <div
        className="rounded-lg overflow-hidden relative md:h-[250px] -md:gap-4 -md:h-[276px] -md:bg-white -md:rounded-[10px]  -md:border-zinc-100 w-full flex -md:flex-col flex-row border border-neutral-300 cursor-pointer"
        onClick={() => handleCardClick()}
      >
        <div className="w-[40%] -md:h-[164px] -md:w-full">
          <img
            src={image}
            className="h-[100%] w-[100%] bg-[#ffffff] dark:bg-background-secondary-alt object-fill"
          />
        </div>
        <div className="flex flex-col w-[60%] -md:w-full md:p-5 justify-between">
          <div>
            <div className="m-3 -md:my-0">
              <h2 className="text-stone-900 text-base -md:text-sm font-medium leading-tight dark:text-white line-clamp-1 ">
                {name}
              </h2>
              {!isDefault && (
                <button
                  className="flex items-center absolute  top-[4%] right-[3%] justify-center "
                  onClick={(e) => {
                    e.stopPropagation();
                    setMenuOptionsVisible((prev) => !prev);
                  }}
                >
                  {menuOptionsVisible ? (
                    <RxCross1 className="text-base h-[22px] w-[22px] text-[#798797] dark:text-white" />
                  ) : (
                    <DotsThreeCircle />
                  )}
                </button>
              )}
              {menuOptionsVisible && !isDefault && (
                <div className="absolute top-10 right-0 z-10 w-36">
                  <div className="bg-white-30 dark:bg-background-sidebar-alt rounded-lg shadow-lg border dark:border-[#3b3b3b] border-[#D0D5DD]">
                    <ul className="flex flex-col p-2 m-0">
                      <li
                        className="flex items-center gap-2 p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-background-secondary-alt"
                        onClick={(e) => {
                          e.stopPropagation();
                          setMenuOptionsVisible(false);
                          navigate(`/personas/${id}/edit`);
                        }}
                      >
                        <span className="text-black-60 dark:text-white text-sm">
                          Edit
                        </span>
                      </li>
                      <hr className="h-px bg-gray-300 dark:bg-[#3b3b3b] border-0 w-full my-1"></hr>
                      <li
                        className="flex items-center gap-2 p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-background-secondary-alt"
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowDeleteModal(true);
                          setMenuOptionsVisible(false);
                        }}
                      >
                        <span className="text-black-60 dark:text-white text-sm">
                          Delete
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
            <div className="m-3 -md:my-0">
              <p
                className="text-stone-500 text-sm -md:text-xs -md:leading-[18px] font-normal leading-[24px] dark:text-gray-200 line-clamp-4 pb-[0.14em] "
                title={desc}
              >
                {desc}
              </p>
            </div>
          </div>
          <div className="flex items-center justify-end">
            {isDefault && isOwner && (
              <div title="Manage Access">
                <UserCircleGear
                size={24}
                className="hover:scale-110 text-stone-500"
                onClick={(e) => handleSettingsClick(e)}
              />
              </div>
            )}
            {/* {isDefault && isOwner && (
              <Link
                className=" text-blue-500 py-2 px-4 rounded-md flex items-center hover:scale-105"
                to={`/personas/access?meta_id=${meta_id}`}
              >
                Manage Access
              </Link>
            )} */}
            {/* <span
              className="mt-4 text-blue-500 py-2 px-4 rounded-md flex items-center hover:scale-105"
              onClick={() => onCardClick()}
            >
              Start Conversation
            </span> */}
          </div>
        </div>
        <Modal
          open={showDeleteModal}
          onClose={() => {
            setShowDeleteModal(false);
          }}
          closeButton={false}
        >
          <div className="text-left">
            <h3 className="text-xl font-medium mb-4 border-b pb-4 border-gray-300">
              Delete Document
            </h3>
            <div className="bg-red-100 p-4 text-[#FF0000] rounded-md mb-4 max-w-md font-semibold">
              Continuing with this action will delete the document permanently.
              Do you really want to delete the document?
            </div>
            <div className="flex justify-end w-full gap-4 border-t pt-4 border-gray-300">
              <div>
                <button
                  className="bg-background-secondary hover:bg-gray-200 text-gray-800 py-2 px-4 rounded"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowDeleteModal(false);
                  }}
                >
                  Cancel
                </button>
              </div>
              <div>
                <button
                  className="bg-[#FF0000] hover:bg-red-600 text-white py-2 px-4 rounded"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDelete(id);
                    setShowDeleteModal(false);
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}
