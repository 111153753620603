export function FollowUpQuestions({
  followups = [],
  handleFollowUp = () => {},
}) {
  return (
    <div className="grid grid-cols-2 gap-2 mb-10 -md:m-0">
      {followups?.map((followup) => (
        <div className="w-full">
          <div
            className="bg-[#f9f9f9] text-black  dark:text-white  shadow-primary px-3 py-2 rounded-md text-sm flex gap-2 border border-[#f6f6f6] w-4/5 -md:w-[unset] cursor-pointer h-full"
            onClick={() => {
              handleFollowUp(followup);
            }}
          >
            <span className="text-sm font-[#666]">{followup}</span>
          </div>
        </div>
      ))}
    </div>
  );
}
