import { Link } from "react-router-dom";
import BlogPost from "../../assets/blogpost.svg";
import Factcheck from "../../assets/factcheck.svg";
import { BsArrowRightShort } from "react-icons/bs";
import { TopNav } from "../../components/layout/sidebar";

export const Workflow = () => {
  const menuOptions = [
    {
      title: "Write a blog post",
      description:
        "Create engaging blog posts with our Blog Post Writer Chatbot. Crafted for accuracy and engagement, it delves into various topics, exposing misinformation. Educate, inform, and empower your audience with compelling blog posts tailored to their chosen topic and desired tone.",
      image: BlogPost,
      link: "/workflows/blogpost",
    },
    // {
    //   title: "Write a fact check",
    //   description:
    //     "Introducing our Fact Check Article Writer Chatbot, your go-to companion for crafting meticulously researched and reliable fact-check articles.",
    //   image: Factcheck,
    //   link: "/workflows/fact-check",
    // },
    {
      title: "Write a social media compaign",
      description:
        "Improve your social media strategy through our straightforward workflow. Provide your topic, key points, and desired platform. Our user-friendly interface will assist you in creating engaging content for a strong online presence. Enhance your social media reach effortlessly.",
      image: Factcheck,
      link: "/workflows/social-media",
    },
  ];

  return (
    <>
      <div className="topNavSection pl-10 pr-9 py-7 justify-end flex">
        <TopNav />
      </div>
      <div className="mb-16 p-10 bg-white rounded-[24px] min-h-[95vh]">
        <div className="flex flex-row justify-between  items-center ">
          <h2 className="text-3xl mt-8 md:mt-0 font-medium dark:text-white">
            Workflows
          </h2>
        </div>
        <div className="w-full grid grid-cols-1 lg:mt-12 mt-8 gap-4 sm:grid-cols-2">
          {menuOptions.map((option) => (
            <Link
              to={option.link}
              className="p-4 cursor-pointer flex flex-col md:flex-row gap-4 transition-all focus:ring-gray-400 focus:shadow-xl duration-150 rounded-lg shadow-sm hover:shadow-lg relative hover:ring-gray-300 hover:ring-2 ring-1 ring-gray-200  bg-white dark:bg-background-sidebar-alt dark:text-white dark:ring-background-secondary-alt"
            >
              <img
                className="md:w-48 w-full h-full"
                src={option.image}
                alt={option.title}
              />
              <div className="flex gap-2 flex-col">
                <h3 className="text-lg font-semibold text-gray-700 dark:text-white">
                  {option.title}
                </h3>
                <p className="text-gray-500 flex-1 line-clamp-6 text-base dark:text-gray-200">
                  {option.description}
                </p>
                <BsArrowRightShort className="w-8 h-8 text-gray-700 ml-auto dark:text-white" />
              </div>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};
