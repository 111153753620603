import useRandomColor from "../../hooks/useRandomColor";
import { getStylesBySize } from "./avatar.styles";

export const Avatar = ({
  size = "medium",
  type = "alphabet",
  data = "-", // if type is alphabet, data is the first letter of the name and if type is image, data is the image url and if type is icon, data is the icon component
  backgroundColor,
  textColor = "#fff",
  onClick = () => {},
  pointer = false,
  isPrimary = false,
}) => {
  // generate a random hex color useMemo to avoid re-rendering and re-generating the color
  const color = useRandomColor();

  const renderContent = () => {
    switch (type) {
      case "image":
        return (
          <img
            src={data}
            alt="avatar"
            className={`rounded-full ${pointer && "cursor-pointer"}`}
          />
        );
      case "icon":
        const IconComponent = data;
        return (
          <IconComponent
            className={`text-[${textColor}] ${pointer && "cursor-pointer"}`}
            weight="bold"
          />
        );
      default:
        return (
          <span
            className={`text-[${textColor}] ${pointer && "cursor-pointer"}`}
          >
            {data}
          </span>
        );
    }
  };

  return (
    <div
      className={`rounded-full flex items-center justify-center ${
        isPrimary ? "bg-[#e93648]" : backgroundColor
      } ${getStylesBySize(size)}`}
      onClick={() => onClick()}
    >
      {renderContent()}
    </div>
  );
};
