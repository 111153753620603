const intialState = {
  access: false,
  loading: false,
};

const appAccessReducer = (state = intialState, action) => {
  switch (action.type) {
    case "ADD_APP_ACCESS":
      return {
        ...state,
        access: action.payload,
      };
    case "SET_APP_ACCESS_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default appAccessReducer;
