const intialState = {
  details: {},
  loading: false,
};

const profileReducer = (state = intialState, action) => {
  switch (action.type) {
    case "ADD_PROFILE":
      return {
        ...state,
        details: action.payload,
      };
    case "SET_PROFILE_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default profileReducer;
