import {
  Export,
  GearSix,
  PlusCircle,
  SidebarSimple,
} from "@phosphor-icons/react";
import { theme } from "../../commons/styles";
import { ButtonWithIcon } from "../buttons/buttonWithIcon";

import { Link } from "react-router-dom";
import { ConnectedClip, MaxMessageClip } from "../clip";
import hamburger from "../../assets/mobile-list.svg";
import options from "../../assets/mobile-chat-dots-three-circle.svg";
import optionsDark from "../../assets/dots-three-circle-black.svg";
import feedback from "../../assets/lifebuoy.svg";
import help from "../../assets/question.svg";
import share from "../../assets/export.svg";
import { useRef, useState } from "react";
import useOutsideClick from "../../hooks/useOutsideClick";
import { models } from "../../pages/personas/factlyPersona";
import Select from "react-select";
import { ActivePersona as MasterActivePersona, getLabelByValue as getMasterLabelByValue } from "../../pages/personas/masterPersonaUi/masterPersonaPrompts";

// gpt4 = "gpt4"
// gpt4_turbo = "gpt4_turbo"
// gpt35turbo = "gpt35turbo"
// gemini_pro = "gemini_pro"
// claude2 = "claude2"
const getModelLabel = (model) => {
  switch (model) {
    case "gpt4":
      return "GPT-4";
    case "gpt35turbo":
      return "GPT-3.5-turbo";
    case "gpt4_turbo":
      return "GPT-4 Turbo";
    case "gemini_pro":
      return "Gemini Pro";
    case "claude2":
      return "Claude 2";
    default:
      return "GPT-4";
  }
};

export const ChatNavbar = ({
  sidebarVisible,
  handleNewChat,
  masterPersona,
  chat,
  toggleSidebar,
  showSettings,
  setShowSettings = () => {},
  modelName,
  handleShareLink,
  showShare = true,
  showSettingsIcon = true,
  persona,
  personaName,
  isPersona = false,
  persona_description = "",
  handlePersonaModel = () => {},
  isDesktopScreen,
  showLimit = false,
  limit = 0,
  isConnected,
  optionModel,
  showConnectionStatus 
}) => {
  const isMaster = persona === "master"
  const [optionSelected, setOptionsSelected] = useState(false);
  const handleOptionClick = () => {
    setOptionsSelected((prev) => !prev);
  };
  const [optionDesktopSelected, setOptionsDesktopSelected] = useState(false);
  const handleOptionDesktopClick = () => {
    setOptionsDesktopSelected((prev) => !prev);
  };

  const isMobileScreen = !isDesktopScreen;
  if (isPersona && isMobileScreen) {
    return (
      <MobilePersonaNavItems
        toggleSidebar={toggleSidebar}
        personaName={personaName}
        modelName={modelName}
        limit={limit}
        optionSelected={optionSelected}
        handleOptionClick={handleOptionClick}
        handleShareLink={handleShareLink}
        setOptionsSelected={setOptionsSelected}
      />
    );
  }
  return (
    <div
      className={`flex -md:py-7 px-4 ${!isMaster?"justify-center":""} md:flex-1 border-b border-b-primary-border items-center md:px-10 relative ${
        (!showSettingsIcon && !showShare && !sidebarVisible) || isPersona
          ? "justify-start"
          : "md:justify-center"
      }`}
    >
      {!sidebarVisible && isDesktopScreen && (
        <div className={`flex h-3/5 items-center`}>
          <ButtonWithIcon
            icon={<PlusCircle size={theme.iconSize.medium} color="#fff" />}
            isPrimary={true}
            onClick={() => handleNewChat()}
            disabled={chat?.messages?.length === 0}
          >
            New Chat
          </ButtonWithIcon>
          <div className="p-3">
            <SidebarSimple
              weight="duotone"
              className={`text-black border ${
                !sidebarVisible ? "rounded-md" : "rounded-full"
              } p-1`}
              size={theme.iconSize.large}
              cursor={"pointer"}
              onClick={() => toggleSidebar()}
            />
          </div>
        </div>
      )}
      {isMobileScreen && !isPersona && (
        <img src={hamburger} alt="logo" onClick={toggleSidebar} />
      )}
      {showSettingsIcon && !isPersona && (
        <div
          className={`flex items-center gap-2 absolute ml-auto mr-auto ${
            showSettings && "bg-[#F3EED9] p-2 rounded-md"
          }`}
          onClick={() => setShowSettings((prev) => !prev)}
        >
          <span className="text-[#798897] cursor-pointer">
            Model: {modelName}
          </span>
          <GearSix
            size={
              isMobileScreen ? theme.iconSize.medium : theme.iconSize.medium
            }
            color="#798897"
            cursor={"pointer"}
          />
        </div>
      )}
      {isMaster && isPersona && getMasterLabelByValue(masterPersona) && (
        <div className="flex justify-between flex-1">
          <MasterActivePersona persona={getMasterLabelByValue(masterPersona)} />
          <img
            src={!optionDesktopSelected ? options : optionsDark}
            alt="logo"
            onClick={handleOptionDesktopClick}
          />
          <DesktopOptions
            handleShareLink={handleShareLink}
            optionDesktopSelected={optionDesktopSelected}
            setOptionsDesktopSelected={setOptionsDesktopSelected}
          />
        </div>
      )}
      {isPersona && !isMaster && (
        <div className="flex gap-2 items-center justify-between w-full">
          {/* persona details */}
          <div className="flex flex-col gap-2 items-start w-1/2 p-2">
            <span className="text-lg font-semibold text-gray-800">
              {persona}
            </span>
            <span className="text-sm text-gray-600">{persona_description}</span>
          </div>
           <div className="flex items-center gap-2">
            {showLimit && <MaxMessageClip msgCount={limit} />}
           
            <Select
                placeholder = "select a model"
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "black",
                    primary25: "#EAEAEA",
                    primary50: "#EAEAEA",
                    primary75: "#EAEAEA",
                  },
                })}
                value={optionModel}
                defaultValue={optionModel}
                isDisabled= {chat?.messages?.length > 0}
                onChange={handlePersonaModel}
                className="flex-1"
                options={models}
              />
              <div className="relative">
           <img
            src={!optionDesktopSelected? options : optionsDark}
            alt="logo"
            onClick={handleOptionDesktopClick}
          />
           <DesktopOptions 
           handleShareLink={handleShareLink}
           optionDesktopSelected={optionDesktopSelected}
           setOptionsDesktopSelected={setOptionsDesktopSelected}
           />
           </div>
            {/* // <Link
            //   to={window.REACT_APP_PERSONA_FEEDBACK_URL}
            //   className="text-blue-500 cursor-pointer"
            //   target="_blank"
            // >
            //   Send Feedback
            // </Link>
            // <Link to={window.REACT_APP_TAGORE_DOC_URL} target="_blank">
            //   <Question
            //     size={theme.iconSize.medium}
            //     color="#798897"
            //     cursor={"pointer"}
            //   />
            // </Link>
            // <Export
            //   size={theme.iconSize.medium}
            //   color="#666"
            //   onClick={() => handleShareLink()}
            //   cursor={"pointer"}
            // /> */}
          </div> 
        </div>
      )}
      {showShare && (
        <Export
          size={isMobileScreen ? theme.iconSize.medium : theme.iconSize.large}
          color="#666"
          className="ml-auto"
          onClick={() => handleShareLink()}
          cursor={"pointer"}
        />
      )}
    </div>
  );
};

const MobilePersonaNavItems = ({
  toggleSidebar,
  personaName,
  limit,
  optionSelected,
  handleOptionClick,
  handleShareLink,
  setOptionsSelected,
  setShowSettings = () => {},
  showSettings = false, 
  modelName
}) => {
  return (
    <div className="border border-[#D1D1D1] bg-[#FFF] w-full h-[64px] flex px-[18px] py-[28px] justify-between items-center">
      <img src={hamburger} alt="logo" onClick={toggleSidebar} />
      <div className="flex flex-col items-center">
      <span className="text-stone-900 text-xs font-medium font-['Inter'] leading-normal">
        {personaName}
      </span>
      <div
          className={`flex items-center gap-2 ml-auto mr-auto 
          ${
            showSettings && "bg-[#F3EED9] p-2 rounded-md"
          }`
        }
          onClick={() => setShowSettings((prev) => !prev)}
        >
          <span className="text-[#798897] cursor-pointer">
          {modelName}
          </span>
        </div>
      </div>
      <div className="flex gap-[10px] items-center">
        <MaxMessageClip isMobile={true} msgCount={limit} />
        <div className="relative">
          <img
            src={!optionSelected ? options : optionsDark}
            alt="logo"
            onClick={handleOptionClick}
          />
          <MobileOptions
            optionSelected={optionSelected}
            handleShareLink={handleShareLink}
            setOptionsSelected={setOptionsSelected}
          />
        </div>
      </div>
    </div>
  );
};


const DesktopOptions = ({
  optionDesktopSelected,
  handleShareLink,
  setOptionsDesktopSelected,
}) => <MobileOptions
optionSelected = {optionDesktopSelected}
handleShareLink = {handleShareLink}
setOptionsSelected = {setOptionsDesktopSelected}
/>

const MobileOptions = ({
  optionSelected,
  handleShareLink,
  setOptionsSelected,
}) => {
  const ref = useRef();
  useOutsideClick(ref, () => {
    setOptionsSelected(false);
  });
  const onShare = () => {
    setOptionsSelected((prev) => !prev);
    handleShareLink();
  };
  if (!optionSelected) return null;
  return (
    <div
      ref={ref}
      className="w-[168px] right-0 z-10 absolute top-14 h-[158px] p-5 bg-white rounded-xl shadow flex-col justify-start items-center gap-3.5 inline-flex"
    >
      <div
        onClick={onShare}
        className="self-stretch justify-between items-center inline-flex"
      >
        <div className="text-stone-900 text-sm font-normal font-['Inter']">
          Share
        </div>
        <img src={share} className="w-5 h-5 relative" alt="Share icon" />
      </div>
      <div className="self-stretch h-px bg-neutral-300 rounded-sm" />
      <Link
        to={window.REACT_APP_TAGORE_DOC_URL}
        className="w-full"
        target="_blank"
      >
        <div className="self-stretch justify-between items-center w-full inline-flex">
          <div className="text-stone-900 text-sm font-normal font-['Inter']">
            Help
          </div>
          <img src={help} className="w-5 h-5 relative" alt="Help icon" />
        </div>
      </Link>
      <div className="self-stretch h-px bg-neutral-300 rounded-sm" />
      <Link
        to={window.REACT_APP_PERSONA_FEEDBACK_URL}
        className="text-blue-500 cursor-pointer w-full"
        target="_blank"
      >
        <div className="self-stretch justify-between items-center w-full inline-flex">
          <div className="text-stone-900 text-sm font-normal font-['Inter']">
            Send feedback
          </div>
          <img
            src={feedback}
            className="w-5 h-5 relative"
            alt="Feedback icon"
          />
        </div>
      </Link>
    </div>
  );
};
