import { Outlet } from "react-router-dom";
import Sidebar, { TopNav } from "./sidebar";

import { useState } from "react";
import { ToastContainer } from "react-toastify";
import useWindowSize from "../../hooks/useWindowSize";
import "react-toastify/dist/ReactToastify.css";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoMdClose } from "react-icons/io";
import { ReactComponent as TagoreLogoDark } from "../../assets/icons/tagoreAiBlackMobile.svg";
import { withOrg } from "../organisation/withOrg";

function Layout({ selectedOrg }) {
  const { isMobileScreen } = useWindowSize();
  const [sideBarOpen, setSidebarOpen] = useState(false);

  return (
    <div
      className={`${
        isMobileScreen ? "w-full flex-wrap" : ""
      } flex flex-row h-dvh max-h-dvh overflow-y-auto bg-[#F9F9F9]`}
    >
      <div className="">
        {isMobileScreen && (
          <nav className="w-screen flex justify-between items-end fixed top-0 bg-white dark:bg-background-sidebar-alt px-8 pb-4 pt-3 z-30">
            <div onClick={() => setSidebarOpen(!sideBarOpen)}>
              {sideBarOpen ? (
                <IoMdClose className="w-6 h-6 dark:text-white cursor-pointer" />
              ) : (
                <RxHamburgerMenu className="w-6 h-6 dark:text-white cursor-pointer" />
              )}
            </div>
            <TagoreLogoDark />
            <TopNav />
          </nav>
        )}
        <Sidebar
          selectedOrg={selectedOrg}
          sideBarOpen={sideBarOpen}
          setSidebarOpen={setSidebarOpen}
        />
      </div>
      <main
        className={`${
          isMobileScreen
            ? "mt-20 w-full"
            : "w-full max-h-dvh overflow-y-auto"
        } dark:bg-background-secondary-alt`}
      >
        <Outlet />
      </main>
      <ToastContainer
        toastClassName={({ type }) =>
          type === "error"
            ? "w-[340px] border-l-[12px] border-[#DA3125] rounded-md shadow-lg bg-[#FFF]"
            : type === "success"
            ? "w-[340px] border-l-[12px] border-[#03C04A] rounded-md shadow-lg bg-[#FFF]"
            : type === "warning"
            ? "w-[340px] border-l-[12px] border-[#EA8700] rounded-md shadow-lg bg-[#FFF]"
            : ""
        }
        className="space-y-4  "
      />
    </div>
  );
}

export default withOrg(Layout);
