import React from 'react';
import { ReactComponent as Chats } from "../../../assets/chats-master-icon.svg";
import { ReactComponent as Countdown } from "../../../assets/clock-countdownmaster-icon.svg";
import { ReactComponent as Database } from "../../../assets/database-master.svg";
import { ReactComponent as CheckedCircle } from "../../../assets/icons/check-circle-master.svg";
import { ReactComponent as UnCheckedCircle } from "../../../assets/icons/unchecked-circle-master.svg";
import { ReactComponent as TagoreChatIcon } from "../../../assets/chat-main-master.svg";
import { ReactComponent as CountdownSwitch } from "../../../assets/icons/clock-countdown-master-switch.svg";
import { ReactComponent as DatabaseSwitch } from "../../../assets/icons/database-master-switch.svg";
import { ReactComponent as ChatsSwitch } from "../../../assets/icons/master-switch-chat.svg";
export const MasterOptionsData = [
  {
    label: "Fact checks",
    description:
      "Chat with a vast database containing hundreds of thousands of fact-checks and get verified information on numerous false social media claims.",
    value: "master-fact-check",
    icon: <Chats />,
    prompts: ["Switch to Fact checks"],
    switchIcon: <ChatsSwitch />
  },
  {
    label: "Official information",
    description:
      "Chat with multiple sources of official information in one place. Access information from Lok Sabha and Rajya Sabha answers, official press releases, central acts, and budget speeches.",
    value: "master-info",
    prompts: ["Switch to Official Information"],
    icon: <Countdown />,
    switchIcon: <CountdownSwitch />
  },
  {
    label: "Official Data",
    description:
      "Chat with data retrieved from various official sources like parliament answers and official data compiled on our data portal, Dataful.",
    value: "master-data",
    prompts: ["Switch to Official Data"],
    icon: <Database />,
    switchIcon: <DatabaseSwitch/>
  },
];

const MasterPersonaOption = ({
  label,
  value,
  description,
  icon,
  isSelected,
  setMasterPersona,
}) => {
  const handleOptionClick = () => {
    setMasterPersona(value);
  };
  return (
    <div
      className={`flex-1 min-h-[218px] px-4 py-3 ${
        isSelected ? "bg-orange-100" : "bg-white"
      } rounded-[10px] border border-zinc-100 flex-col justify-center items-start inline-flex`}
      onClick={handleOptionClick}
    >
      <div className="self-stretch grow shrink basis-0 flex-col justify-between items-end flex">
        <div className="self-stretch h-[102px] flex-col justify-start items-start gap-3 flex">
          <div className="justify-start items-center gap-1.5 inline-flex">
            <div className="w-6 h-6 relative">{icon}</div>
            <div className="text-stone-900 text-base font-medium font-['Inter'] capitalize leading-[17.60px]">
              {label}
            </div>
          </div>
          <div className="self-stretch text-stone-500 text-sm font-normal font-['Inter'] leading-snug">
            {description}
          </div>
        </div>
        <div className="w-6 h-6 relative">
          {isSelected ? <CheckedCircle /> : <UnCheckedCircle />}
        </div>
      </div>
    </div>
  );
};

const OptionsIntro = () => {
  return (
    <div className="w-[294px] mx-auto h-[92px] flex-col justify-start items-center gap-8 inline-flex">
      <div className="w-[42.72px] h-10 relative"><TagoreChatIcon /></div>
      <div className="text-center text-neutral-700 text-2xl font-medium font-['Inter'] leading-tight">
        What can I help you with?
      </div>
    </div>
  );
};

const MasterPersonaOptions = ({ setMasterPersona, masterPersona }) => {
  return (
    <>
      <OptionsIntro />
      <div className="flex gap-4">
        {MasterOptionsData.map((Option, index) => (
          <MasterPersonaOption
            key={index}
            isSelected={masterPersona === Option.value}
            setMasterPersona={setMasterPersona}
            {...Option}
          />
        ))}
      </div>
    </>
  );
};

export default MasterPersonaOptions;
