export const getasstNamefromCode = (code) => {
  let asstTable = {
    lse: "BallotVerse India",
    ibs: "Budget Speak",
    csr: "CSR Explorer",
    tt: "Trade Tracer",
  };

  // handle if code matches with any of the keys in asstTable
  // return it or return lse as default
  return asstTable[code] || asstTable["lse"];
};
