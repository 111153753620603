import { Warning } from "@phosphor-icons/react/dist/ssr";

export const MaxLimitReachedPersona = ({
  handleNewChat,
  handleExplorePersonas,
  convLimit,
}) => {
  return (
    <div className={`flex flex-col justify-center items-center gap-6`}>
      <Warning size={64} color="#FFBB33" />
      <div className={`flex flex-col gap-2 items-center`}>
        <span className={`text-xl text-[#1e1e1e]`}>
          Conversation limit reached
        </span>
        <span className={`text-[#666] text-center`}>
          Each conversation is limited to 5 user/human prompts and you can have
          maximum 5 conversations in a day.
        </span>
      </div>
      <div className="flex flex-col gap-4 w-full">
        {!convLimit && (
          <button
            className="text-center w-full py-3 px-4 bg-[#1e1e1e] text-white rounded-md"
            onClick={handleNewChat}
          >
            New Chat
          </button>
        )}

        <button
          className="border border-[#666] rounded-lg text-center w-full py-3 px-4"
          onClick={handleExplorePersonas}
        >
          Explore Personas
        </button>
      </div>
    </div>
  );
};
