import React from "react";
import ToggleSwitch from "../../../components/toggle";

function NotificationSettings() {
  // todo: handle state for the form
  // todo: add more options
  const [checked, setChecked] = React.useState(false);

  return (
    <div className="flex flex-col gap-y-4 w-full">
      <div className="flex flex-row justify-end items-end w-full pr-10 py-7 border-b border-[#D1D1D1]">
        <button className="bg-[#1E1E1E] text-white rounded-lg py-3 px-2 font-normal w-[144px] h-12">
          Save
        </button>
      </div>
      <div className="flex flex-col gap-y-7 mx-auto mt-6 w-[60vw]">
        <div className="flex flex-row justify-between py-6">
          <div className="flex flex-col gap-y-2">
            <h2 className="text-xl font-semibold">Email</h2>
            <span className="text-[#666] font-normal">
              Email on which you want to receive notifications
            </span>
          </div>
          <input
            className="w-[362px] h-12 rounded-lg border border-[#D1D1D1] px-4"
            type="email"
            placeholder="Enter your email"
          />
        </div>
        <div className="flex flex-row justify-between py-6">
          <div className="flex flex-col gap-y-2">
            <h2 className="text-xl font-semibold">Document Created</h2>
            <span className="text-[#666] font-normal">
              Automatically reopen the last document
            </span>
          </div>
          <div className="flex justigy-start items-center w-[362px]">
            <ToggleSwitch
              defaultChecked={checked}
              onChange={(checked) => setChecked(checked)}
            />
          </div>
        </div>
        <div className="flex flex-row justify-between py-6">
          <div className="flex flex-col gap-y-2">
            <h2 className="text-xl font-semibold">Invite accepted</h2>
            <span className="text-[#666] font-normal">
              Automatically reopen the last document
            </span>
          </div>
          <div className="flex justigy-start items-center w-[362px]">
            <ToggleSwitch
              defaultChecked={checked}
              onChange={(checked) => setChecked(checked)}
            />
          </div>
        </div>
        <div className="flex flex-row justify-between py-6">
          <div className="flex flex-col gap-y-2">
            <h2 className="text-xl font-semibold">Toggle</h2>
            <span className="text-[#666] font-normal">
              Automatically reopen the last document
            </span>
          </div>
          <div className="flex justigy-start items-center w-[362px]">
            <ToggleSwitch
              defaultChecked={checked}
              onChange={(checked) => setChecked(checked)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotificationSettings;
