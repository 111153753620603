export function ButtonWithIcon({
  icon,
  isPrimary,
  onClick,
  children,
  takeFullWidth,
  disabled,
}) {
  return (
    <button
      className={`flex justify-center items-center -md:w-[auto] ${
        isPrimary ? "bg-black-50" : ""
      } py-3 px-2 relative rounded-lg ${takeFullWidth ? "w-full" : "w-52"}
      ${disabled ? "opacity-50 cursor-not-allowed" : ""}
      `}
      onClick={!disabled ? onClick : () => {}}
    >
      <div className="absolute left-2">{icon}</div>
      <span className="text-base text-white">{children}</span>
    </button>
  );
}
