import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { routes } from "./routes";
import { Provider } from "react-redux";
import store from "./redux/store";
import "react-toastify/dist/ReactToastify.css";
import Tagore from "./Tagore";
import { PostHogProvider } from "posthog-js/react";

const router = createBrowserRouter(routes, {
  basename: window.REACT_APP_PUBLIC_URL,
});

const root = ReactDOM.createRoot(document.getElementById("root"));

const options = {
  api_host: window.REACT_APP_PUBLIC_POSTHOG_HOST,
};

root.render(
  <PostHogProvider
    apiKey={window.REACT_APP_PUBLIC_POSTHOG_KEY}
    options={options}
  >
    <Provider store={store}>
      <Tagore>
        <RouterProvider router={router} />
      </Tagore>
    </Provider>
  </PostHogProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
