export function getAssistantURL(assistant) {
  let personaURLObj = {
    parlens: window.REACT_APP_PARLENS_CHAT_SERVER_URL,
    pib: window.REACT_APP_PIB_CHAT_SERVER_URL,
    budgetspeech: window.REACT_APP_BUDGET_SPEECH_CHAT_SERVER_URL,
    sach: window.REACT_APP_SACH_CHAT_SERVER_URL,
  };

  return personaURLObj[assistant] || "";
}

export const getPersonaNameFromCode = (code) => {
  let personaNameObj = {
    parlens: "Parlens",
    pib: "PIB Chronicle",
    budgetspeech: "BudgetSpeak",
    sach: "SACH",
    tt: "TradeTracer",
    csr: "CSR Explorer",
    lse: "BallotVerse India",
    ibs: "Budget Speak",
    constitution: "constitution",
    central_acts:"Central Acts",
    factly_articles:"Factly Articles",
    dataful:"Dataful"
  };

  return personaNameObj[code] || "Sach";
};

export const getInitialFollowupQuestionsFromPersona = (persona) => {
  switch (persona) {
    case "parlens":
      return [
        "What is the amount of money collected through electoral bonds? Give year-wise data in a table format?",
        "How many startups are registered in India?",
        "Give details of different Vande Bharat train routes inaugurated.",
        "Give state-wise details about funds released under POSHAN Abhiyaan in 2019-20 in a table format.",
      ];

    case "pib":
      return [
        "What did PM Modi say about the One Nation One Election?",
        "When did the government approve the Kaladan project? Give me the timeline",
        "Give the state-wise number of LPG Connections under PMUY in a table format.",
        "Give statements made by the Health Minister on the Covid outbreak.",
      ];

    case "budgetspeech":
      return [
        "What did the Finance Minister say about Agriculture in 1993?",
        "Compare our defense priorities of 2022-23 to 1947-48.",
        "What were the comments of Manmohan Singh on liberalisation when he was the Finance Minister?",
        "What were the priority areas in the budgets presented by the NDA government in the last 10 years?",
      ];

    case "ibs":
      return [
        "What did the Finance Minister say about Agriculture in 1993?",
        "Compare our defense priorities of 2022-23 to 1947-48.",
        "What were the comments of Manmohan Singh on liberalisation when he was the Finance Minister?",
        "What were the priority areas in the budgets presented by the NDA government in the last 10 years?",
      ];

    case "sach":
      return [
        "Tell me about PM Kisan Tractor Yojana?",
        "Was any leader from India given the Most Honest Leader award in the USA?",
        "क्या वडनगर रेलवे स्टेशन 1973 से पहले अस्तित्व में था?",
        "Did BBC declare any political party as the most corrupt?",
      ];

    case "lse":
      return [
        "Who won the Mumbai North seat in the 2019 Lok Sabha Elections? What was the margin of the victory?",
        "Give the name of the top 10 winners of the 2019 Lok Sabha elections by the margin of victory in a table format.",
        "Which party received the most number of votes in Bihar in 2014?",
        "How many candidates contested in the Agra constituency in 2014? Give some of their names.",
      ];

    case "tt":
      return [
        "Provide me India's trade balance with China & USA in the last 10 years in a table format",
        "List in a table top-10 exporters to India and importers from India in 2022-23",
        "With which country did India have the highest negative trade balance in 2019-20?",
        "How much did India import from the G-4 countries in 2021-22? Give country-wise",
      ];

    case "csr":
      return [
        "Education or Health - which sector received more CSR funds in 2021-22?",
        "How much CSR amount was spent in the 'Kottayam' district in 2016?",
        "Which company spent the most on women empowerment in Telangana in 2020-21?",
        "Provide a list of companies that spent on education in the 'Jaisalmer' district in 2021-22.",
      ];
    default:
      return [];
  }
};

export function generateChatUrl(userId, chatId) {
  const baseUrl = window.REACT_APP_SACH_CHAT_SERVER_URL;
  const queryParams = new URLSearchParams({
    user_id: userId,
  });

  if (chatId) {
    queryParams.append("chat_id", chatId);
  }

  return `${baseUrl}?${queryParams.toString()}`;
}
