export const voiceMap = {
    'zh-CN': 'cmn-CN-Wavenet-A', // Mandarin Chinese
    'es-ES': 'es-ES-Wavenet-A', // Spanish
    'en-US': 'en-US-Wavenet-A', // English
    'ru-RU': 'ru-RU-Wavenet-A', // Russian
    'ar-SA': 'ar-XA-Wavenet-A', // Standard Arabic
    'bn-BD': 'bn-IN-Wavenet-A', // Bengali
    'hi-IN': 'hi-IN-Wavenet-A', // Hindi
    'pt-PT': 'pt-PT-Wavenet-A', // Portuguese
    'id-ID': 'id-ID-Wavenet-A', // Indonesian
    'ja-JP': 'ja-JP-Wavenet-A', // Japanese
    'fr-FR': 'fr-FR-Wavenet-A', // French
    'de-DE': 'de-DE-Wavenet-A', // German
    'jv-ID': 'id-ID-Wavenet-A', // Javanese (mapped to Indonesian as no Javanese voice is listed)
    'ko-KR': 'ko-KR-Wavenet-A', // Korean
    'te-IN': 'te-IN-Standard-A', // Telugu
    'vi-VN': 'vi-VN-Wavenet-A', // Vietnamese
    'mr-IN': 'mr-IN-Wavenet-A', // Marathi
    'it-IT': 'it-IT-Wavenet-A', // Italian
    'ta-IN': 'ta-IN-Wavenet-A', // Tamil
    'tr-TR': 'tr-TR-Wavenet-A', // Turkish
    'ur-PK': 'ur-IN-Wavenet-A', // Urdu
    'gu-IN': 'gu-IN-Wavenet-A', // Gujarati
    'pl-PL': 'pl-PL-Wavenet-A', // Polish
    'uk-UA': 'uk-UA-Wavenet-A', // Ukrainian
    'kn-IN': 'kn-IN-Wavenet-A', // Kannada
    'mai-IN': 'hi-IN-Wavenet-A', // Maithili (mapped to Hindi as no Maithili voice is listed)
    'ml-IN': 'ml-IN-Wavenet-A', // Malayalam
    'fa-IR': 'ar-XA-Wavenet-A', // Iranian Persian (mapped to Arabic as no Persian voice is listed)
    'my-MM': 'my-MM-Standard-A', // Burmese
    'sw-TZ': 'sw-TZ-Standard-A', // Swahili
    'su-ID': 'id-ID-Wavenet-A', // Sundanese (mapped to Indonesian as no Sundanese voice is listed)
    'ro-RO': 'ro-RO-Wavenet-A', // Romanian
    'pa-IN': 'pa-IN-Wavenet-A', // Panjabi
    'bho-IN': 'hi-IN-Wavenet-A', // Bhojpuri (mapped to Hindi as no Bhojpuri voice is listed)
    'am-ET': 'am-ET-Wavenet-A', // Amharic
    'ha-NG': 'ha-NG-Standard-A', // Hausa
    'fuv-NG': 'fuv-NG-Standard-A', // Nigerian Fulfulde
    'bs-BA': 'bs-BA-Standard-A', // Bosnian
    'hr-HR': 'hr-HR-Standard-A', // Croatian
    'nl-NL': 'nl-NL-Wavenet-A', // Dutch
    'sr-RS': 'sr-RS-Standard-A', // Serbian
    'th-TH': 'th-TH-Neural2-C', // Thai
    'ku-IQ': 'ku-IQ-Standard-A', // Central Kurdish
    'yo-NG': 'yo-NG-Standard-A', // Yoruba
    'uz-UZ': 'uz-UZ-Standard-A', // Northern Uzbek
    'ms-MY': 'ms-MY-Wavenet-A', // Malay
    'ig-NG': 'ig-NG-Standard-A', // Igbo
    'ne-NP': 'ne-NP-Standard-A', // Nepali
    'ceb-PH': 'tl-PH-Standard-A', // Cebuano (mapped to Tagalog as no Cebuano voice is listed)
    'skr-PK': 'ur-IN-Wavenet-A', // Saraiki (mapped to Urdu as no Saraiki voice is listed)
    'tl-PH': 'fil-PH-Wavenet-A', // Tagalog
    'hu-HU': 'hu-HU-Wavenet-A', // Hungarian
    'az-AZ': 'az-AZ-Standard-A', // North Azerbaijani
    'si-LK': 'si-LK-Standard-A', // Sinhala
    'koi-RU': 'koi-RU-Standard-A', // Komi-Permyak
    'el-GR': 'el-GR-Wavenet-A', // Modern Greek
    'cs-CZ': 'cs-CZ-Wavenet-A', // Czech
    'mag-IN': 'hi-IN-Wavenet-A', // Magahi (mapped to Hindi as no Magahi voice is listed)
    'rn-BI': 'rn-BI-Standard-A', // Rundi
    'be-BY': 'be-BY-Standard-A', // Belarusian
    'plt-MG': 'plt-MG-Standard-A', // Plateau Malagasy
    'qug-EC': 'qug-EC-Standard-A', // Chimborazo Highland Quichua
    'mad-ID': 'id-ID-Wavenet-A', // Madurese (mapped to Indonesian as no Madurese voice is listed)
    'ny-MW': 'ny-MW-Standard-A', // Nyanja
    'zyb-CN': 'zyb-CN-Standard-A', // Yongbei Zhuang
    'pbu-PK': 'pbu-PK-Standard-A', // Northern Pashto
    'rw-RW': 'rw-RW-Standard-A', // Kinyarwanda
    'zu-ZA': 'zu-ZA-Standard-A', // Zulu
    'bg-BG': 'bg-BG-Standard-A', // Bulgarian
    'sv-SE': 'sv-SE-Wavenet-A', // Swedish
    'ln-CD': 'ln-CD-Standard-A', // Lingala
    'so-SO': 'so-SO-Standard-A', // Somali
    'hms-CN': 'hms-CN-Standard-A', // Southern Qiandong Miao
    'hnj-CN': 'hnj-CN-Standard-A', // Hmong Njua
    'ilo-PH': 'ilo-PH-Standard-A', // Iloko
    'kk-KZ': 'kk-KZ-Standard-A', // Kazakh
  };    
