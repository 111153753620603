export const fetchAppAccess = async (org_id) => {
  return fetch(`${window.REACT_APP_TAGORE_API_URL}/authorize/appAccess`, {
    credentials: "include",
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-Organisation": org_id,
    },
  }).then((response) => {
    if (response.status === 200) {
      return true;
    } else {
      return false;
    }
  });
};
