export const getStylesBySize = (size) => {
  switch (size) {
    case "extra-small":
      return "w-8 h-8 text-base";
    case "small":
      return "w-10 h-10 text-lg";
    case "medium":
      return "w-16 h-16 text-2xl";
    case "large":
      return "w-20 h-20 text-4xl";
    default:
      return "w-16 h-16 text-2xl";
  }
};
