import { Link } from "react-router-dom";
import { TopNav } from "../../components/layout/sidebar";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { grantAccess, revokeAccess } from "../../actions/persona";
import { withOrg } from "../../components/organisation/withOrg";
import { useQuery } from "../../hooks/useQuery";
import { PERSONA_API } from "../../commons/persona";
import { errorToast, successToast } from "../../util/toasts";
import { ClipLoader } from "react-spinners";
import { CaretLeft } from "@phosphor-icons/react";

function PersonaAccess({ selectedOrg }) {
  const query = useQuery();

  let tableColumns = [
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Role",
      dataIndex: "role",
    },
    {
      title: "Access",
      dataIndex: "access",
    },
  ];

  const tableStyles = {
    valuesPadding: "px-4 py-6",
    headerPadding: "p-4",
  };

  const [checkIds, setCheckIds] = useState([]);

  const [userAccessDetails, setUserAccessDetails] = useState([]);

  const [newlyAddedUsers, setNewlyAddedUsers] = useState([]);

  const [newlyRemovedUsers, setNewlyRemovedUsers] = useState([]);

  const { users, profile } = useSelector(({ organisations, profile }) => {
    let selectedOrg = organisations?.details?.find(
      (org) => org?.id === organisations?.selectedOrg
    );

    return {
      users: selectedOrg?.organisation_users?.map((user) => ({
        name: user?.user?.first_name + " " + user?.user?.last_name,
        email: user?.user?.email,
        role: user?.role,
        id: user?.user?.id,
      })),
      profile: profile?.details,
    };
  });

  const isChecked = (user) => {
    if (user?.role == "owner") {
      return true;
    }

    let userAccess = userAccessDetails.find((userAccess) => {
      return userAccess.user_id == user.id;
    });

    return userAccess?.checked;
  };

  const fetchUserIds = () => {
    fetch(
      `${PERSONA_API}/access/users?` +
        new URLSearchParams({
          persona: query.get("meta_id"),
        }),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-Organisation": selectedOrg,
        },
        credentials: "include",
      }
    )
      .then((response) => {
        if (response.status == 200) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw Error(data.errors?.[0].message);
          });
        }
      })
      .then((data) => {
        setCheckIds(data?.user_ids);

        let userDetails = [];

        data?.user_ids.forEach((user) => {
          userDetails.push({
            user_id: user,
            checked: true,
          });
        });

        setUserAccessDetails(userDetails);
        // setUserDetails(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchUserIds();
  }, [query.get("meta_id")]);

  const [loading, setLoading] = useState({
    add: false,
    remove: false,
  });

  const giveAccess = (newAddedUsers) => {
    setLoading({
      ...loading,
      add: true,
    });

    grantAccess({
      orgId: selectedOrg,
      personaName: query.get("meta_id"),
      userIds: newAddedUsers,
    })
      .then(() => {
        successToast("access updated");
      })
      .catch(() => {
        errorToast("error in updating access");
      })
      .finally(() => {
        setLoading({
          ...loading,
          add: false,
        });
      });
  };

  const removeAccess = (newRemoveUsers) => {
    setLoading({
      ...loading,
      remove: true,
    });
    revokeAccess({
      orgId: selectedOrg,
      personaName: query.get("meta_id"),
      userIds: newRemoveUsers,
    })
      .then(() => {
        successToast("access updated");
      })
      .catch((error) => {
        errorToast("error in updating access");
      })
      .finally(() => {
        setLoading({
          ...loading,
          remove: false,
        });
      });
  };

  const handleSubmit = () => {
    let usersNewlyAdded = newlyAddedUsers.filter((user) => {
      return !checkIds.includes(user);
    });

    if (usersNewlyAdded.length > 0) {
      giveAccess(usersNewlyAdded);
    }

    let removedUsers = newlyRemovedUsers.filter((user) => {
      return checkIds.includes(user);
    });

    if (removedUsers.length > 0) {
      removeAccess(removedUsers);
    }
  };

  return (
    <>
      <div className="flex pl-10 pr-9 py-7 flex-row items-center  gap-5">
        <div className="ml-auto">
          <TopNav />
        </div>
      </div>
      <div className="p-10 mt-0 bg-white rounded-[24px] flex items-center gap-4">
        <Link to={"/personas"}>
          <CaretLeft size={20} />
        </Link>
        <div className="text-stone-900 text-xl font-medium leading-tight">
          Manage Access
        </div>
      </div>
      <div className="flex flex-col gap-2 bg-white p-10">
        <div className="flex items-center flex-row-reverse gap-2">
          <button
            className="py-2 px-4 bg-blue-600 text-white rounded-md"
            onClick={() => {
              handleSubmit();
            }}
          >
            {loading.add || loading.remove ? (
              <ClipLoader color={"white"} loading={true} size={15} />
            ) : (
              "Submit"
            )}
          </button>
          <button
            className="py-2 px-4 bg-blue-600 text-white rounded-md"
            onClick={() => {
              let userDetails = [];
              users.forEach((user) => {
                userDetails.push({
                  user_id: user.id,
                  checked: true,
                });
              });
              setUserAccessDetails(userDetails);

              // add to newly added users
              let addedUsers = newlyAddedUsers;
              users.forEach((user) => {
                if (!newlyAddedUsers.includes(user.id)) {
                  addedUsers.push(user.id);
                }
              });
              setNewlyAddedUsers(addedUsers);

              // remove from newly removed users
              let removedUsers = newlyRemovedUsers.filter((removedUser) => {
                return !users.map((user) => user.id).includes(removedUser);
              });

              setNewlyRemovedUsers(removedUsers);
            }}
          >
            Select All
          </button>
        </div>
        {/* this is the table with form */}
        <div>
          <table className="border-collapse w-full">
            <thead>
              {tableColumns.map((column, index) => {
                return (
                  <th
                    key={index}
                    className={`bg-background-sidebar text-sm font-medium rounded-t-md text-text-primary text-left p-4 ${"w-1/4"} ${
                      tableStyles.headerPadding
                    }`}
                  >
                    {column.title}
                  </th>
                );
              })}
            </thead>
            <tbody>
              {users?.map((user, index) => {
                return (
                  <tr
                    key={index}
                    className={`border-b border-[#eaeaea] ${
                      index % 2 === 0 ? "bg-white" : "bg-[#fafafa]"
                    }`}
                  >
                    {tableColumns.map((column, index) => {
                      return (
                        <>
                          {column.dataIndex === "access" ? (
                            <td
                              className={`flex items-center ${tableStyles.valuesPadding} relative`}
                            >
                              <input
                                checked={isChecked(user)}
                                type="checkbox"
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    let userDetails = userAccessDetails;
                                    userDetails.push({
                                      user_id: user.id,
                                      checked: true,
                                    });
                                    setUserAccessDetails(userDetails);

                                    // add to newly added users
                                    let addedUsers = newlyAddedUsers;
                                    if (!newlyAddedUsers.includes(user.id)) {
                                      addedUsers.push(user.id);
                                      setNewlyAddedUsers(addedUsers);
                                    }

                                    // remove from newly removed users
                                    let removedUsers = newlyRemovedUsers.filter(
                                      (removedUser) => {
                                        return removedUser != user.id;
                                      }
                                    );
                                    setNewlyRemovedUsers(removedUsers);
                                  } else {
                                    let userDetails = userAccessDetails.filter(
                                      (userDetail) => {
                                        return userDetail.user_id != user.id;
                                      }
                                    );
                                    setUserAccessDetails(userDetails);

                                    // add to newly removed users
                                    let removedUsers = newlyRemovedUsers;
                                    if (!newlyRemovedUsers.includes(user.id)) {
                                      removedUsers.push(user.id);
                                      setNewlyRemovedUsers(removedUsers);
                                    }

                                    // remove from newly added users
                                    let addedUsers = newlyAddedUsers.filter(
                                      (addedUser) => {
                                        return addedUser != user.id;
                                      }
                                    );
                                    setNewlyAddedUsers(addedUsers);
                                  }
                                }}
                              ></input>
                            </td>
                          ) : (
                            <td
                              key={index}
                              className={`text-sm font-normal text-text-secondary ${column.width} ${tableStyles.valuesPadding}`}
                            >
                              {column.dataIndex === "name"
                                ? user[column.dataIndex] === " "
                                  ? "---"
                                  : user[column.dataIndex]
                                : user[column.dataIndex]}
                            </td>
                          )}
                        </>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default withOrg(PersonaAccess);
