import { fetchAppAccess } from "../../actions/kavach";
import { ADD_APP_ACCESS, SET_APP_ACCESS_LOADING } from "../constants/appAccess";

export const getAppAccess = () => {
  return (dispatch, getState) => {
    dispatch(loadingAppAccess());
    const selectedOrgID = getState().organisations?.selectedOrg;
    return fetchAppAccess(selectedOrgID)
      .then((response) => {
        dispatch({
          type: ADD_APP_ACCESS,
          payload: response,
        });
      })
      .catch(() => {
        dispatch({
          type: ADD_APP_ACCESS,
          payload: false,
        });
      })
      .finally(() => {
        dispatch(stopAppAccessLoading());
      });
  };
};

export const loadingAppAccess = () => ({
  type: SET_APP_ACCESS_LOADING,
  payload: true,
});

export const stopAppAccessLoading = () => ({
  type: SET_APP_ACCESS_LOADING,
  payload: false,
});
