import React from "react";
import { useParams } from "react-router-dom";

import { errorToast } from "../../util/toasts";
import { AIBlock, UserBlock } from "../../components/chat/chatBlock";
import { ReactComponent as TagoreLogoDark } from "../../assets/icons/tagoreAiBlack.svg";

function ShareChat() {
  const { id } = useParams();

  const [chat, setChat] = React.useState(null); // Initialize with null for loading state

  const getChatByExternalRef = async (id) => {
    try {
      const response = await fetch(
        `${window.REACT_APP_TAGORE_API_URL}/chat/shared/${id}`
      );

      const chat = await response.json();

      if (!response.ok) {
        throw new Error(chat.errors?.[0].message);
      }

      return chat;
    } catch (error) {
      errorToast(error.message); // Display a more informative error message
      return null;
    }
  };

  React.useEffect(() => {
    const fetchChat = async () => {
      const chatData = await getChatByExternalRef(id);
      if (chatData) {
        setChat(chatData);
      }
    };

    fetchChat();
  }, [id]);

  if (chat === null) {
    // Loading state, you can show a loading spinner here
    return <div>Loading...</div>;
  }

  return (
      <>
    <TagoreLogoDark className="mx-auto mt-6"/>
    <main className="flex-col flex w-3/4 mx-auto mt-6">
      <div className="flex justify-between items-center">
        <div className="flex flex-col gap-4">
          <h1 className="text-2xl font-bold dark:text-white">{chat?.title}</h1>
        </div>
        {/* <button className="bg-[#000] dark:bg-[#fff] rounded-lg px-4 py-2 text-sm font-semibold text-white dark:text-black">
          Continue Conversation
        </button> */}
      </div>
      <div className="flex flex-col mt-10">
        {chat.messages
          .filter((item) => item.role !== "system")
          .map((item, index) => {
            return (
              <>
                {item.role === "user" ? (
                  <UserBlock
                    key={index}
                    content={item.content}
                    showEdit={false}
                  />
                ) : (
                  <AIBlock
                    index={index}
                    content={item.content}
                    showAudio={false}
                    audioURL={item?.audio}
                  />
                )}
              </>
            );
          })}
      </div>
    </main>
    </>
  );
}

export default ShareChat;
