export const getResponseFromAsst = async ({
  threadId,
  prompt,
  asstID,
  orgID,
}) => {
  let reqBody = {
    asst_name: asstID,
    prompt: prompt,
  };

  if (threadId) {
    reqBody["thread_id"] = threadId;
  }

  return fetch(window.REACT_APP_TAGORE_API_URL + "/assistants", {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-Organisation": orgID,
    },
    body: JSON.stringify(reqBody),
  }).then((response) => {
    if (response.status === 200) {
      return response.json();
    } else {
      return response.json().then((data) => {
        throw Error(data.errors?.[0].message);
      });
    }
  });
};

export const fetchAsstHistory = async ({ asstID, orgID, query }) => {
  return fetch(
    window.REACT_APP_TAGORE_API_URL +
      `/assistants?asst_id=${asstID}&search_query=${query}&limit=20`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-Organisation": orgID,
      },
    }
  ).then((response) => {
    if (response.status === 200) {
      return response.json();
    } else {
      return response.json().then((data) => {
        throw Error(data.errors?.[0].message);
      });
    }
  });
};

export const getThreadByID = (threadID) => {
  return fetch(
    window.REACT_APP_TAGORE_API_URL + "/assistants/thread/" + threadID,
    {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    }
  ).then((response) => {
    if (response.status === 200) {
      return response.json();
    } else {
      return response.json().then((data) => {
        throw Error(data.errors?.[0].message);
      });
    }
  });
};
