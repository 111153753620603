import { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "../../hooks/useQuery";
import { personaKey } from "../../commons/keys";
import { SSE } from "sse.js";
import useSocket from "../../util/useSocket";
import {
  getAssistantURL,
  getInitialFollowupQuestionsFromPersona,
  getPersonaNameFromCode,
} from "../../commons/getAssistantURL";
import CentralLoading from "../../components/loader/centralLoading";
import { ChatSidebar } from "../../components/chat/sidebar";
import { useNavigate } from "react-router-dom";
import { ChatContainer } from "../../components/chat/container";
import { ChatInput } from "../../components/chat/chatInput";
import AlwaysScrollToBottom from "../../components/scroll/scrollToBottom";
import { BeatLoader } from "react-spinners";
import { theme } from "../../commons/styles";
import { ChatNavbar } from "../../components/chat/chatNavbar";
import {
  AIBlock,
  ErrorBlock,
  UserBlock,
} from "../../components/chat/chatBlock";
import { transcribeAudio } from "../../actions/audio";
import { errorToast, successToast } from "../../util/toasts";
import { withOrg } from "../../components/organisation/withOrg";
import { convertToJsonFormat } from "../../util/convert";

import { FollowUpQuestions } from "../../components/chat/follow_questions";
import { replaceKeys } from "../../util/replaceObjKey";
import {
  createExternalLinkForSharing,
  deleteFactlyPersonaHistory,
  getAssistantHistory,
  // getFactlyPersonaHistory,
  getPersonaChatsByUserID as getFactlyPersonaHistory,
  limitReached as limitReachAction,
  validateAccess,
} from "../../actions/persona";
import { UnauthorisedAccess } from "../errors/unauthorised";
import Modal from "../../components/Modal";
import UppyUploader from "../../components/Uppy";
import { getFileExtensionFromMime } from "../../util/fileType";
import { MaxLimitReachedPersona } from "../../components/commons/maxLimitPersonaWarning";
import { MaxLimitWarning } from "../../components/commons/maxLimitWarning";
import { isObject } from "../../util/isObject";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { usePostHog } from "posthog-js/react";
import { dispatchPosthog } from "../../util/posthog";
import useWindowSize from "../../hooks/useWindowSize";
import Select from "react-select";
import { Check } from "@phosphor-icons/react";
import MasterPersonaPrompts from "./masterPersonaUi/masterPersonaPrompts";
import MasterPersonaOptions from "./masterPersonaUi/masterPersonaOptions";
//import { generateTextFromPrompt } from "../../actions/text";

const SystemMessages = ({ systemMessages }) => (
  <>
    {systemMessages.map((message, index) => (
      <div key={index} className="bg-[#f4f4f5] p-4 rounded-lg w-full">
        <div className="flex items-center text-sm text-green-600 mb-2">
          <Check className="w-4 h-4 mr-2" />
          <span>{message}</span>
        </div>
      </div>
    ))}
  </>
);

export const models=[
  {
    "value": "gpt4o",
    "label": "GPT‑4o"
  },
  {
      "value": "gpt4",
      "label": "GPT-4"
  },
  {
      "value": "gpt4_turbo",
      "label": "GPT-4 Turbo"
  },
  {
      "value": "gpt35turbo",
      "label": "GPT-3.5 Turbo"
  },
  {
      "value": "claude-3-sonnet",
      "label": "Claude-3 Sonnet"
  },
  {
      "value": "claude-3-haiku",
      "label": "Claude-3 Haiku"
  },
  {
    "value": "claude-3-opus",
    "label": "Claude-3 Opus"
  },
  { 
    "value": "gemini-1.5-pro",
    "label": "Gemini 1.5 Pro"
  }
]

function FactlyPersona({ userID, selectedOrg }) {
  const { isDesktopScreen } = useWindowSize(undefined, 769);

  // posthog is used to track the events
  const posthog = usePostHog();

  // currentChat is used to store the current chat
  const [currentChat, setCurrentChat] = useState({ id: "", title: "" });

  const [sseClient, setSSEClient] = useState(null);

  // isSelectedChat is used to check whether the current chat is selected from the sidebar
  const [isSelectedChat, setIsSelectedChat] = useState(false);

  // navigate is used to navigate to a different route
  const navigate = useNavigate();

  // useQuery returns a URLSearchParams object which has a get() method with key as argument
  const query = useQuery();

  const { personaIDQuery, personaRequestURL } = useMemo(() => {
    const personaName = query.get(personaKey);
    let requestURL = `${getAssistantURL(
      personaName
    )}?user_id=${userID}&llm_model=gpt4_turbo`;

    if (currentChat.id !== "") {
      requestURL += `&chat_id=${currentChat.id}`;
    }

    return {
      personaIDQuery: personaName,
      personaRequestURL: requestURL,
    };
  }, [query, currentChat]);
  const isMaster = personaIDQuery === 'master'
  const [masterPersona,setMasterPersona] = useState(null)

  const isAssistant = ["lse", "ibs", "csr", "tt"].includes(
    query.get(personaKey)
  );
  const personaUrl = isAssistant
    ? "/assistants/stream"
    : "/personas/factly-chat/stream";

  // sidebarVisible is used to toggle the sidebar
  const [sidebarVisible, setSidebarVisible] = useState(
    isDesktopScreen ? true : false
  );
  useEffect(() => {
    if (isDesktopScreen) {
      setSidebarVisible(true);
    } else {
      setSidebarVisible(false);
    }
  }, [isDesktopScreen]);

  // sharedLink is used to store the shared link
  const [sharedLink, setSharedLink] = useState("");

  // showShareChat is used to toggle the share chat modal
  const [showShareChat, setShowShareChat] = useState(false);

  // toggleSidebar is used to toggle the sidebar
  const toggleSidebar = () => setSidebarVisible(!sidebarVisible);

  // handleNewChatClick is used to handle the click on the new chat button in the sidebar
  const handleNewChatClick = () => {
    setIsSelectedChat(false);

    setCurrentChat({ id: "", title: "" });

    setMessages([]);

    // disconnect with the current socket
    // disconnect();

    setFollowups(getInitialFollowupQuestionsFromPersona(personaIDQuery));

    setUsrMsgs(0);
    // set the current chat to empty
    setLoading(false);

    // limitReachAction(personaIDQuery)
    //   .then((response) => {
    //     setLimitReached(response?.hasReachedLimit);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });

    // fetchChatHistory();
  };

  function getPersonaDescription() {
    switch (personaIDQuery) {
      case "ibs":
        return "Delve into the heart of India's financial decisions with BudgetSpeak, where budget speeches come alive through insightful conversations.";
      case "constitution":
        return "Navigate the bedrock of India's legal framework with this interactive guide to the Indian Constitution's articles, amendments, and foundational principles.";
      case "central_acts":
        return  "Discover the realm of Central Acts with this conversational companion, designed to help you navigate India's comprehensive legal statutes and legislative provisions.";
      case "tt":
        return "Empowering decisions with TradeTracer, where expertise meets comprehensive trade data, illuminating India's export and import landscape.";
      case "lse":
        return "Embark on a dialogue with the pulse of democracy – BallotVerse, decoding Lok Sabha Elections data through engaging conversations.";
      case "csr":
        return "Explore the social footprint of businesses with CSR Explorer, your guide to comprehensive corporate social responsibility data and insights.";
      case "parlens":
        return "Engage with the wealth of Lok Sabha and Rajya Sabha data effortlessly with Parlens, your conversational guide to parliamentary knowledge.";
      case "budgetspeech":
        return "Delve into the heart of India's financial decisions with BudgetSpeak, where budget speeches come alive through insightful conversations.";
      case "pib":
        return "Journey through the present and past with PIB Chronicle, your gateway to daily and historical insights from the Government of India's press releases.";
      case "factly_articles":    
        return  "Dive into accurate and reliable information by exploring in-depth analyses and verified insights from Factly's articles, deciphering complex issues with clarity and precision.";
      case "dataful":    
        return  "Find clean, structured, ready-to-use datasets on India covering over 50 sectors such as health, education, agriculture, social development, art and culture, electoral statistics, law and justice, and more.";
      default:
        return "Empowering Users to Navigate Fact and Fiction. Your Gateway to Verified Information and Debunked Hoaxes from Claim Review.";
    }
  }

  const personaName = {
    parlens: "Parlens",
    budgetspeech: "BudgetSpeak",
    pib: "PIB Chronicle",
    sach: "SACH",
    tt: "TradeTracer",
    csr: "CSR Explorer",
    lse: "BallotVerse India",
    ibs: "Budget Speak",
    constitution: "constitution",
    central_acts: "Central Acts",
    factly_articles:"Factly Articles",
    dataful:"Dataful"
  };

  const [followups, setFollowups] = useState([]);

  const [model, setModel] = useState({
    "value": "gpt4o",
    "label": "GPT‑4o"
});

  const [showUppy, setShowUppy] = useState(false);

  // messages is used to store the messages in the current chat
  const [messages, setMessages] = useState([]);

  // currentPrompt is used to store the current prompt
  const [currentPrompt, setCurrentPrompt] = useState("");

  // chatHistory is used to store the chat history
  const [chatHistory, setChatHistory] = useState({
    count: 0,
    results: [],
  });

  const [loading, setLoading] = useState(false);

  // editIndex is used to store the index of the message being edited
  const [editIndex, setEditIndex] = useState(-1);

  const [access, setAccess] = useState(true);

  // showFilesMenu is used to toggle the files menu
  const [showFilesMenu, setShowFilesMenu] = useState(false);

  const toggleFilesMenu = () => {
    setShowFilesMenu(!showFilesMenu);
  };

  // userClickedClose is used to check whether user has clicked on closing the restriction button
  const [userClickedClose, setUserClickedClose] = useState(false);

  // selectedFiles is used to store the files selected by the user
  const [selectedFiles, setSelectedFiles] = useState([]);

  // error is used to store the error from the socket
  const [error, setError] = useState(null);

  const fetchAccess = useCallback(async () => {
    if (personaIDQuery) {
      validateAccess({
        orgID: selectedOrg,
        userID: userID,
        personaName: personaIDQuery,
      })
        .then((response) => {
          setAccess(response?.allowed);
        })
        .catch(() => {
          errorToast(
            "Something went wrong. Please try again after some time or reload the page."
          );
        });
    }
  }, [personaIDQuery]);

  useEffect(() => {
    fetchAccess();

    setFollowups(getInitialFollowupQuestionsFromPersona(personaIDQuery));
  }, [personaIDQuery]);

  // handleKeyDown is used to handle the keydown event for enter key
  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && e.shiftKey) {
      return;
    }

    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      const messageText = e.target.value.trim();
      if (messageText !== "") {
        e.target.value = "";
        e.target.style.height = "24px";
      }
    }

    if (e.keyCode === 13 && !loading && currentPrompt !== "") {
      handleChatSubmit();
    }
  };

  const handleSendMessage = (lastMessage) => {
    let message = {
      role: "human",
      content: lastMessage,
      chat_id: currentChat.id || "",
    };

    if (selectedFiles.length > 0) {
      message.files = selectedFiles.map((file) => {
        return {
          type: getFileExtensionFromMime(file.type),
          url: file.url.raw,
        };
      });
    }

    setLoading(true);
    setMessages((prevMessages) => [...prevMessages, message]);

    setSelectedFiles([]);

    // setUsrMsgs(usrMsgs + 1);

    handleStreamRequest(message);
  };
  console.log(messages, "messages");
  const [limitReached, setLimitReached] = useState(false);
  const [usrMsgs, setUsrMsgs] = useState(0);

  const handleChatSubmit = () => {
    if (error) {
      setError(null);
    }
    handleSendMessage(currentPrompt);
    dispatchPosthog(posthog, "Sent Factly Persona Message", {
      persona: personaIDQuery,
      message: currentPrompt,
    });
  };

  const handleStreamRequest = (currentMessage) => {
    console.log("stream request called")
    setLoading(true);
    const newMessages = [...messages, currentMessage];
    var requestBody = {
      persona:isMaster?masterPersona:personaIDQuery,
      prompt: currentMessage.content,
      chat_id: currentChat.id || "",
      messages: newMessages,
      files: currentMessage.files,
      model:model.value
    };
    if (isAssistant) {
      requestBody.asst_name = personaIDQuery;
    }
    setCurrentPrompt("");

    var source = new SSE(window.REACT_APP_TAGORE_API_URL + personaUrl, {
      payload: JSON.stringify(requestBody),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Organisation": selectedOrg,

        "X-User": userID,
      },
      withCredentials: true,
    });

    setSSEClient(source);

    source?.addEventListener("message", (event) => {
      let chatObject;
      try {
        chatObject = JSON.parse(event.data);
        console.log(chatObject, "chatObject");
      } catch (error) {
        console.log(error, "error");
        console.log(event.data, "event.data", typeof event.data, "type");
        console.error("Error in parsing the response");
        return;
      }
      if (currentChat.id === "" && chatObject?.chat_id) {
        setCurrentChat({ id: chatObject.chat_id, title: "" });
      }
        setMessages(chatObject.messages);
    });

    source?.addEventListener("error", (event) => {
      source?.close();
      setSSEClient(null);
      setLoading(false);
      if (!String(event.data).includes("[DONE]")) {
        return;
      }
    });
    source?.stream();
    setScrollUp(false);
  };

  const getAudioBlob = async (audioURL) => {
    let audioBlob = new Blob(
      [new Uint8Array(await (await fetch(audioURL)).arrayBuffer())],
      { type: "audio/webm " }
    );

    // making a file out of this blob
    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();
    const sampleRate = audioContext.sampleRate;

    const formData = new FormData();
    formData.append("sampleRate", sampleRate); // Append the sample rate
    formData.append("audio", audioBlob);

    handleAudioSubmit(formData);
  };

  const handleEdit = (text, index) => {
    if (error) {
      setError(null);
    }
    // setMessages(messages.slice(0, index));
    handleSendMessage(text);
  };

  const handleRegenerate = () => {
    setLoading(true);
    let userMessage = messages[messages.length - 2].content;
    let newMessages = messages.slice(0, messages.length - 2);
    setMessages(newMessages);
    handleSendMessage(userMessage);
  };

  const handleAudioSubmit = (formData) => {
    setLoading(true);
    transcribeAudio(formData)
      .then((data) => {
        handleSendMessage(data?.transcript);
      })
      .catch((error) => {
        errorToast("Unable to generate response. Please try again");
      });
  };

  const [scrollUp, setScrollUp] = useState(false);

  const handleScroll = (e) => {
    if (!loading && e?.deltaY) {
      if (e?.deltaY < 0) {
        setScrollUp(true);
      } else {
        setScrollUp(false);
      }
    }
  };

  const pagination = {
    page: 1,
    limit: 20,
    search_query:''
  };

  const fetchChatHistory = () => {
    if (isAssistant) {
      getAssistantHistory({
        persona: personaIDQuery,
        page: pagination.page,
        limit: pagination.limit,
        userID: userID,
        selectedOrg: selectedOrg,
      })
        .then((data) => {
          // const normalizedData = (obj) => {
          //   return obj.chats
          //   .map((originalObject) => {
          //     const keyMap = {
          //       chat_id: "id",
          //       chat_title: "title",
          //       chat_history: "messages",
          //     };
          //     return replaceKeys(originalObject, keyMap);
          //   });
          // };
          setChatHistory({
            results: data.chats,
            count: data.total || 20,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      getFactlyPersonaHistory(personaIDQuery, pagination)
      .then((data) => {
        setChatHistory({
          results: data.chats,
          count: data.count||20,
        });
      })
      .catch((err) => {
        errorToast(err);
      });
    } 
  };

  // const fetchLimitDetails = () => {
  //   if (personaIDQuery) {
  //     limitReachAction(personaIDQuery)
  //       .then((response) => {
  //         setLimitReached(response?.hasReachedLimit);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //     return;
  //   }
  // };

  useEffect(() => {
    // chat history
    fetchChatHistory();

    // // limit details
    // fetchLimitDetails();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelectChat = (chat) => {
    setFollowups([]);

    setIsSelectedChat(true);

    // disconnect with the current socket
    // disconnect();
    setCurrentChat({ id: chat.id, title: chat.title });

    let messages = chat.messages
    // commented out obj restructuring
    // ?.map((message) => {
    //   return {
    //     sender: message?.role,
    //     content: isObject(message?.content)
    //       ? message?.content?.input
    //       : message?.content,
    //     files: message?.content?.files || [],
    //   };
    // });
    
   // Comment out follow up question generation
    // let para = convertToJsonFormat(messages);
    // let request = {
    //   provider: "openai",
    //   input: para,
    //   model: "gpt-4",
    //   stream: false,
    //   generate_for: "followup-questions-chat",
    // };

    // generateTextFromPrompt(request, selectedOrg)
    //   .then((data) => {
    //     // break the response at \n
    //     // remove '-' from each line
    //     let response = data?.output
    //       ?.split("\n")
    //       .map((line) => line.replace("-", ""));

    //     // remove empty lines
    //     response = response.filter((line) => line !== "");
    //     setFollowups(response);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   })

    // setUsrMsgs(messages.filter((message) => message.role === "human").length); for limiting

    setMessages(messages);
  };

  if (!access) {
    return (
      <UnauthorisedAccess
        title="Access Denied"
        description="Oops! It seems like you don't have the necessary access to engage with this persona. Access to specific features or conversations may be restricted based on user roles or permissions. If you believe this is an error or if you need additional access, please contact your administrator for assistance. Thank you for your understanding!"
        showLogout={false}
        link={`/personas`}
        linkText={`Go back to Personas`}
      />
    );
  }

  // userHasReachedLimit function contains logic to check if the user has reached the limit of the number of conversations
  // max number of conversation in a day is 5 which we get to know from the limitReachedAction
  // if the limitReached returns true and the currentChat is nil then don't allow the user to start a new chat i.e. disable the chat input
  // if the limitReached returns true and the currentChat is not nil then allow the user to continue the chat
  // the max user messages allowed in 1 chat is 5
  function userHasReachedLimit() {
    // if (limitReached) {
    //   return true;
    // }

    return false;
  }

  const handleDeleteChat = (chatID) => {
    deleteFactlyPersonaHistory({
      persona: personaIDQuery,
      chat_id: chatID,
    })
      .then(() => {
        successToast("Chat deleted successfully");
        fetchChatHistory();    
        setCurrentChat({ id: "", title: "" });
        setMessages([]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const isInputDisabled = () => {
    if(isMaster){
      return !Boolean(masterPersona)
    }
    return false;
    // (userHasReachedLimit() && usrMsgs >= 5) || !isConnected;
  };

  const toggleShareChat = () => {
    setShowShareChat(!showShareChat);
  };
  return (
    <div className="flex w-screen h-dvh -md:flex-col fixed">
      <ChatSidebar
        disableNewChat={userHasReachedLimit()}
        chatHistory={chatHistory}
        sidebarVisible={sidebarVisible}
        toggleSidebar={toggleSidebar}
        handleNewChat={handleNewChatClick}
        navigate={() => navigate("/personas")}
        handleSelectChat={handleSelectChat}
        chat={{ ...currentChat, messages: messages }}
        handleDeleteChat={handleDeleteChat}
        key={chatHistory?.count}
      />
      <ChatContainer
        isDesktopScreen={isDesktopScreen}
        sidebarVisible={sidebarVisible}
      >
        <ChatNavbar
          isDesktopScreen={isDesktopScreen}
          sidebarVisible={sidebarVisible}
          optionModel = {model}
          handleNewChat={handleNewChatClick}
          chat={{
            messages: messages,
          }}
          personaName={personaName[personaIDQuery]}
          toggleSidebar={toggleSidebar}
          showSettingsIcon={true}
          showShare={false}
          isPersona={true}
          masterPersona = {masterPersona}
          persona={!isMaster?getPersonaNameFromCode(personaIDQuery):"master"}
          handlePersonaModel={(model) => {
            setModel(model);
          }}
          modelName={model.label}
          persona_description={getPersonaDescription()}
          showLimit={true}
          showConnectionStatus={false}
          limit={5 - usrMsgs} //
          isConnected={true} //isConnected
          handleShareLink={() => {
            if (messages.length <= 0) {
              errorToast("Please start a conversation before sharing the chat");
              return;
            }

            if (!currentChat?.id) {
              errorToast("Please select a chat before sharing");
              return;
            }

            createExternalLinkForSharing({
              persona_name: personaIDQuery,
              chat_id: currentChat.id,
            })
              .then((response) => {
                let link;
                if (process.env.NODE_ENV === "development") {
                  link = `${window.location.origin}${window.REACT_APP_PUBLIC_URL}/persona/shared/${response?.external_id}`;
                } else {
                  link = `${window.location.origin}/persona/shared/${response?.external_id}`;
                }
                setSharedLink(link);
                toggleShareChat();

                dispatchPosthog(posthog, "Shared Factly Persona Chat", {
                  persona: personaIDQuery,
                  chat_id: currentChat.id,
                  shared_link: link,
                });
              })
              .catch((error) => {
                console.log(error);
              });
          }}
        />
        <div className={`flex-[11] overflow-hidden flex flex-col px-10 justify-center -md:p-0`}>
             {!isDesktopScreen
              &&!loading &&
              !userHasReachedLimit() &&
              usrMsgs < 5 &&
              selectedFiles.length === 0 &&
              true &&
              !messages.length && (<div className="flex w-full gap-0 p-3 items-center justify-center">
                <span className="flex-1 pl-3">Choose a language model</span>
                <Select
                placeholder = "select a model"
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "black",
                    primary25: "#EAEAEA",
                    primary50: "#EAEAEA",
                    primary75: "#EAEAEA",
                  },
                })}
                value={model}
                defaultValue={model}
                onChange={(newValue) => {
                  setModel(newValue);
                }}
                className="flex-1"
                options={models}
              ></Select>
                </div>)}
          {/* chat display section starts here */}
          <div
            className="flex-[10] py-8 px-9 flex flex-col gap-8 max-h-[80vh] -md:p-3 -md:max-h-[80vh] overflow-y-scroll"
            onScroll={handleScroll}
            id="chat-display"
          >
            {isMaster&&messages.length===0&&<div className="flex flex-1 flex-col gap-24 justify-center">
            {<MasterPersonaOptions
             setMasterPersona={setMasterPersona}
             masterPersona={masterPersona}
            />}
            </div>}
            {messages
              // .filter((message) => message.role !== "system")
              .map((message, index, array) => {
                if (message.role === "system") {
                  return <SystemMessages systemMessages={[message.content]} />;
                }
                if (message.role === "human") {
                  return (
                    <UserBlock
                      key={index}
                      content={message?.content}
                      audioURL={message?.audioURL}
                      editing={index === editIndex}
                      onEditClick={() => {
                        setEditIndex(index);
                      }}
                      onEditCancel={() => setEditIndex(null)}
                      onEditSubmit={(text) => {
                        handleEdit(text, index);
                      }}
                      files={message?.files}
                    />
                  );
                } else if (message.role === "ai") {
                  return (
                    <AIBlock
                      index={index}
                      content={message.content}
                      audioURL={message.audioURL}
                      showRegenerate={index === messages.length - 1 && !loading}
                      triggerRegenerate={handleRegenerate}
                      showAudio={true}
                    />
                  );
                }
              })}
            {!loading &&
              !userHasReachedLimit() &&
              usrMsgs < 5 &&
              selectedFiles.length === 0 &&
              true &&
              !messages.length && (
                <FollowUpQuestions
                  followups={followups}
                  handleFollowUp={(followup) => {
                    handleSendMessage(followup);
                    setFollowups([]);
                  }}
                />
              )}
            {loading && !scrollUp && (
              <div className="flex justify-center mt-4">
                <AlwaysScrollToBottom chat={messages} />
                <BeatLoader size={theme.iconSize.large} color={"#CED0D4"} />
              </div>
            )}
            {error && <ErrorBlock />}
          </div>
          {/* the chat input section starts here */}
          {isMaster&&masterPersona&&!loading?<MasterPersonaPrompts
           setMasterPersona={setMasterPersona}
           masterPersona={masterPersona}
          />:null}
          <div className="relative">
            {selectedFiles.length > 0 && (
              <div className="w-full grid grid-cols-3 absolute bottom-full max-h-96 bg-[#F9F9F9] border border-[#D1D1D1] overflow-y-scroll rounded-md p-2 gap-2">
                {selectedFiles.map((file, index) => {
                  return (
                    <div
                      className="flex flex-col gap-2 justify-center items-center"
                      key={index}
                    >
                      <img
                        src={file.url.raw}
                        alt={file.name}
                        className="object-cover w-full h-full"
                      />
                    </div>
                  );
                })}
              </div>
            )}
            {(userClickedClose && userHasReachedLimit() && !isSelectedChat) ||
            usrMsgs >= 5 ? (
              <MaxLimitWarning
                handleNewChat={() => {
                  handleNewChatClick();
                }}
                handleExplorePersonas={() => {
                  navigate("/personas");
                }}
                convLimit={userHasReachedLimit()}
              />
            ) : (
              <ChatInput
                loading={loading}
                currentPrompt={currentPrompt}
                setCurrentPrompt={setCurrentPrompt}
                handleKeyDown={handleKeyDown}
                getAudioBlob={getAudioBlob}
                handleChatSubmit={handleChatSubmit}
                showStop={true}
                handleStop={() => {
                  sseClient?.close();
                  setSSEClient(null);
                  setLoading(false);
                  setScrollUp(false);
                }}
                handleImageUpload={() => {
                  setShowUppy(true);
                }}
                showAudio={true}
                showFiles={personaIDQuery === "sach" || "master"}
                showFilesMenu={showFilesMenu}
                toggleFilesMenu={toggleFilesMenu}
                disabled={isInputDisabled()}
              />
            )}
          </div>
        </div>
        {showUppy && (
          <Modal
            onClose={() => setShowUppy(false)}
            open={showUppy}
            closeButton={true}
          >
            <UppyUploader
              onUpload={(fileDetails) => {
                setShowUppy(false);
                setShowFilesMenu(false);
                setSelectedFiles([...selectedFiles, ...fileDetails]);
              }}
            />
          </Modal>
        )}
        {!userClickedClose && userHasReachedLimit() && (
          <Modal
            closeButton={true}
            open={true}
            onClose={() => {
              setUserClickedClose(true);
            }}
            width={"w-1/3"}
          >
            <MaxLimitReachedPersona
              handleNewChat={() => {
                handleNewChatClick();
                setUserClickedClose(true);
              }}
              handleExplorePersonas={() => {
                navigate("/personas");
              }}
              convLimit={userHasReachedLimit()}
            />
          </Modal>
        )}
      </ChatContainer>
      {showShareChat && (
        <div className="absolute w-screen h-dvh md:bg-white md:opacity-90">
          <div
            className={`absolute top-16 -md:left-1/2 -md:transform -md:-translate-x-1/2 -md:top-[5rem] -md:max-w-[95vw] -md:w-full -md:shadow  -md:bg-white -md:rounded-xl right-16 p-6 flex flex-col bg-[#f9f9f9] md:border md:border-[#D1D1D1] rounded-md gap-4 z-[50] w-[500px]`}
          >
            <span>Share Link to this Chat</span>
            <div className="max-h-[320px] overflow-y-auto bg-white z-[999] w-full">
              {messages
                .filter((item) => item.role !== "system")
                .map((item, index) => {
                  return (
                    <>
                      {item.role === "human" ? (
                        <UserBlock
                          key={index}
                          content={item.content}
                          showEdit={false}
                          files={item?.files}
                        />
                      ) : (
                        <AIBlock
                          index={index}
                          content={item.content}
                          showAudio={false}
                        />
                      )}
                    </>
                  );
                })}
            </div>
            <span className="text-[#666]">
              Anyone with the URL will be able to view the shared chat but wont
              be able to make any changes.
            </span>
            <div className="flex items-center justify-center gap-4">
              <button
                className="px-2 py-3 border text-[#798897] border-[#798897] rounded-md"
                onClick={() => toggleShareChat()}
              >
                Cancel
              </button>
              <button
                className="px-2 py-3 bg-[#798897] text-white rounded-md"
                onClick={() => {
                  navigator.clipboard.writeText(sharedLink);
                  successToast("Link copied to clipboard");
                }}
              >
                Copy Link
              </button>
            </div>
          </div>
        </div>
      )}
      {/* </> */}
      <ToastContainer
        toastClassName={({ type }) =>
          type === "error"
            ? "w-[340px] border-l-[12px] border-[#DA3125] rounded-md shadow-lg bg-[#FFF]"
            : type === "success"
            ? "w-[340px] border-l-[12px] border-[#03C04A] rounded-md shadow-lg bg-[#FFF]"
            : type === "warning"
            ? "w-[340px] border-l-[12px] border-[#EA8700] rounded-md shadow-lg bg-[#FFF]"
            : ""
        }
        className="space-y-4  "
      />
    </div>
  );
}

const loader = (
  <div className="flex w-screen h-dvh fixed">
    <CentralLoading />
  </div>
);

export default withOrg(FactlyPersona, loader);
