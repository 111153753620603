import React from "react";
import { useParams } from "react-router-dom";

import { errorToast } from "../../util/toasts";
import { AIBlock, UserBlock } from "../../components/chat/chatBlock";
import { isObject } from "../../util/isObject";
import { ReactComponent as TagoreLogoDark } from "../../assets/icons/tagoreAiBlack.svg";

export function FactlyShareChat() {
  const { id } = useParams();

  const [chat, setChat] = React.useState(null); // Initialize with null for loading state

  const getChatByExternalRef = async (id) => {
    try {
      const response = await fetch(
        `${window.REACT_APP_TAGORE_API_URL}/personas/factly-details/shared?external_id=${id}`
      );

      let chat = await response.json();

      let messages = chat?.chat_messages?.map((message) => {
        return {
          role: message?.role,
          content: isObject(message?.content)
            ? message?.content?.input
            : message?.content,
          files: message?.content?.files || [],
        };
      });

      chat.chat_messages = messages;

      if (!response.ok) {
        throw new Error(chat.errors?.[0].message);
      }

      return chat;
    } catch (error) {
      errorToast(error.message); // Display a more informative error message
      return null;
    }
  };

  React.useEffect(() => {
    const fetchChat = async () => {
      const chatData = await getChatByExternalRef(id);
      if (chatData) {
        setChat(chatData);
      }
    };

    fetchChat();
  }, [id]);

  if (chat === null) {
    // Loading state, you can show a loading spinner here
    return <div>Loading...</div>;
  }

  return (
    <>
    <TagoreLogoDark className="mx-auto mt-6"/>
    <main className="flex-col flex w-3/4 mx-auto mt-6">
      <div className="flex justify-between items-center">
        <div className="flex flex-col gap-4">
          <h1 className="text-2xl font-bold dark:text-white">
            {chat?.chat_title}
          </h1>
        </div>
      </div>
      <div className="flex flex-col mt-10">
        {(chat?.chat_messages ?? chat?.messages ?? [])
          .filter((item) => item?.role !== "system")
          .map((item, index) => {
            return (
              <>
                {item?.role === "human" ? (
                  <UserBlock
                    key={index}
                    content={item.content}
                    showEdit={false}
                    files={item?.files}
                  />
                ) : (
                  <AIBlock
                    index={index}
                    content={item.content}
                    showAudio={false}
                    audioURL={item?.audio}
                  />
                )}
              </>
            );
          })}
      </div>
    </main>
    </>
  );
}
