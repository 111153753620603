import React, { useRef, useEffect, useImperativeHandle, forwardRef } from "react";
import Lottie from "react-lottie";
import animationData from "../assets/lottiefiles/master-speaker-input.json";
import { Microphone } from "@phosphor-icons/react";
import { theme } from "../commons/styles";
import { useSpeechRecognition } from "react-speech-recognition";
import SpeechRecognition from "react-speech-recognition";

// AudioRecorder component takes care of recording audio
export const AudioRecorder = forwardRef(({
  recording,
  setRecording,
  speaking,
  setSpeaking,
  setCurrentPrompt,
  textareaRef,
  autoSizeInput,
}, ref) => {
  const {
    transcript,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  const startRecognition = async () => {
    await SpeechRecognition.startListening({
      continuous: true,
      language: "en-US",
    });
    setRecording(true);
    setSpeaking(true);
    resetTranscript();
    setCurrentPrompt("");
  };

  const stopRecognition = async () => {
    await SpeechRecognition.stopListening();
    setRecording(false);
    setSpeaking(false);
  };
  useImperativeHandle(ref, () => ({
    stopRecognition,
    browserSupportsSpeechRecognition
  }), [browserSupportsSpeechRecognition]);

  useEffect(() => {
    if (!browserSupportsSpeechRecognition) {
      setRecording(false);
      setSpeaking(false);
      return;
    }

    if (transcript) {
      setCurrentPrompt((prevPrompt) => {
        autoSizeInput({ target: textareaRef.current });
        return transcript;
      });
    }
  }, [transcript, browserSupportsSpeechRecognition]);

  if (!browserSupportsSpeechRecognition) {
    return null; // Hide the icon if speech recognition is not supported
  }
  const recognition = SpeechRecognition.getRecognition();
  recognition.onsoundend = () => {
    stopRecognition();
  };

  return (
    <>
      {!recording ? (
        <Microphone
          size={theme.iconSize.large}
          color={theme.iconColor.secondary}
          cursor={"pointer"}
          onClick={startRecognition}
          title="Start Recording"
          alt="Start Recording"
        />
      ) : (
        <div
          style={{ zIndex: "10" }}
          onClick={stopRecognition}
          alt="Stop Recording"
          title="Stop Recording"
        >
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: animationData,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            height={32}
            width={32}
            isStopped={!speaking}
          />
        </div>
      )}
    </>
  );
});

export default AudioRecorder;