import { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { ImageSearch } from "../../components/search/imagesSearch";
import {
  generateVariationsOfImage,
  getGeneratedImages,
} from "../../actions/images";
import { HashLoader } from "react-spinners";
import { errorToast } from "../../util/toasts";
import sunflowerImage from "../../assets/sunflower.png";
import astronautImage from "../../assets/astronaut.png";
import brightCity from "../../assets/bright-city.png";
import madPanda from "../../assets/mad-panda.png";
import handDrawnBoat from "../../assets/handrawn-boat.png";
import foxNight from "../../assets/fox-night.png";
import catWithHat from "../../assets/cat-with-hat.png";
import davidWearingHeadphones from "../../assets/david-wearing-headphones.png";
import useDarkMode from "../../hooks/useDarkMode";
import ExampleModal from "./ExamplesModal";
import useWindowSize from "../../hooks/useWindowSize";
import { getURL } from "../../util/validateURL";
import { DropdownIndicator } from "../documents/create";
import { ReactComponent as PaperPlaneRight } from "../../assets/icons/PaperPlaneRight.svg";
import { TopNav } from "../../components/layout/sidebar";

export default function ImagePage() {
  const fileInputRef = useRef(null);
  const { isMobileScreen } = useWindowSize();
  const { darkMode } = useDarkMode();
  const [imageRequest, setImageRequest] = useState({
    prompt: "",
    n: 4,
    provider: "stableDiffusion",
    model: "stable-diffusion-xl-1024-v1-0",
  });
  const ProviderOptions = [
    { value: "stableDiffusion", label: "Stable Diffusion" },
  ];
  const getProviderOption = (value) => {
    return ProviderOptions.find(
      (ProviderOption) => ProviderOption.value === value
    );
  };
  const handleRangeChange = (event) => {
    setImageRequest((prev) => ({ ...prev, n: Number(event.target.value) }));
  };

  const onMouseIn = (index) => {
    setImages(
      images.map((image, i) => {
        if (i === index) {
          return { ...image, isHover: true };
        } else {
          return { ...image, isHover: false };
        }
      })
    );
  };

  const onMouseOut = (index) => {
    setImages(
      images.map((image, i) => {
        return { ...image, isHover: false };
      })
    );
  };

  // loading state variable to control the state of the loading button
  const [loading, setLoading] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalPrompt, setModalPrompt] = useState(null);

  const [images, setImages] = useState([]);

  const handlePromptChange = (e) => {
    setImageRequest({
      ...imageRequest,
      prompt: e.target.value,
    });
  };

  const [isDownload, setIsDownload] = useState(false);

  const [imageURLToBeDwld, setImageURLToBeDwld] = useState(null);

  const handleSearch = (imageRequest) => {
    setLoading(true);
    setIsDownload(true);
    getGeneratedImages(imageRequest)
      .then((response) => {
        setImages(response?.map((image) => ({ ...image, isHover: false })));
      })
      .catch((error) => {
        errorToast(error?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleUploadFile = (e) => {
    setLoading(true);
    generateVariationsOfImage(
      e.target.files[0],
      imageRequest.n,
      "stable-diffusion-xl-1024-v1-0",
      "stableDiffusion"
    )
      .then((response) => {
        setImages(response?.map((image) => ({ ...image, isHover: false })));
      })
      .catch((err) => {
        errorToast("error in generating variations of image " + err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  async function toDataURL(url) {
    const blob = await fetch(url).then((res) => res.blob());
    return URL.createObjectURL(blob);
  }

  // handleProviderChange is a function that handles the change in the provider select element
  const handleProviderChange = (e) => {
    setImageRequest({
      ...imageRequest,
      provider: e.target.value.value,
    });
  };

  const defaultImagePrompts = [
    {
      url: foxNight,
      prompt: "A painting of a fox in the style of Starry Night",
    },
    {
      url: astronautImage,
      prompt: "An astronaut lounging in a tropical resort in space, vaporwave",
    },
    {
      url: catWithHat,
      prompt: "A photo of a cat with a hat on",
    },
    {
      url: davidWearingHeadphones,
      prompt:
        "A photo of Michelangelo's sculpture of David wearing headphones djing",
    },
    {
      url: brightCity,
      prompt:
        "a pencil and watercolor drawing of a bright city in the future with flying cars",
    },
    {
      url: handDrawnBoat,
      prompt: "A hand-drawn sailboat circled by birds on the sea at sunrise",
    },
    {
      url: sunflowerImage,
      prompt:
        "A photograph of a sunflower with sunglasses on in the middle of the flower in a field on a bright sunny day",
    },
    {
      url: madPanda,
      prompt: "panda mad scientist mixing sparkling chemicals, digital art",
    },
  ];

  function downloadImageFromBase64Url(base64Url, fileName) {
    // Convert the Base64 data to a Blob object
    const blob = b64toBlob(base64Url);

    // Create a temporary link element
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;

    // Simulate a click on the link to trigger the download
    link.click();

    // Clean up the URL object
    URL.revokeObjectURL(link.href);
  }

  function b64toBlob(base64Data) {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let i = 0; i < byteCharacters.length; i++) {
      byteArrays.push(byteCharacters.charCodeAt(i));
    }

    const byteArray = new Uint8Array(byteArrays);
    return new Blob([byteArray], { type: "image/png" }); // Modify the MIME type if needed
  }

  return (
    <>
      {!isMobileScreen && (
        <div className="topNavSection pl-10 pr-9 py-7 justify-end flex">
          <TopNav />
        </div>
      )}
      <div
        className={`bg-white rounded-[24px] p-4 md:p-8 min-h-[90vh] mb-8 ${
          isMobileScreen ? "" : "px-10"
        }`}
      >
        <h2
          className={` dark:text-white mb-4 text-[#1E1E1E] text-lg md:text-xl not-italic font-medium leading-5;`}
        >
          Use text to describe your picture
        </h2>
        <div className="flex justify-between items-center gap-3">
          <ImageSearch
            isMobileScreen={isMobileScreen}
            placeholder={
              isMobileScreen
                ? "abstract painted.."
                : `Handmade Unique abstract painting made with charcoal..`
            }
            onChange={handlePromptChange}
            handleSearch={() => handleSearch(imageRequest)}
            isLoading={loading}
            value={imageRequest.prompt}
            disabled={imageRequest.prompt.length < 1}
            searchBtn={false}
            chBtn={false}
          />
          <div
            onClick={() => handleSearch(imageRequest)}
            className="w-fit inline-block transition duration-250 hover:bg-opacity-30 hover:bg-primary-yellow rounded-full p-1 cursor-pointer"
          >
            <PaperPlaneRight />
          </div>
        </div>
        <div
          className={`w-full my-8 flex ${
            isMobileScreen
              ? "flex-col gap-y-4 justify-center items-end"
              : "flex-row justify-end"
          }`}
        >
          <div
            className={`flex flex-col gap-2 w-full lg:flex-row lg:gap-5 lg:w-1/2`}
          >
            <div className="flex flex-row items-center space-x-2 mb-3 w-full">
              <div className="flex flex-col gap-2 w-full">
                <label
                  className={
                    `${darkMode ? "text-[#BEBEBE]" : "text-stone-900"}` +
                    " text-xs font-normal"
                  }
                >
                  Image Count{" "}
                </label>
                <input
                  type="range"
                  min={1}
                  max={8}
                  value={imageRequest.n}
                  onChange={handleRangeChange}
                  className="w-full lg:w-80 bg-gray-300 appearance-none h-1 rounded-lg outline-none accent-primary-grey "
                />
              </div>
              <span className={`dark:text-[#BEBEBE] text-gray-600 mt-6 ml-2`}>
                {imageRequest.n}
              </span>
            </div>
            <Select
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator,
              }}
              styles={{
                control: (style) => {
                  return {
                    ...style,
                    maxHeight: "55px",
                    borderRadius: "10px",
                    border: "1px solid #D1D1D1",
                    background: "#FFF",
                    padding: "8px 14px",
                    boxShadow: "none",
                    "&:hover": {
                      border: "1px solid #D1D1D1",
                    },
                  };
                },
                dropdownIndicator: (style) => {
                  return {
                    ...style,
                    paddingRight: "0px",
                  };
                },
                valueContainer: (style) => {
                  return {
                    ...style,
                    paddingLeft: "0px",
                  };
                },
                placeholder: (style) => {
                  return {
                    ...style,
                    fontWeight: "normal",
                    color: "#A0AEC0",
                  };
                },
              }}
              className="w-full lg:w-[50%]"
              name="provider"
              value={getProviderOption(imageRequest.provider)}
              onChange={(value) => handleProviderChange({ target: { value } })}
              options={ProviderOptions}
            />
          </div>
        </div>
        <div className={`mt-8`}>
          {loading ? (
            <div className={`flex justify-center mt-[20vh]`}>
              <HashLoader color={"#667085"} loading={true} size={100} />
            </div>
          ) : (
            <div
              className={`grid ${
                isMobileScreen ? "grid-cols-2" : "grid-cols-4"
              } gap-8`}
            >
              {images && images.length > 0
                ? images.map((image, index) => {
                    return (
                      <div
                        key={index}
                        className={`w-full rounded-lg relative`}
                        onMouseEnter={() => onMouseIn(index)}
                        onMouseLeave={() => onMouseOut(index)}
                        onClick={() => {
                          setModalOpen(true);
                          setImageURLToBeDwld(image?.url);
                          setModalPrompt({
                            url: getURL(image?.url),
                            prompt: "",
                          });
                          // downloadImageFromBase64Url(image?.url, "image.png")
                        }}
                      >
                        <img
                          alt="generated logos"
                          className="rounded-lg cursor-pointer shadow-primary hover:shadow-md dark:shadow-none"
                          src={getURL(image?.url)}
                        ></img>
                        {image.isHover && (
                          <div className="absolute bg-black top-0 left-0 w-full h-full opacity-50 rounded-lg hover:block cursor-pointer"></div>
                        )}
                      </div>
                    );
                  })
                : defaultImagePrompts.map((image, index) => {
                    return (
                      <div
                        key={index}
                        className={`w-full rounded-lg relative`}
                        onMouseEnter={() => onMouseIn(index)}
                        onMouseLeave={() => onMouseOut(index)}
                        onClick={() => {
                          setModalOpen(true);
                          setModalPrompt(image);
                        }}
                      >
                        <div className="relative">
                          <img
                            alt="generated logos"
                            // onClick={() => downloadImage(image?.url, 'image.png')}
                            className="rounded-lg cursor-pointer shadow-primary hover:shadow-md dark:shadow-none"
                            src={image.url}
                          ></img>
                          <div className="absolute top-0 left-0 w-full h-full flex flex-col bg-white justify-between items-start opacity-0 hover:opacity-80 cursor-pointer transition duration-300 ease-in-out">
                            <p className="text-black font-medium text-xl m-4 font-serif">
                              {image.prompt}
                            </p>
                            <p
                              style={{ color: "#777" }}
                              className=" text-lg m-4"
                            >
                              Click to try{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
            </div>
          )}
          <ExampleModal
            visible={modalOpen}
            setImageRequest={setImageRequest}
            imageRequest={imageRequest}
            imageUrl={modalPrompt?.url}
            prompt={modalPrompt?.prompt}
            handleSearch={handleSearch}
            onClose={() => setModalOpen(false)}
            isDownload={isDownload}
            handleDownload={() => {
              downloadImageFromBase64Url(imageURLToBeDwld, "image.png");
              setIsDownload(true);
              setImageURLToBeDwld(null);
            }}
          />
        </div>
      </div>
    </>
  );
}

//     {/* image variations division and a selector for choosing the generative model provider */}
