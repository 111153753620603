import { getProfile } from "../../actions/profile";
import { errorToast } from "../../util/toasts";
import { ADD_PROFILE, SET_PROFILE_LOADING } from "../constants/profile";

export const addProfile = () => {
  return (dispatch, getState) => {
    dispatch(loadingProfile());
    return getProfile()
      .then((res) => {
        dispatch({
          type: ADD_PROFILE,
          payload: res,
        });
      })
      .catch(() => {
        errorToast("Unable to fetch profile. Please try again later.");
      })
      .finally(() => {
        dispatch(stopProfileLoading());
      });
  };
};

export const loadingProfile = () => ({
  type: SET_PROFILE_LOADING,
  payload: true,
});

export const stopProfileLoading = () => ({
  type: SET_PROFILE_LOADING,
  payload: false,
});
