export const MaxLimitWarning = ({
  handleNewChat,
  handleExplorePersonas,
  convLimit,
}) => {
  return (
    <div className="w-full px-20 py-4 bg-[#FEE141]">
      {convLimit ? (
        <>
          Each conversation is limited to 5 user/human prompts and maximum 5
          conversations/day. You can explore our other{" "}
          <span
            className="text-black underline font-semibold cursor-pointer"
            onClick={handleExplorePersonas}
          >
            Personas
          </span>
        </>
      ) : (
        <>
          Each conversation is limited to 5 user/human prompts and maximum 5
          conversations/day. You can always start a{" "}
          <span
            className="text-black underline font-semibold cursor-pointer"
            onClick={handleNewChat}
          >
            New Chat
          </span>{" "}
          or explore our other{" "}
          <span
            className="text-black underline font-semibold cursor-pointer"
            onClick={handleExplorePersonas}
          >
            Personas
          </span>
        </>
      )}
    </div>
  );
};
