import { withOrg } from "../../components/organisation/withOrg";
import { useEffect, useState } from "react";
import { theme } from "../../commons/styles";
import { SSE } from "sse.js";
import { AIBlock, UserBlock } from "../../components/chat/chatBlock";
import AlwaysScrollToBottom from "../../components/scroll/scrollToBottom";
import { BeatLoader } from "react-spinners";
import { deleteChatByID, getShareableExternalRef } from "../../actions/chat";
import { errorToast, successToast } from "../../util/toasts";
import { ToastContainer } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { ChatSidebar } from "../../components/chat/sidebar";
import { ChatInput } from "../../components/chat/chatInput";
import { ChatNavbar } from "../../components/chat/chatNavbar";
import CentralLoading from "../../components/loader/centralLoading";
import { getPersonaByID, getPersonaChatsByUserID } from "../../actions/persona";

function PersonaChat({ selectedOrg }) {
  const modelValueToLabel = {
    "gpt-3.5-turbo": "GPT-3.5 Turbo",
    "gpt-4": "GPT 4",
    "claude-2": "Claude 2",
    "claude-instant": "Claude Instant",
    "chat-bison": "Google's Chat Bison",
    "meta-llama/Llama-2-70b-chat-hf": "LLAMA 2",
  };

  const navigate = useNavigate();

  const [sidebarVisible, setSidebarVisible] = useState(true);
  // sseClient is client for fetching chat messages using server side events
  const [sseClient, setSSEClient] = useState(null);

  const [chat, setChat] = useState({
    id: null,
    messages: [],
  });

  // responseLoading is a boolean that is true when the response for chat is being fetched
  // this is used to show a loading indicator
  const [responseLoading, setResponseLoading] = useState(false);
  const [loading, setLoading] = useState(true);

  const [persona, setPersona] = useState({});
  const [stream, setStream] = useState(true);
  const { id } = useParams();
  useEffect(() => {
    setLoading(true);
    getPersonaByID(id)
      .then((response) => {
        setPersona(response);
      })
      .catch(() => {
        errorToast("Unable to fetch persona. Please try again.");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  const [settings, setSettings] = useState({
    model: {
      value: "claude-2",
      label: "Claude 2",
    },
    temperature: 0.9,
    system_prompt: "",
  });

  const [showSettings, setShowSettings] = useState(false);

  const [currentPrompt, setCurrentPrompt] = useState("");

  const [editIndex, setEditIndex] = useState(null);

  const [scrollUp, setScrollUp] = useState(false);

  const [chatHistory, setChatHistory] = useState({
    count: 0,
    results: [],
  });

  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    search_query: "",
  });

  const [sharedLink, setSharedLink] = useState("");

  const [showShareChat, setShowShareChat] = useState(false);

  const toggleShareChat = () => {
    setShowShareChat(!showShareChat);
  };

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const fetchChatHistory = async () => {
    getPersonaChatsByUserID(id, pagination)
      .then((data) => {
        setChatHistory({
          results: data.chats,
          count: data.total,
        });
      })
      .catch((err) => {
        errorToast(err);
      });
  };

  useEffect(() => {
    fetchChatHistory();
  }, [pagination.limit, pagination.page]);

  useEffect(() => {
    const timer = setTimeout(() => {
      // get the chat history of the user
      getPersonaChatsByUserID(id, pagination)
        .then((data) => {
          setChatHistory({
            results: data.chats,
            count: data.total,
          });
        })
        .catch((err) => {
          errorToast(err);
        });
    }, 500);

    return () => clearTimeout(timer);
  }, [pagination.search_query]);

  useEffect(() => {
    // Attach the scroll event listener when the component mounts
    const scrollContainer = document?.getElementById("chat-display");
    scrollContainer?.addEventListener("wheel", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      scrollContainer?.removeEventListener("wheel", handleScroll);
    };
  }, []);

  // getAudioBlob is a callback function passed to AudioRecorder
  // it gets called when the onStop event is fired

  const getAudioBlob = () => console.log("getAudioBlob");

  // async (audioURL) => {
  //   let audioBlob = new Blob(
  //     [new Uint8Array(await (await fetch(audioURL)).arrayBuffer())],
  //     { type: "audio/webm " }
  //   );
  //   // making a file out of this blob
  //   const audioContext = new (window.AudioContext ||
  //     window.webkitAudioContext)();
  //   const sampleRate = audioContext.sampleRate;

  //   const formData = new FormData();
  //   formData.append("sampleRate", sampleRate); // Append the sample rate
  //   formData.append("audio", audioBlob);

  //   formData.append("model", settings.model.value);
  //   formData.append("provider", modelToProvider[settings.model.value]);
  //   formData.append("temperature", settings.temperature);
  //   formData.append("additional_instructions", additionalInstructions);

  //   if (modelToProvider[settings.model.value] === "google") {
  //     formData.append("stream", false);
  //   } else {
  //     formData.append("stream", true);
  //   }

  //   if (chat.id) {
  //     formData.append("chat_id", chat.id);
  //   }

  //   if (settings.system_prompt) {
  //     formData.append("system_prompt", settings.system_prompt);
  //   }

  //   handleAudioSubmit(formData);

  //   setChat({
  //     id: chat.id,
  //     messages: [
  //       ...chat.messages,
  //       {
  //         content: "generating response for the audio...",
  //         audioURL: audioURL,
  //         type: "user",
  //       },
  //     ],
  //   });
  // };

  const handleAudioSubmit = (formdata) => console.log(formdata);

  // const handleAudioSubmit = (formData) => {
  //  var requestBody ={
  //         messages: newMessages,
  //         additional_instructions:
  //           "The response should be in markdown format(IMPORTANT).",
  //         stream: stream,
  //       };
  //   var source =  new SSE(
  //     window.REACT_APP_TAGORE_API_URL + `/personas/${id}/chats`,
  //     {
  //       payload: JSON.stringify(requestBody),
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         "X-Organisation": selectedOrg,
  //       },
  //       withCredentials: true,
  //     }
  //   );
  //   setResponseLoading(true);
  //   setSSEClient(source);

  //   source?.addEventListener("message", (event) => {
  //     let chatObject = JSON.parse(event.data);
  //     // set messages and id in chat state
  //     setChat({
  //       id: chatObject.id,
  //       messages: chatObject?.messages,
  //       model: chatObject?.model,
  //       system_prompt: chatObject?.messages[0]?.content,
  //     });
  //   });

  //   source?.addEventListener("error", (event) => {
  //     // setIsEditing({ status: false, id: null });
  //     source?.close();
  //     setResponseLoading(false);
  //     setSSEClient(null);
  //     if (!String(event.data).includes("[DONE]")) {
  //       return;
  //     }
  //   });

  //   source?.stream();
  //   setScrollUp(false);
  // };

  const handleChatSubmit = () => {
    handleStreamRequest();
  };

  const handleStreamRequest = () => {
    setResponseLoading(true);

    let currentMessage = {
      role: "user",
      content: currentPrompt,
    };

    const newMessages = [...chat.messages, currentMessage];

    var requestBody = {
      messages: newMessages,
      additional_instructions:
        "The response should be in markdown format(IMPORTANT).",
      stream: stream,
    };

    if (chat.id) {
      requestBody.id = chat.id;
    }

    if (settings.system_prompt) {
      requestBody.system_prompt = settings.system_prompt;
    }

    setChat({
      ...chat,
      messages: newMessages,
    });

    setCurrentPrompt("");

    var source = new SSE(
      window.REACT_APP_TAGORE_API_URL + `/personas/${id}/chats`,
      {
        payload: JSON.stringify(requestBody),
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Organisation": selectedOrg,
        },
        withCredentials: true,
      }
    );

    setSSEClient(source);

    source?.addEventListener("message", (event) => {
      let chatObject = JSON.parse(event.data);
      setChat({
        id: chatObject.id,
        messages: chatObject?.messages,
        model: chatObject?.model,
        system_prompt: chatObject?.messages[0]?.content,
      });
    });

    source?.addEventListener("error", (event) => {
      source?.close();
      setSSEClient(null);
      setResponseLoading(false);
      if (!String(event.data).includes("[DONE]")) {
        return;
      }
    });

    source?.stream();
    setScrollUp(false);
  };

  const handleKeyDown = (e) => {
    // add an event to handle shift + enter
    if (e.keyCode === 13 && e.shiftKey) {
      return;
    }

    if (e.keyCode === 13 && !responseLoading && currentPrompt !== "") {
      handleChatSubmit();
    }
  };

  // handleRegenerate is a callback function passed to AIBlock
  // it gets called when the user clicks on the regenerate button
  const handleRegenerate = () => {
    let newMessages = chat.messages.slice(0, chat.messages.length - 1);
    setResponseLoading(true);
    setChat({
      id: chat.id,
      messages: newMessages,
    });

    var requestBody = {
      messages: newMessages,
      additional_instructions:
        "The response should be in markdown format(IMPORTANT).",
      stream: stream,
    };

    if (chat.id) {
      requestBody.id = chat.id;
    }

    var source = new SSE(
      window.REACT_APP_TAGORE_API_URL + `/personas/${id}/chats`,
      {
        payload: JSON.stringify(requestBody),
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Organisation": selectedOrg,
        },
        withCredentials: true,
      }
    );

    setSSEClient(source);

    source?.addEventListener("message", (event) => {
      let chatObject = JSON.parse(event.data);
      setChat({
        id: chatObject.id,
        messages: chatObject?.messages,
        model: chatObject?.model,
        system_prompt: chatObject?.messages[0]?.content,
      });
    });

    source?.addEventListener("error", (event) => {
      source?.close();
      setSSEClient(null);
      setResponseLoading(false);
      if (!String(event.data).includes("[DONE]")) {
        return;
      }
    });

    source?.stream();

    setScrollUp(false);
  };

  const handleScroll = (e) => {
    if (!responseLoading) {
      if (e?.deltaY < 0 || isNaN(e.deltaY)) {
        setScrollUp(true);
      } else {
        setScrollUp(false);
      }
    }
  };

  const handleStop = () => {
    sseClient?.close();
    setSSEClient(null);
    setResponseLoading(false);
    setScrollUp(false);
  };

  const handleEdit = (text, index) => {
    setResponseLoading(true);
    let newMessages = chat.messages.slice(0, index + 2);

    newMessages[newMessages.length - 1] = {
      role: "user",
      content: text,
    };

    setChat({
      id: chat.id,
      messages: newMessages,
    });

    var requestBody = {
      messages: newMessages,
      additional_instructions:
        "The response should be in markdown format(IMPORTANT).",
      stream: stream,
    };

    if (chat.id) {
      requestBody.id = chat.id;
    }

    if (settings.system_prompt) {
      requestBody.system_prompt = settings.system_prompt;
    }

    requestBody.stream = true;

    var source = new SSE(
      window.REACT_APP_TAGORE_API_URL + `/personas/${id}/chats`,
      {
        payload: JSON.stringify(requestBody),
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Organisation": selectedOrg,
        },
        withCredentials: true,
      }
    );

    setSSEClient(source);

    source?.addEventListener("message", (event) => {
      let chatObject = JSON.parse(event.data);
      setChat({
        id: chatObject.id,
        messages: chatObject?.messages,
        model: chatObject?.model,
        system_prompt: chatObject?.messages[0]?.content,
      });
    });

    source?.addEventListener("error", (event) => {
      source?.close();
      setSSEClient(null);
      setResponseLoading(false);
      if (!String(event.data).includes("[DONE]")) {
        return;
      }
    });

    source?.stream();

    setScrollUp(false);
  };

  const handleSelectChat = (chat) => {
    setChat({
      id: chat.id,
      messages: chat.messages,
    });

    setSettings({
      model: {
        value: chat.model,
        label: modelValueToLabel[chat.model],
      },
      temperature: chat.temperature,
      system_prompt: chat.messages[0].content,
    });
  };

  const handleShareLink = async () => {
    if (chat.messages.length === 0) {
      errorToast("Please start a conversation first");
      return;
    }

    getShareableExternalRef(chat.id, selectedOrg)
      .then((ref) => {
        let link;
        if (process.env.NODE_ENV === "development") {
          link = `${window.location.origin}${window.REACT_APP_PUBLIC_URL}/chat/shared/${ref}`;
        } else {
          link = `${window.location.origin}/chat/shared/${ref}`;
        }
        setSharedLink(link);
      })
      .then(() => {
        toggleShareChat(true);
      });
  };

  const handleNewChat = () => {
    setChat({
      id: null,
      messages: [],
    });
    setSettings({
      model: {
        value: "claude-2",
        label: "Claude 2",
      },
      temperature: 0.9,
      system_prompt: "",
    });

    setCurrentPrompt("");

    setScrollUp(false);

    setSSEClient(null);

    setResponseLoading(false);

    setEditIndex(null);
  };
  const handleDeleteChat = (chatID) => {
    deleteChatByID(chatID)
      .then(() => {
        fetchChatHistory();
        successToast("Chat deleted successfully");
        setChat({
          id: null,
          messages: [],
        });
      })
      .catch((err) => {
        errorToast(err);
        errorToast("Failed to delete chat");
      });
  };

  return (
    <div className="flex w-screen h-dvh fixed">
      {loading ? (
        <CentralLoading />
      ) : (
        <>
          <ChatSidebar
            sidebarVisible={sidebarVisible}
            toggleSidebar={toggleSidebar}
            chatHistory={chatHistory}
            setPagination={setPagination}
            chat={chat}
            pagination={pagination}
            handleSelectChat={handleSelectChat}
            handleNewChat={handleNewChat}
            navigate={() => navigate("/personas")}
            handleDeleteChat={handleDeleteChat}
          />
          <div
            className={`
            ${sidebarVisible ? "flex-[7]" : "w-full"}
            h-full flex justify-between flex-col
          `}
          >
            {/* chat navbar  */}
            <ChatNavbar
              sidebarVisible={sidebarVisible}
              handleNewChat={handleNewChat}
              handleShareLink={handleShareLink}
              chat={chat}
              toggleSidebar={toggleSidebar}
              showSettings={showSettings}
              showSettingsIcon={false}
              showShare={false}
              setShowSettings={setShowSettings}
              persona={persona.name}
              isPersona={true}
              persona_description={persona?.description}
              showLimit={false}
              isConnected={true}
            />
            <div
              className={`w-full flex ${
                !sidebarVisible && "justify-center"
              } flex-[11]`}
            >
              <div
                className={`flex flex-col px-10 justify-center ${
                  !sidebarVisible ? "w-4/5" : "w-full"
                }`}
              >
                {/* chat display */}
                <div
                  className="flex-[10] py-8 px-9 flex flex-col gap-8 overflow-y-scroll max-h-[80vh]"
                  onScroll={handleScroll}
                  id="chat-display"
                >
                  {chat.messages
                    .filter((message) => message.role != "system")
                    .map((message, index) => {
                      if (message.role === "user") {
                        return (
                          <UserBlock
                            key={index}
                            content={message?.content}
                            audioURL={message?.audio}
                            editing={editIndex === index}
                            onEditClick={() => {
                              setEditIndex(index);
                            }}
                            onEditCancel={() => setEditIndex(null)}
                            onEditSubmit={(text) => {
                              handleEdit(text, index);
                            }}
                          />
                        );
                      } else {
                        return (
                          <AIBlock
                            key={index}
                            content={message.content}
                            audioURL={message.audioURL}
                            showRegenerate={
                              index === chat.messages.length - 2 &&
                              !responseLoading
                            }
                            triggerRegenerate={handleRegenerate}
                            audioIndex={-1}
                          />
                        );
                      }
                    })}
                  {responseLoading && !scrollUp && (
                    <div className="flex justify-center mt-4">
                      <AlwaysScrollToBottom chat={chat.messages} />
                      <BeatLoader
                        size={theme.iconSize.large}
                        color={"#CED0D4"}
                      />
                    </div>
                  )}
                </div>
                {/* chat display */}

                <ChatInput
                  loading={responseLoading}
                  handleStop={handleStop}
                  currentPrompt={currentPrompt}
                  setCurrentPrompt={setCurrentPrompt}
                  handleKeyDown={handleKeyDown}
                  getAudioBlob={getAudioBlob}
                  handleChatSubmit={handleChatSubmit}
                />
              </div>
            </div>
          </div>
          {/* {showShareChat && (
          <div className="absolute w-screen h-dvh bg-white opacity-90">
            <div
              className={`absolute top-16 right-16 p-6 flex flex-col bg-[#f9f9f9] border border-[#D1D1D1] rounded-md gap-4 z-[50] w-[500px]`}
            >
              <span>Share Link to this Chat</span>
              <div className="max-h-[320px] overflow-y-auto bg-white z-[999] w-full">
                {chat.messages
                  .filter((item) => item.role !== "system")
                  .map((item, index) => {
                    return (
                      <>
                        {item.role === "user" ? (
                          <UserBlock
                            key={index}
                            content={item.content}
                            showEdit={false}
                          />
                        ) : (
                          <AIBlock index={index} content={item.content} />
                        )}
                      </>
                    );
                  })}
              </div>
              <span className="text-[#666]">
                Anyone with the URL will be able to view the shared chat but wont
                be able to make any changes.
              </span>
              <div className="flex items-center justify-center gap-4">
                <button
                  className="px-2 py-3 border text-[#798897] border-[#798897] rounded-md"
                  onClick={() => toggleShareChat()}
                >
                  Cancel
                </button>
                <button
                  className="px-2 py-3 bg-[#798897] text-white rounded-md"
                  onClick={() => {
                    navigator.clipboard.writeText(sharedLink);
                    successToast("Link copied to clipboard");
                  }}
                >
                  Copy Link
                </button>
              </div>
            </div>
          </div>
        )} */}
          <ToastContainer />
        </>
      )}
    </div>
  );
}

export default withOrg(PersonaChat);
