import React, { useState } from "react";

export const Tooltip = ({ children, text }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div
      className="relative"
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {children}
      {showTooltip && (
        <div className="absolute top-0 left-0 bg-gray-700 text-white p-2 rounded min-w-[200px] overflow-auto z-[9999]">
          {text}
        </div>
      )}
    </div>
  );
};
