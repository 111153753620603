import { useEffect } from "react";
import { getAppAccess } from "./redux/actions/appAccess";
import { getOrgs } from "./redux/actions/orgs";
import { useDispatch, useSelector } from "react-redux";
import { addProfile } from "./redux/actions/profile";
import { Outlet, useNavigate } from "react-router-dom";

export default function Auth() {
  const dispatch = useDispatch();
  const { selectedOrgID } = useSelector(({ organisations }) => {
    return {
      selectedOrgID: organisations?.selectedOrg,
    };
  });
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getOrgs(navigate)).then(() => {
      dispatch(getAppAccess());
    });
    dispatch(addProfile());
  }, [selectedOrgID]);

  return <Outlet></Outlet>;
}
