export const SizeButton = ({
  isSelected,
  title,
  maxSize,
  clickAction,
  isCustom,
}) => {
  return (
    <button
      onClick={() => clickAction(maxSize, title, isCustom)}
      className={`bg-button-primary leading-tight dark:bg-background-sidebar-alt text-sm px-3.5 py-2 rounded-md ${
        isSelected && "border border-[#4D515C]"
      } ${
        isSelected
          ? "text-stone-900 text-xs font-medium  dark:text-white"
          : "text-stone-500 text-xs font-medium dark:first-letter:"
      }`}
    >
      {title}
    </button>
  );
};
