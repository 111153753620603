import { useEffect, useState } from "react";
import { CreateButton } from "../../components/buttons/CreateButton";
import PersonaCard from "../../components/cards/personaCard";
import Search, { ExpandableSearch } from "../../components/search";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "./pagination";
import { getPersona } from "../../actions/persona";
import { errorToast, successToast } from "../../util/toasts";
import useWindowSize from "../../hooks/useWindowSize";
import { deletePersonaById } from "../../actions/persona";
import { getURL } from "../../util/validateURL";
import { TopNav } from "../../components/layout/sidebar";

import BudgetSpeak from "../../assets/persona/BudgetSpeak.jpg";
import Parlens from "../../assets/persona/Parlens.jpg";
import PIB from "../../assets/persona/PIB.jpg";
import SachFactCheck from "../../assets/persona/SachFactCheck.jpg";
import BallotVerse from "../../assets/persona/BallotVerse.jpg";
import Trade from "../../assets/persona/Trade.jpg";
import CSR from "../../assets/persona/CSR.jpg";
import Constituition from "../../assets/Constitution.jpg"
import CentralActs from "../../assets/CentralActs.jpg"
import FactlyArticles from "../../assets/factlyArticles.jpg"
import Dataful from "../../assets/Dataful.jpg"
import { useSelector } from "react-redux";
import { usePostHog } from "posthog-js/react";
import { dispatchPosthog } from "../../util/posthog";

export default function Personas() {
  const posthog = usePostHog();

  const navigate = useNavigate();

  const [tab, setTab] = useState("is_factly");

  const [pagination, setPagination] = useState({
    page: 1,
    limit: 8,
    search_query: "",
    count: 0,
    filter: "is_factly",
  });
  console.log(pagination.count, "count");

  const setCurrentPage = (page) => {
    setPagination((prev) => {
      return {
        ...prev,
        page,
      };
    });
  };
  const [searchQuery, setSearchQuery] = useState("");
  // personData is the data of the persona on the current page
  const [personaData, setPersonaData] = useState([]);

  const { organisations, profile } = useSelector(
    ({ organisations, profile }) => {
      return {
        organisations,
        profile,
      };
    }
  );

  const map = {
    "pib chronicle": {
      image: PIB,
      onClick: () => {
        navigate("/personas/factly?persona_id=pib");
      },
    },
    "sach-fact-check": {
      image: SachFactCheck,
      onClick: () => {
        navigate("/personas/factly?persona_id=sach");
      },
    },
    parlens: {
      image: Parlens,
      onClick: () => {
        navigate("/personas/factly?persona_id=parlens");
      },
    },
    budgetspeak: {
      image: BudgetSpeak,
      onClick: () => {
        navigate("/personas/factly?persona_id=budgetspeech");
      },
    },
    "ballotverse india": {
      image: BallotVerse,
      onClick: () => {
        navigate("/personas/factly?persona_id=lse");
      },
    },
    tradetracer: {
      image: Trade,
      onClick: () => {
        navigate("/personas/factly?persona_id=tt");
      },
    },
    "csr explorer": {
      image: CSR,
      onClick: () => {
        navigate("/personas/factly?persona_id=csr");
      },
    },
    constitution: {
      image: Constituition,
      onClick: () => {
        navigate("/personas/factly?persona_id=constitution");
      },
    },
    central_acts: {
      image: CentralActs,
      onClick: () => {
        navigate("/personas/factly?persona_id=central_acts");
      },
    },
    factly_articles:{
      image: FactlyArticles,
      onClick: () => {
        navigate("/personas/factly?persona_id=factly_articles");
      },
    },
    dataful:{
      image: Dataful,
      onClick: () => {
        navigate("/personas/factly?persona_id=dataful");
      },
    }
  };

  const openAi = ["ballotverse india", "tradetracer", "csr explorer"];

  const factlyPersona = [
    {
      key: "sach",
      image: SachFactCheck,
      name: "SACH Fact Check",
      desc: "Empowering Users to Navigate Fact and Fiction. Your Gateway to Verified Information and Debunked Hoaxes from Claim Review.",
      id: "-1",
      is_default: true,
      isFactly: true,
      onclick: () => {
        navigate("/personas/factly?persona_id=sach");

        dispatchPosthog(posthog, "Sach Persona Clicked", {});
      },
    },
    {
      key: "parlens",
      image: Parlens,
      name: "Parlens",
      desc: "Engage with the wealth of Lok Sabha and Rajya Sabha data effortlessly with Parlens, your conversational guide to parliamentary knowledge.",
      id: "-2",
      is_default: true,
      isFactly: true,
      onclick: () => {
        navigate("/personas/factly?persona_id=parlens");

        dispatchPosthog(posthog, "Parlens Persona Clicked", {});
      },
    },
    {
      key: "pib",
      image: PIB,
      name: "PIB Chronicle",
      desc: "Journey through the present and past with PIB Chronicle, your gateway to daily and historical insights from the Government of India's press releases.",
      id: "-5",
      is_default: true,
      isFactly: true,
      onclick: () => {
        navigate("/personas/factly?persona_id=pib");

        dispatchPosthog(posthog, "PIB Persona Clicked", {});
      },
    },
    {
      key: "budgetspeech",
      image: BudgetSpeak,
      name: "BudgetSpeak",
      desc: "Delve into the heart of India's financial decisions with BudgetSpeak, where budget speeches come alive through insightful conversations.",
      id: "-3",
      is_default: true,
      isFactly: true,
      onclick: () => {
        navigate("/personas/factly?persona_id=budgetspeech");

        dispatchPosthog(posthog, "BudgetSpeak Persona Clicked", {});
      },
    },
  ];

  const openAIAsst = [
    {
      key: "lse",
      image: BallotVerse,
      name: "BallotVerse India",
      desc: "Embark on a dialogue with the pulse of democracy – BallotVerse, decoding Lok Sabha Elections data through engaging conversations.",
      id: "-4",
      is_default: true,
      isFactly: true,
      onclick: () => {
        navigate("/assistants/chat?asst_id=lse");

        dispatchPosthog(posthog, "Ballotverse Persona Clicked", {});
      },
    },
    {
      key: "tt",
      image: Trade,
      name: "TradeTracer",
      desc: "Empowering decisions with TradeTracer, where expertise meets comprehensive trade data, illuminating India's export and import landscape.",
      id: "-6",
      is_default: true,
      isFactly: true,
      onclick: () => {
        navigate("/assistants/chat?asst_id=tt");

        dispatchPosthog(posthog, "Tradetracer Persona Clicked", {});
      },
    },
    {
      key: "csr",
      image: CSR,
      name: "CSR Explorer",
      desc: "Explore the social footprint of businesses with CSR Explorer, your guide to comprehensive corporate social responsibility data and insights.",
      id: "-7",
      is_default: true,
      isFactly: true,
      onclick: () => {
        navigate("/assistants/chat?asst_id=csr");

        dispatchPosthog(posthog, "CSR Persona Clicked", {});
      },
    },
  ];

  // useEffect(() => {
  //   getPersona({
  //     page: pagination.page,
  //     limit: pagination.limit,
  //     search_query: pagination.search_query,
  //   })
  //     .then((data) => {
  //       setPersonaData(data.personas);
  //     })
  //     .catch((err) => {
  //       errorToast("Something went wrong! Please try again later.");
  //     });
  // }, [pagination.page, pagination.limit]);

  useEffect(() => {
    getPersona({
      page: pagination.page,
      limit: pagination.limit,
      search_query: pagination.search_query,
      filter: tab,
    }).then((data) => {
      setPagination((prev) => ({
        ...prev,
        count: data.count,
      }));
      setPersonaData(data.personas);
    });
  }, [
    pagination.search_query,
    pagination.page,
    pagination.limit,
    tab,
    factlyPersona.length,
    openAIAsst.length,
  ]);

  useEffect(() => {
    let timer = setTimeout(() => {
      setPagination((prev) => ({
        ...prev,
        search_query: searchQuery,
        page: 1,
      }));
    }, 500);
    return () => clearTimeout(timer);
  }, [searchQuery]);

  const { isMobileScreen } = useWindowSize();

  const handleDelete = (id) => {
    if (!id || id === "") return;

    deletePersonaById(id)
      .then((response) => {
        const newPersonaData = personaData.filter((persona) => {
          return persona.id !== id;
        });
        setPersonaData(newPersonaData);
        successToast("Persona deleted successfully");
      })
      .catch((err) => {
        errorToast("Error deleting persona");
      });
  };

  return (
    <>
      <div className="flex pl-10 pr-9 py-7 flex-row items-center -md:px-6 -md:justify-between gap-5">
        {isMobileScreen && (
          <>
            <span className="text-lg">Personas</span>
            <div className="flex gap-4 items-center">
              <div className="flex justify-end items-center w-[75%] ">
                <ExpandableSearch
                  placeholder={"search Personas"}
                  onChange={(e) => {
                    setPagination({
                      ...pagination,
                      search_query: e.target.value,
                    });
                  }}
                />
              </div>
              <Link to="/personas/create">
                <CreateButton icon={true} text="Create Personas" />
              </Link>
            </div>
          </>
        )}
        {!isMobileScreen && (
          <Link to="/personas/create">
            <CreateButton text={"Create Personas"} icon={false} />
          </Link>
        )}
        {!isMobileScreen && (
          <ExpandableSearch
            width="auto"
            placeholder={"search Personas"}
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
            handleSearch={() => {
              setPagination((prev) => {
                return {
                  ...prev,
                  search_query: searchQuery,
                };
              });
            }}
          />
        )}
        {!isMobileScreen && (
          <div className="ml-auto -md:hidden">
            <TopNav />
          </div>
        )}
      </div>
      <div className="md:p-10 px-6 pt-6 mt-0 bg-white rounded-[24px]">
        <div className="flex md:flex-row flex-col gap-5 justify-between items-center">
          <div className="text-stone-900 text-xl font-medium leading-tight -md:hidden">
            Your personalized assistants
          </div>
          <div
            className="
          -md:flex -md:static -md:items-center -md:justify-around
           md:w-96 w-full h-14  relative bg-white rounded-lg border border-neutral-300 cursor-pointer"
          >
            <div
              className={
                "md:w-32 w-[30%] h-10 px-3 py-2.5 left-[8px] top-[8px] md:absolute rounded justify-center items-center gap-2 inline-flex" +
                `${tab === "all" ? " bg-primary-yellow font-medium" : ""}`
              }
              onClick={() => {
                setTab("all");
                setPagination((prev) => {
                  return { ...prev, page: 1 };
                });
              }}
            >
              <div className="text-center text-stone-900 text-sm font-medium ">
                All
              </div>
            </div>
            <div
              className={
                "md:w-32 w-[30%] h-10 px-3 py-2.5 left-[120px] top-[8px] md:absolute rounded justify-center items-center gap-2 inline-flex" +
                `${
                  tab === "created"
                    ? " bg-primary-yellow font-medium"
                    : "font-normal"
                } `
              }
              onClick={() => {
                setTab("created");
                setPagination((prev) => {
                  return { ...prev, page: 1 };
                });
              }}
            >
              <div className="text-center text-stone-900 text-sm  ">
                Created
              </div>
            </div>
            <div
              className={
                "md:w-32 w-[30%] h-10 px-3 py-2.5 right-[8px] top-[8px] md:absolute rounded justify-center items-center gap-2 inline-flex" +
                `${tab === "is_factly" ? " bg-primary-yellow font-medium" : ""}`
              }
              onClick={() => {
                setTab("is_factly");
                setPagination((prev) => {
                  return { ...prev, page: 1 };
                });
              }}
            >
              <div className="text-center text-stone-900 text-sm font-medium ">
                Factly
              </div>
            </div>
          </div>
        </div>
        {/* This is Page Items */}
        <div className="grid gap-8 my-10 md:grid-cols-2 grid-cols-1">
          {personaData.map((persona, index) => {
            // determine whether its openAI or factly
            if (openAi.includes(persona.slug) || persona.is_factly) {
              return (
                <PersonaCard
                  meta_id={persona.slug}
                  key={persona.slug}
                  image={map[persona.slug]?.image}
                  name={persona.name}
                  desc={persona.description}
                  id={persona.id}
                  isDefault={true}
                  onCardClick={() => map[persona.slug].onClick()}
                  isOwner={isOwner(organisations)}
                />
              );
            }
            return (
              <PersonaCard
                key={index}
                image={getURL(persona?.avatar)}
                name={persona?.name}
                desc={persona?.description}
                id={persona?.id}
                handleDelete={handleDelete}
                isDefault={persona?.is_default}
                onCardClick={() => {
                  navigate(`/personas/${persona?.id}/chat`);
                }}
              />
            );
          })}
        </div>
        {tab !== "Factly" && (
          <Pagination
            totalPages={Math.ceil(pagination.count / pagination.limit)}
            currentPage={pagination.page}
            setCurrentPage={setCurrentPage}
          />
        )}
      </div>
    </>
  );
}

const isOwner = (orgs) => {
  return (
    orgs?.details?.find((org) => org?.id === orgs.selectedOrg)?.role === "owner"
  );
};
