import { langMap } from "./language";
import { voiceMap } from "./voice";
import { franc } from 'franc-min';

const filterContent = (text, detectedLangCode) => {
        return text
               .replace(/```[\s\S]*?```/g, '') // Remove code blocks
               .replace(/\[(.*?)\]\(.*?\)/g, '$1'); // Replace URLs with their text
  };

export const speakText = async (text) => {
    try {
      const detectedLangCode = franc(text);
      const languageCode = langMap[detectedLangCode] || 'en-US';
      const filteredText = filterContent(text, detectedLangCode);
      const voiceName = voiceMap[languageCode] || `${languageCode}-Wavenet-A`;
  
      const url = `https://texttospeech.googleapis.com/v1/text:synthesize?key=${window.REACT_APP_GOOGLE_TTS_API_KEY}`; // temp add from config.js
      console.log(process.env.REACT_APP_G_TRANSLATE_KEY,"process.env.REACT_APP_G_TRANSLATE_KEY") // test env from docker

      const requestBody = JSON.stringify({
        input: { text: filteredText },
        voice: { languageCode: languageCode, name: voiceName },
        audioConfig: { audioEncoding: 'MP3' }
      });
  
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: requestBody
      });
  
      if (!response.ok) {
        throw new Error(`Failed to generate speech: ${response.statusText}`);
      }
  
      const result = await response.json();
      const audioContent = result.audioContent;
      return 'data:audio/mp3;base64,' + audioContent;
    } catch (error) {
      throw new Error(`Error generating speech: ${error.message}`);
    }
  };
  