export const transcribeAudio = (formData) => {
  return fetch(window.REACT_APP_TAGORE_API_URL + "/audio/transcribe", {
    body: formData,
    credentials: "include",
    method: "POST",
  }).then((response) => {
    if (response.status === 200) {
      return response.json();
    } else {
      return response.json().then((data) => {
        throw Error(data.errors?.[0].message);
      });
    }
  });
};

export const textToSpeech = ({ index, chat_id, org_id }) => {
  return fetch(
    window.REACT_APP_TAGORE_API_URL + "/chat/convert-text-to-audio",
    {
      body: JSON.stringify({
        chat_id,
        message_index: index,
      }),
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-Organisation": org_id,
      },
    }
  )
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        return response.json().then((data) => {
          throw Error(data.errors?.[0].message);
        });
      }
    })
    .then((data) => {
      return data;
    });
};
