import tagoreChat from "../../assets/icons/tagore-chat.svg";
import {
  ArrowCounterClockwise,
  Clipboard,
  CheckCircle,
  User,
  PencilSimple,
  SpeakerSimpleHigh,
  PlayPause,
  Play,
  Pause,
} from "@phosphor-icons/react";
import { memo, useRef, useState } from "react";
import { Avatar } from "../avatar/avatar";
import { CircleNotch} from "@phosphor-icons/react/dist/ssr";
import { RenderMarkdown } from "../markdown";
import { speakText } from "../../commons/speakText";

export const UserBlock = memo(
  ({
    content,
    audioURL,
    editing,
    onEditClick,
    onEditSubmit,
    onEditCancel,
    showEdit = true,
    files,
  }) => {
    const [editText, setEditText] = useState(content);

    const [audio, setAudio] = useState(null);

    const handleEditChange = (e) => {
      setEditText(e.target.value);
    };

    return (
      <div className="p-3 flex flex-col gap-2">
        {/* 
        this section contains 3 things aligned horizontally
        1. avatar
        2. content
        3. actions - ( edit and audio )
      */}
        <div className={`flex gap-2 items-start`}>
          <div>
            <Avatar
              type="icon"
              data={User}
              backgroundColor={"[#e93648]"}
              size="extra-small"
              textColor="#ffffff"
              isPrimary={true}
            />
          </div>
          <div className="flex-1">
            {editing ? (
              <div className="flex flex-col flex-1 ml-2.5 gap-3">
                <textarea
                  className="w-full h-32 rounded-lg p-2 bg-[#F6F6F6] text-[#1E1E1E] text-base"
                  value={editText}
                  onChange={handleEditChange}
                ></textarea>
                <div className="flex justify-center items-center gap-4">
                  <button
                    className="bg-[#f6f6f6] text-black dark:bg-background-sidebar-alt dark:text-white dark:shadow-black shadow-primary px-3 py-2 rounded-md text-sm flex items-center gap-2 border border-[#f6f6f6]"
                    onClick={() => {
                      onEditSubmit(editText);
                      onEditCancel();
                    }}
                  >
                    Submit
                  </button>
                  <button
                    className="bg-[#f6f6f6] text-black dark:bg-background-sidebar-alt dark:text-white dark:shadow-black shadow-primary px-3 py-2 rounded-md text-sm flex items-center gap-2 border border-[#f6f6f6]"
                    onClick={() => {
                      onEditCancel();
                      setEditText(content);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <div className="flex flex-col gap-3 flex-1 justify-center ml-2.5">
                {audioURL && (
                  <span className="text-[#798897] text-base">
                    Text from audio prompt
                  </span>
                )}
                <span className="text-[#1E1E1E] text-base">{content}</span>
                {files?.length > 0 && (
                  <div className="w-full grid grid-cols-3 bottom-full max-h-96  overflow-y-scroll rounded-md p-2 gap-2">
                    {files.map((file, index) => {
                      return (
                        <div
                          className="flex flex-col gap-2 justify-center items-center"
                          key={index}
                        >
                          <img
                            src={file?.url?.raw ? file?.url?.raw : file?.url}
                            alt={file.name}
                            className="object-cover w-full h-full"
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="flex flex-col gap-2 justify-center">
            <>
              {showEdit && !editing && (
                <Avatar
                  size="extra-small"
                  type="icon"
                  data={PencilSimple}
                  backgroundColor={"bg-[#f9f9f9]"}
                  textColor="#000"
                  onClick={() => onEditClick()}
                  pointer={true}
                />
              )}
            </>

            {audioURL && (
              <>
                {!audio ? (
                  <Avatar
                    size="extra-small"
                    type="icon"
                    data={SpeakerSimpleHigh}
                    backgroundColor={"bg-[#f9f9f9]"}
                    textColor="#000"
                    pointer={true}
                    onClick={() => {
                      // play the audio
                      let tempAudio = new Audio(audioURL);
                      tempAudio.play();
                      tempAudio.onended = () => {
                        setAudio(null);
                      };

                      setAudio(tempAudio);
                    }}
                  />
                ) : (
                  <Avatar
                    size="extra-small"
                    type="icon"
                    data={PlayPause}
                    backgroundColor={"bg-[#f9f9f9]"}
                    textColor="#000"
                    pointer={true}
                    onClick={() => {
                      audio?.pause();
                      setAudio(null);
                    }}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.content === nextProps.content &&
      prevProps.editing === nextProps.editing &&
      prevProps.audioURL === nextProps.audioURL
    );
  }
);

export const AIBlock = memo(
  ({
    content,
    audioURL,
    index,
    isMobileScreen = false,
    triggerRegenerate,
    showRegenerate,
    generateAudio = () => {},
    audioIndex,
    showAudio = true,
  }) => {
    const [isCopied, setIsCopied] = useState(false);
    const [audio, setAudio] = useState(null);
   
    const [isPlaying, setIsPlaying] = useState(false);
    const [audioUrl, setAudioUrl] = useState('');
    const audioRef = useRef(new Audio());
    
    const handleAudioToggle = async () => {
      try {
        if (!isPlaying && !audioUrl) {
          const url = await speakText(content);
          setAudioUrl(url);
          audioRef.current.src = url;
          audioRef.current.addEventListener('ended', () => setIsPlaying(false));
        }
    
        if (isPlaying) {
          audioRef.current.pause();
        } else {
          audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
      } catch (error) {
        console.error(error.message);
        //TODO: display an error message to the user
      }
    };

    const handleCopy = () => {
      navigator.clipboard.writeText(content);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    };

    return (
      <div className="p-3 flex flex-col gap-2 bg-[#F6F6F6] rounded-xl">
        <div className={`flex gap-2 items-start`}>
          <Avatar
            size="extra-small"
            type="image"
            data={tagoreChat}
            backgroundColor={"[#e93648]"}
            isPrimary={true}
          />
          <div className="flex-1 -md:overflow-x-auto">
            <RenderMarkdown content={content} index={index} />
          </div>
          <div className="flex flex-col gap-2 justify-center">
            {//audioURL 
            false 
            ? (
              <>
                {audio ? (
                  <Avatar
                    size="extra-small"
                    type="icon"
                    data={PlayPause}
                    backgroundColor={"bg-[#ffffff]"}
                    textColor="#000"
                    onClick={() => {
                      audio?.pause();
                      setAudio(null);
                    }}
                    pointer={true}
                  />
                ) : (
                  <Avatar
                    size="extra-small"
                    type="icon"
                    data={Play}
                    backgroundColor={"bg-[#ffffff]"}
                    textColor="#000"
                    onClick={() => {
                      // play the audio
                      let tempAudio = new Audio(audioURL);
                      tempAudio.play();
                      tempAudio.onended = () => {
                        setAudio(null);
                      };
                      setAudio(tempAudio);
                    }}
                    pointer={true}
                  />
                )}
              </>
            ) : (
              <>
                {audioIndex !== index && showAudio ? (
                  <Avatar
                    size="extra-small"
                    type="icon"
                    data={isPlaying?Pause:SpeakerSimpleHigh}
                    backgroundColor={"bg-[#ffffff]"}
                    textColor="#000"
                    onClick={handleAudioToggle}
                    pointer={true}
                  />
                ) : (
                  showAudio && (
                    <CircleNotch className="bg-white p-1 rounded-full text-black cursor-pointer w-8 h-8 animate-spin" />
                  )
                )}
              </>
            )}
          </div>
        </div>
        <div className="flex justify-end items-center">
          <div className="flex items-center gap-2">
            {isCopied ? (
              <Avatar
                size="small"
                type="icon"
                data={CheckCircle}
                backgroundColor={"bg-[#f6f6f6]"}
                textColor="#666666"
                pointer={true}
              />
            ) : (
              <Avatar
                size="small"
                type="icon"
                data={Clipboard}
                backgroundColor={"bg-[#f6f6f6]"}
                textColor="#666666"
                pointer={true}
                onClick={() => handleCopy()}
              />
            )}
          </div>
          {showRegenerate && (
            <div
              className="flex items-center cursor-pointer"
              onClick={triggerRegenerate}
            >
              <Avatar
                size="extra-small"
                type="icon"
                data={ArrowCounterClockwise}
                backgroundColor={"bg-[#f6f6f6]"}
                textColor="#666666"
                pointer={true}
              />
              <span className="text-[#666666]">Regenerate</span>
            </div>
          )}
        </div>
      </div>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.content === nextProps.content &&
      prevProps.audioURL === nextProps.audioURL
    );
  }
);

export const ErrorBlock = () => {
  return (
    <div className="p-3 flex flex-col gap-2 bg-[#FEEBED] rounded-xl">
      <div className={`flex gap-2 items-start`}>
        <Avatar
          size="extra-small"
          type="image"
          data={tagoreChat}
          backgroundColor={"[#e93648]"}
          isPrimary={true}
        />
        <div className="flex-1">
          <span>
            Oops! There’s an issue processing your request. Please try sending
            the request again. If the issue still persists contact Tagore
            support at tagore@factly.in
          </span>
        </div>
      </div>
    </div>
  );
};
