import React from "react";
import Select from "react-select";
import ToggleSwitch from "../../../components/toggle";

function Preferences() {
  // todo: handle state for the form
  // todo: add more options
  const [checked, setChecked] = React.useState(false);

  const langauageOptions = [
    {
      label: "English",
      value: "English",
    },
    {
      label: "French",
      value: "French",
    },
    {
      label: "Spanish",
      value: "Spanish",
    },
  ];

  return (
    <div className="flex flex-col gap-y-4 w-full">
      <div className="flex flex-row justify-end items-end w-full pr-10 py-7 border-b border-[#D1D1D1]">
        <button className="bg-[#1E1E1E] text-white rounded-lg py-3 px-2 font-normal w-[144px] h-12">
          Save
        </button>
      </div>
      <div className="flex flex-col gap-y-7 mx-auto mt-6 w-[60vw]">
        <div className="flex flex-row justify-between py-6">
          <div className="flex flex-col gap-y-2">
            <h2 className="text-xl font-semibold">Language</h2>
            <span className="text-[#666] font-normal">
              Select your preferred language
            </span>
          </div>
          <Select
            label="Language"
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: "black",
                primary25: "#EAEAEA",
                primary50: "#EAEAEA",
                primary75: "#EAEAEA",
              },
            })}
            onChange={(value) => {
              // TODO: handle change
            }}
            value={""}
            defaultValue={""}
            className="rounded-lg cursor-pointer shadow-sm w-[360px] h-fit"
            // error={formValues.contentSource.error}
            placeholder={"Select Language"}
            options={langauageOptions}
          />
        </div>
        <div className="flex flex-row justify-between py-6">
          <div className="flex flex-col gap-y-2">
            <h2 className="text-xl font-semibold">
              Remember Previous Location
            </h2>
            <span className="text-[#666] font-normal">
              Automatically reopen the last document
            </span>
          </div>
          <div className="flex justigy-start items-center w-[362px]">
            <ToggleSwitch
              defaultChecked={checked}
              onChange={(checked) => setChecked(checked)}
            />
          </div>
        </div>
        <div className="flex flex-row justify-between py-6">
          <div className="flex flex-col gap-y-2">
            <h2 className="text-xl font-semibold">
              Remember Previous Location
            </h2>
            <span className="text-[#666] font-normal">
              Automatically reopen the last document
            </span>
          </div>
          <div className="flex justigy-start items-center w-[362px]">
            <ToggleSwitch
              defaultChecked={checked}
              onChange={(checked) => setChecked(checked)}
            />
          </div>
        </div>
        <div className="flex flex-row justify-between py-6">
          <div className="flex flex-col gap-y-2">
            <h2 className="text-xl font-semibold">
              Remember Previous Location
            </h2>
            <span className="text-[#666] font-normal">
              Automatically reopen the last document
            </span>
          </div>
          <div className="flex justigy-start items-center w-[362px]">
            <ToggleSwitch
              defaultChecked={checked}
              onChange={(checked) => setChecked(checked)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Preferences;
