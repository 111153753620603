import {
  readFromLocalStorage,
  writeToLocalStorage,
} from "../../util/localstorage";

const initialState = {
  details: [],
  selectedOrg: -1,
  loading: false,
};

const orgReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_ORGS":
      // Add new org to state
      const fromLocal = parseInt(readFromLocalStorage("selectedOrg"));
      if (fromLocal) {
        return {
          ...state,
          details: action.payload,
          selectedOrg: fromLocal,
        };
      }

      return {
        ...state,
        details: action.payload,
        selectedOrg:
          // a placeholder check to avoid the error of undefined selectedOrg
          state.selectedOrg === -1 || state?.selectedOrg === undefined
            ? action.payload[0]?.id
            : state.selectedOrg,
      };

    case "SELECT_ORG":
      // Select org
      writeToLocalStorage("selectedOrg", action.payload);
      return {
        ...state,
        selectedOrg: action.payload,
      };

    case "SET_ORG_LOADING":
      // Set loading state
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};

export default orgReducer;
