import { useEffect, useRef } from "react";

// AlwaysScrollToBottom is a component that is used to scroll to the bottom of the chat window
// it is used to scroll to the bottom of the chat window when the user sends a message
const AlwaysScrollToBottom = ({ chat }) => {
  const elementRef = useRef();
  useEffect(() => {
    elementRef.current.scrollIntoView({ behavior: "instant" });
  }, [chat]);
  return <div ref={elementRef} />;
};

export default AlwaysScrollToBottom;
