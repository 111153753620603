import React, { useState } from "react";

const ToggleSwitch = ({ label, defaultChecked, onChange }) => {
  const [checked, setChecked] = useState(defaultChecked);

  const handleToggle = () => {
    setChecked(!checked);
    if (onChange) {
      onChange(!checked);
    }
  };

  return (
    <div className="flex items-center space-x-2">
      <label className="text-gray-700">{label}</label>
      <div
        className={`relative flex items-center h-7 w-12 bg-gray-300 rounded-full p-1 cursor-pointer transition-transform duration-300 ease-in-out ${
          checked ? "!bg-[#1e1e1e]" : ""
        }`}
        onClick={handleToggle}
      >
        <div
          className={`absolute left-1 w-5 h-5 bg-white rounded-full transition-all duration-300 ease-in-out	transform ${
            checked ? "translate-x-5" : "translate-x-0"
          }`}
        ></div>
      </div>
    </div>
  );
};

export default ToggleSwitch;
