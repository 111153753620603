import { PERSONA_API } from "../commons/persona";

export async function getPersona({ page, limit, search_query, filter }) {
  return fetch(
    PERSONA_API +
      "/public" +
      "?" +
      new URLSearchParams({
        page: page,
        limit: limit,
        search_query: search_query,
        filter: filter,
      }),
    {
      credentials: "include",
    }
  )
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        return response.json().then((data) => {
          throw Error(data.errors?.[0].message);
        });
      }
    })
    .then((data) => {
      return data;
    });
}

export async function getPersonaChatsByUserID(personaID, pagination) {
  return fetch(
    `${PERSONA_API}/${personaID}/chats?` +
      new URLSearchParams({
        limit: pagination.limit,
        page: pagination.page,
        search_query: pagination.search_query,
      }),
    {
      credentials: "include",
    }
  )
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        return response.json().then((data) => {
          throw Error(data.errors?.[0].message);
        });
      }
    })
    .then((data) => {
      return data;
    });
}

// deletePersonaChatByID function is used to delete a chat by its ID
// it sends http request to the server and validates and returns the response
// parameters: personaID, chatID
export async function deletePersonaChatByID(personaID, chatID) {
  return fetch(`${PERSONA_API}/${personaID}/chats/${chatID}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  }).then((response) => {
    if (response.status === 200) {
      return response.json();
    } else {
      return response.json().then((data) => {
        throw Error(data.errors?.[0].message);
      });
    }
  });
}

// createPersona function is used to create a persona
// it sends http request to the server and validates and returns the response
// parameters: name, description, avatar, prompt, visibility
export async function createPersona(requestBody, orgID) {
  return fetch(PERSONA_API, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Organisation": orgID,
    },
    body: JSON.stringify(requestBody),
    credentials: "include",
  }).then((response) => {
    if (response.status === 201) {
      return response.json();
    } else {
      return response.json().then((data) => {
        throw Error(data.errors?.[0].message);
      });
    }
  });
}

// updatePersona function is used to update a persona
// it sends http request to the server and validates and returns the response
// parameters: personaID, name, description, avatar, prompt, visibility
export async function updatePersona(personaID, requestBody) {
  return fetch(`${PERSONA_API}/${personaID}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody),
    credentials: "include",
  }).then((response) => {
    if (response.status === 200) {
      return response.json();
    } else {
      return response.json().then((data) => {
        throw Error(data.errors?.[0].message);
      });
    }
  });
}

// getPersonaByID fetches the persona from the server using its ID
// parameters: personaID
export async function getPersonaByID(personaID) {
  return fetch(`${PERSONA_API}/${personaID}`, {
    credentials: "include",
  }).then((response) => {
    if (response.status === 200) {
      return response.json();
    } else {
      return response.json().then((data) => {
        throw Error(data.errors?.[0].message);
      });
    }
  });
}

// getDefaultPersona fetches the default persona from the server
// endpoint: /personas/default
export async function getDefaultPersona() {
  return fetch(`${PERSONA_API}/default`, {
    credentials: "include",
  }).then((response) => {
    if (response.status === 200) {
      return response.json();
    } else {
      return response.json().then((data) => {
        throw Error(data.errors?.[0].message);
      });
    }
  });
}

export async function deletePersonaById(id) {
  return fetch(`${PERSONA_API}/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  }).then((response) => {
    if (response.status == 200) {
      return response.json();
    } else {
      return response.json().then((data) => {
        throw Error(data.errors?.[0].message);
      });
    }
  });
}

export async function validateAccess({ orgID, userID, personaName }) {
  return fetch(
    `${PERSONA_API}/access/validate?` +
      new URLSearchParams({
        persona: personaName,
      }),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-Organisation": orgID,
        "X-User": userID,
      },
      credentials: "include",
    }
  ).then((response) => {
    if (response.status == 200) {
      return response.json();
    } else {
      return response.json().then((data) => {
        throw Error(data.errors?.[0].message);
      });
    }
  });
}

export async function getUserIdsforPersona({ personaName, orgId }) {
  return fetch(
    `${PERSONA_API}/access/users?` +
      new URLSearchParams({
        persona: personaName,
      }),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-Organisation": orgId,
      },
      credentials: "include",
    }
  ).then((response) => {
    if (response.status == 200) {
      return response.json();
    } else {
      return response.json().then((data) => {
        throw Error(data.errors?.[0].message);
      });
    }
  });
}

export async function grantAccess({ personaName, orgId, userIds }) {
  return fetch(`${PERSONA_API}/access/grant`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Organisation": orgId,
    },
    credentials: "include",
    body: JSON.stringify({
      persona: personaName,
      users: userIds,
    }),
  }).then((response) => {
    if (response.status == 200) {
      return response.json();
    } else {
      return response.json().then((data) => {
        throw Error(data.errors?.[0].message);
      });
    }
  });
}

export async function revokeAccess({ personaName, orgId, userIds }) {
  return fetch(`${PERSONA_API}/access/revoke`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "X-Organisation": orgId,
    },
    credentials: "include",
    body: JSON.stringify({
      persona: personaName,
      users: userIds,
    }),
  }).then((response) => {
    if (response.status == 200) {
      return response.json();
    } else {
      return response.json().then((data) => {
        throw Error(data.errors?.[0].message);
      });
    }
  });
}

// limitReached action is used to check if the limit of the personas is reached
// it is the number of max conversations user can have with the bot

export async function limitReached(persona) {
  return fetch(`${PERSONA_API}/access/limit?persona=${persona}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  }).then((response) => {
    if (response.status == 200) {
      return response.json();
    } else {
      return response.json().then((data) => {
        throw Error(data.errors?.[0].message);
      });
    }
  });
}

export async function getFactlyPersonaHistory({ persona, page, limit }) {
  return fetch(
    `${PERSONA_API}/history/factly?` +
      new URLSearchParams({
        persona: persona,
        page: page,
        limit: limit,
      }),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    }
  ).then((response) => {
    if (response.status == 200) {
      return response.json();
    } else {
      return response.json().then((data) => {
        throw Error(data.errors?.[0].message);
      });
    }
  });
}
export async function getAssistantHistory({
  persona,
  page,
  limit,
  userID,
  selectedOrg,
}) {
  return fetch(
    `${window.REACT_APP_TAGORE_API_URL}/history/assistants?` +
      new URLSearchParams({
        assistant_id: persona,
        page: page,
        limit: limit,
      }),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-Org": selectedOrg,
        "X-User": userID,
      },
      credentials: "include",
    }
  ).then((response) => {
    if (response.status == 200) {
      return response.json();
    } else {
      return response.json().then((data) => {
        throw Error(data.errors?.[0].message);
      });
    }
  });
}

export async function deleteFactlyPersonaHistory({ persona, chat_id }) {
  return fetch(
    `${PERSONA_API}/factly/delete?` +
      new URLSearchParams({
        persona: persona,
        chat_id: chat_id,
      }),
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    }
  ).then((response) => {
    if (response.status == 200) {
      return response.json();
    } else {
      return response.json().then((data) => {
        throw Error(data.errors?.[0].message);
      });
    }
  });
}

export function createExternalLinkForSharing({ chat_id, persona_name }) {
  return fetch(`${PERSONA_API}/factly-share`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify({
      chat_id: chat_id,
      persona_name: persona_name,
    }),
  }).then((response) => {
    if (response.status == 200) {
      return response.json();
    } else {
      throw Error(response.statusText);
    }
  });
}
