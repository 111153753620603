export function writeToLocalStorage(key, value) {
  if (typeof window !== "undefined") {
    localStorage.setItem(key, value);
  }
}

export function readFromLocalStorage(key) {
  if (typeof window !== "undefined") {
    return localStorage.getItem(key);
  }
}
