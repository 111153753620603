import { withOrg } from "../../components/organisation/withOrg";
import { useEffect, useState } from "react";
import { theme } from "../../commons/styles";
import { SSE } from "sse.js";
import { AIBlock, UserBlock } from "../../components/chat/chatBlock";
import AlwaysScrollToBottom from "../../components/scroll/scrollToBottom";
import { BeatLoader } from "react-spinners";
import { ChatSettingsForm } from "../../components/chat/settingsForm";
import {
  deleteChatByID,
  getChatHistoryByUserID,
  getChatResponse,
  getShareableExternalRef,
} from "../../actions/chat";
import { errorToast, successToast } from "../../util/toasts";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ChatSidebar } from "../../components/chat/sidebar";
import { ChatInput } from "../../components/chat/chatInput";
import { ChatNavbar } from "../../components/chat/chatNavbar";
import { textToSpeech } from "../../actions/audio";
import "react-toastify/dist/ReactToastify.css";
import { usePostHog } from "posthog-js/react";
import { dispatchPosthog } from "../../util/posthog";
import useWindowSize from "../../hooks/useWindowSize";

function Main({ selectedOrg }) {
  const posthog = usePostHog();
  const { isDesktopScreen } = useWindowSize(undefined, 769);

  const getSystemPrompt = (value) => {
    switch (value) {
      case "anthropic":
        return "Additional Instructions to follow - Return the content in valid markdown format.";
      default:
        return "You are an AI assistant named Tagore. You help user in writing creative ideas which can be any kind of literature, code or anything else. You also answer there questions in logical way. Make sure to follow the following rules: 1. Understand the context of the conversation and respond it in an attempt to help the user. 2. Make sure to follow the additional instructions given in request. 3. Don't mention the things mentioned in additional instructions in the response. Additional Instructions to follow - Return the content in valid markdown format.";
    }
  };

  // model to provider map
  const modelToProvider = {
    "gpt-4o-mini": "openai",
    "gpt-4": "openai",
    "claude-2.1": "anthropic",
    "claude-instant": "anthropic",
    "chat-bison": "google",
    "meta-llama/Llama-2-70b-chat-hf": "anyscale",
    "gemini-pro": "google-gemini",
  };

  const modelValueToLabel = {
    "gpt-4o-mini": "GPT-4o Mini",
    "gpt-4": "GPT 4",
    "claude-2.1": "Claude 2.1",
    "claude-instant": "Claude Instant",
    "chat-bison": "Google's Chat Bison",
    "meta-llama/Llama-2-70b-chat-hf": "LLAMA 2",
    "gemini-pro": "Google's Gemini Pro",
  };

  const navigate = useNavigate();
  // additional instructions for the model
  const additionalInstructions = "Return the content in valid markdown format.";

  const [sidebarVisible, setSidebarVisible] = useState(
    isDesktopScreen ? true : false
  );
  useEffect(() => {
    if (isDesktopScreen) {
      setSidebarVisible(true);
    } else {
      setSidebarVisible(false);
    }
  }, [isDesktopScreen]);
  // sseClient is client for fetching chat messages using server side events
  const [sseClient, setSSEClient] = useState(null);

  const [chat, setChat] = useState({
    id: "",
    messages: [],
  });

  // responseLoading is a boolean that is true when the response for chat is being fetched
  // this is used to show a loading indicator
  const [responseLoading, setResponseLoading] = useState(false);

  const [settings, setSettings] = useState({
    model: {
      value: "claude-2.1",
      label: "Claude 2.1",
    },
    temperature: 0.9,
    system_prompt: "",
  });

  const [showSettings, setShowSettings] = useState(false);

  const [currentPrompt, setCurrentPrompt] = useState("");

  const [editIndex, setEditIndex] = useState(null);

  const [scrollUp, setScrollUp] = useState(false);

  const [chatHistory, setChatHistory] = useState({
    count: 0,
    results: [],
  });

  const [pagination, setPagination] = useState({
    page: 1,
    limit: 20,
    search_query: "",
  });

  const [sharedLink, setSharedLink] = useState("");

  const [showShareChat, setShowShareChat] = useState(false);

  // audioIndex state captures the index of the message for which the audio is being generated
  // this is used to show a loading indicator
  const [audioIndex, setAudioIndex] = useState(-1);

  const toggleShareChat = () => {
    setShowShareChat(!showShareChat);
  };

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const fetchChatHistory = async () => {
    getChatHistoryByUserID(pagination)
      .then((data) => {
        setChatHistory({
          results: data.chats,
          count: data.total,
        });
      })
      .catch((err) => {
        errorToast(err);
      });
  };

  useEffect(() => {
    fetchChatHistory();
  }, [pagination.limit, pagination.page]);

  useEffect(() => {
    const timer = setTimeout(() => {
      // get the chat history of the user
      getChatHistoryByUserID(pagination)
        .then((data) => {
          setChatHistory({
            results: data.chats,
            count: data.total,
          });
        })
        .catch((err) => {
          errorToast(err);
        });
    }, 500);

    return () => clearTimeout(timer);
  }, [pagination.search_query]);

  useEffect(() => {
    // Attach the scroll event listener when the component mounts
    const scrollContainer = document.getElementById("chat-display");
    scrollContainer.addEventListener("wheel", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      scrollContainer.removeEventListener("wheel", handleScroll);
    };
  }, []);

  // getAudioBlob is a callback function passed to AudioRecorder
  // it gets called when the onStop event is fired
  const getAudioBlob = async (audioURL) => {
    let audioBlob = new Blob(
      [new Uint8Array(await (await fetch(audioURL)).arrayBuffer())],
      { type: "audio/webm " }
    );
    // making a file out of this blob
    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();
    const sampleRate = audioContext.sampleRate;

    const formData = new FormData();
    formData.append("sampleRate", sampleRate); // Append the sample rate
    formData.append("audio", audioBlob);

    formData.append("model", settings.model.value);
    formData.append("provider", modelToProvider[settings.model.value]);
    formData.append("temperature", settings.temperature);
    formData.append("additional_instructions", additionalInstructions);

    if (modelToProvider[settings.model.value] === "google") {
      formData.append("stream", false);
    } else {
      formData.append("stream", true);
    }

    if (chat.id) {
      formData.append("chat_id", chat.id);
    }

    if (settings.system_prompt) {
      formData.append("system_prompt", settings.system_prompt);
    }

    handleAudioSubmit(formData);

    setChat({
      id: chat.id,
      messages: [
        ...chat.messages,
        {
          content: "generating response for the audio...",
          audioURL: audioURL,
          type: "user",
        },
      ],
    });
  };

  const handleAudioSubmit = (formData) => {
    var source = new SSE(
      window.REACT_APP_TAGORE_API_URL + "/chat/completions/audio",
      {
        payload: formData,
        method: "POST",
        withCredentials: true,
        headers: {
          "X-Organisation": selectedOrg,
        },
      }
    );
    setResponseLoading(true);
    setSSEClient(source);

    source.addEventListener("message", (event) => {
      let chatObject = JSON.parse(event.data);
      // set messages and id in chat state
      setChat({
        id: chatObject.id,
        messages: chatObject?.messages,
        model: chatObject?.model,
        system_prompt: chatObject?.messages[0]?.content,
      });
    });

    source.addEventListener("error", (event) => {
      // setIsEditing({ status: false, id: null });
      source.close();
      setResponseLoading(false);
      setSSEClient(null);
      if (!String(event.data).includes("[DONE]")) {
        return;
      }
    });

    source.stream();
    setScrollUp(false);
  };

  const handleChatSubmit = () => {
    if (modelToProvider[settings.model.value] !== "google") {
      handleStreamRequest();
    } else {
      handleRequest();
    }
  };

  const handleStreamRequest = () => {
    setResponseLoading(true);

    let currentMessage = {
      role: "user",
      content: currentPrompt,
    };

    const newMessages = [...chat.messages, currentMessage];

    var requestBody = {
      messages: newMessages,
      model: settings.model.value,
      provider: modelToProvider[settings.model.value],
      temperature: settings.temperature,
      additional_instructions: additionalInstructions,
      stream: true,
    };

    if (chat.id) {
      requestBody.id = chat.id;
    }

    if (settings.system_prompt) {
      requestBody.system_prompt = settings.system_prompt;
    }

    setChat({
      ...chat,
      messages: newMessages,
    });

    setCurrentPrompt("");

    var source = new SSE(
      window.REACT_APP_TAGORE_API_URL + "/chat/completions",
      {
        payload: JSON.stringify(requestBody),
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Organisation": selectedOrg,
        },
        withCredentials: true,
      }
    );

    setSSEClient(source);

    source.addEventListener("message", (event) => {
      let chatObject = JSON.parse(event.data);
      setChat({
        id: chatObject.id,
        messages: chatObject?.messages,
        model: chatObject?.model,
        system_prompt: chatObject?.messages[0]?.content,
      });
    });

    source.addEventListener("error", (event) => {
      source.close();
      setSSEClient(null);
      setResponseLoading(false);
      if (!String(event.data).includes("[DONE]")) {
        return;
      }
    });

    source.stream();
    setScrollUp(false);

    dispatchPosthog(posthog, "Chat Message Sent", {
      model: settings.model.value,
      provider: modelToProvider[settings.model.value],
      temperature: settings.temperature,
      prompt: currentPrompt,
      id: chat?.id,
    });
  };

  const handleRequest = () => {
    setResponseLoading(true);

    let currentMessage = {
      role: "user",
      content: currentPrompt,
    };

    const newMessages = [...chat.messages, currentMessage];

    var requestBody = {
      messages: newMessages,
      model: settings.model.value,
      provider: modelToProvider[settings.model.value],
      temperature: settings.temperature,
      additional_instructions: additionalInstructions,
      stream: false,
    };

    if (chat.id) {
      requestBody.id = chat.id;
    }

    if (settings.system_prompt) {
      requestBody.system_prompt = settings.system_prompt;
    }

    setChat({
      id: chat.id,
      messages: newMessages,
    });

    setCurrentPrompt("");

    getChatResponse(requestBody, selectedOrg)
      .then((data) => {
        if (!chat.id) {
          setChat({
            id: data?.id,
            messages: data?.messages,
          });
        }
      })
      .catch((err) => {
        errorToast(err);
      })
      .finally(() => {
        setResponseLoading(false);
        setScrollUp(false);
      });
  };

  const handleKeyDown = (e) => {
    // add an event to handle shift + enter
    if (e.keyCode === 13 && e.shiftKey) {
      return;
    }
    
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      const messageText = e.target.value.trim();
      if (messageText !== "") {
        e.target.value = "";
        e.target.style.height = "24px";
      }
    }

    if (e.keyCode === 13 && !responseLoading && currentPrompt !== "") {
      handleChatSubmit();
    }
  };

  // handleRegenerate is a callback function passed to AIBlock
  // it gets called when the user clicks on the regenerate button
  const handleRegenerate = () => {
    let newMessages = chat.messages.slice(0, chat.messages.length - 1);
    setResponseLoading(true);
    setChat({
      id: chat.id,
      messages: newMessages,
    });

    var requestBody = {
      messages: newMessages,
      model: settings.model.value,
      provider: modelToProvider[settings.model.value],
      temperature: settings.temperature,
      additional_instructions: additionalInstructions,
    };

    if (chat.id) {
      requestBody.id = chat.id;
    }

    if (settings.system_prompt) {
      requestBody.system_prompt = settings.system_prompt;
    }

    if (requestBody.provider === "google") {
      requestBody.stream = false;
      getChatResponse(requestBody, selectedOrg)
        .then((data) => {
          if (!chat.id) {
            setChat({
              id: data?.id,
              messages: data?.messages,
            });
          }
        })
        .catch((err) => {
          errorToast(err);
        })
        .finally(() => {
          setResponseLoading(false);
        });
    } else {
      requestBody.stream = true;

      var source = new SSE(
        window.REACT_APP_TAGORE_API_URL + "/chat/completions",
        {
          payload: JSON.stringify(requestBody),
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Organisation": selectedOrg,
          },
          withCredentials: true,
        }
      );

      setSSEClient(source);

      source.addEventListener("message", (event) => {
        let chatObject = JSON.parse(event.data);
        setChat({
          id: chatObject.id,
          messages: chatObject?.messages,
          model: chatObject?.model,
          system_prompt: chatObject?.messages[0]?.content,
        });
      });

      source.addEventListener("error", (event) => {
        source.close();
        setSSEClient(null);
        setResponseLoading(false);
        if (!String(event.data).includes("[DONE]")) {
          return;
        }
      });

      source.stream();
    }
    setScrollUp(false);
  };

  const handleScroll = (e) => {
    if (!responseLoading) {
      if (e?.deltaY < 0 || isNaN(e.deltaY)) {
        setScrollUp(true);
      } else {
        setScrollUp(false);
      }
    }
  };

  const handleStop = () => {
    sseClient?.close();
    setSSEClient(null);
    setResponseLoading(false);
    setScrollUp(false);
  };

  const handleEdit = (text, index) => {
    setResponseLoading(true);
    let newMessages = chat.messages.slice(0, index + 2);

    newMessages[newMessages.length - 1] = {
      role: "user",
      content: text,
    };

    dispatchPosthog(posthog, "Chat Message Sent", {
      model: settings.model.value,
      provider: modelToProvider[settings.model.value],
      temperature: settings.temperature,
      prompt: text,
      id: chat.id,
    });

    setChat({
      id: chat.id,
      messages: newMessages,
    });

    var requestBody = {
      messages: newMessages,
      model: settings.model.value,
      provider: modelToProvider[settings.model.value],
      temperature: settings.temperature,
      additional_instructions: additionalInstructions,
    };

    if (chat.id) {
      requestBody.id = chat.id;
    }

    if (settings.system_prompt) {
      requestBody.system_prompt = settings.system_prompt;
    }

    if (requestBody.provider === "google") {
      requestBody.stream = false;
      getChatResponse(requestBody, selectedOrg)
        .then((data) => {
          if (!chat.id) {
            setChat({
              id: data?.id,
              messages: data?.messages,
            });
          }
        })
        .catch((err) => {
          errorToast(err);
        })
        .finally(() => {
          setResponseLoading(false);
        });
    } else {
      requestBody.stream = true;

      var source = new SSE(
        window.REACT_APP_TAGORE_API_URL + "/chat/completions",
        {
          payload: JSON.stringify(requestBody),
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Organisation": selectedOrg,
          },
          withCredentials: true,
        }
      );

      setSSEClient(source);

      source.addEventListener("message", (event) => {
        let chatObject = JSON.parse(event.data);
        setChat({
          id: chatObject.id,
          messages: chatObject?.messages,
          model: chatObject?.model,
          system_prompt: chatObject?.messages[0]?.content,
        });
      });

      source.addEventListener("error", (event) => {
        source.close();
        setSSEClient(null);
        setResponseLoading(false);
        if (!String(event.data).includes("[DONE]")) {
          return;
        }
      });

      source.stream();
    }
    setScrollUp(false);
  };

  const handleSelectChat = (chat) => {
    setChat({
      id: chat.id,
      messages: chat.messages,
    });

    setSettings({
      model: {
        value: chat.model,
        label: modelValueToLabel[chat.model],
      },
      temperature: chat.temperature,
      system_prompt: chat.messages[0].content,
    });
    if (!isDesktopScreen) {
      setSidebarVisible(false);
    }
  };

  const handleShareLink = async () => {
    if (chat.messages.length === 0) {
      errorToast("Please start a conversation first");
      return;
    }

    getShareableExternalRef(chat.id, selectedOrg)
      .then((ref) => {
        let link;
        if (process.env.NODE_ENV === "development") {
          link = `${window.location.origin}${window.REACT_APP_PUBLIC_URL}/chat/shared/${ref}`;
        } else {
          link = `${window.location.origin}/chat/shared/${ref}`;
        }

        dispatchPosthog(posthog, "Chat Shared", {
          chat_id: chat.id,
          link: link,
        });

        setSharedLink(link);
      })
      .then(() => {
        toggleShareChat(true);
      });
  };

  const getActualSystemPrompt = (systemPrompt) => {
    let tagorePrompt = getSystemPrompt(modelToProvider[settings.model.value]);
    if (systemPrompt.trim() === tagorePrompt.trim()) {
      return "";
    }
    return systemPrompt;
  };

  const handleNewChat = () => {
    setChat({
      id: "",
      messages: [],
    });
    setSettings({
      model: {
        value: "claude-2.1",
        label: "Claude 2.1",
      },
      temperature: 0.9,
      system_prompt: "",
    });

    setCurrentPrompt("");

    setScrollUp(false);

    setSSEClient(null);

    setResponseLoading(false);

    setEditIndex(null);
    if (!isDesktopScreen) {
      setSidebarVisible(false);
    }
  };

  const handleDeleteChat = (chatID) => {
    deleteChatByID(chatID)
      .then(() => {
        fetchChatHistory();
        successToast("Chat deleted successfully");
        setChat({
          id: null,
          messages: [],
        });
      })
      .catch((err) => {
        errorToast(err);
        errorToast("Failed to delete chat");
      });
  };

  return (
    <div className="flex w-screen h-dvh md:h-dvh -md:flex-col fixed">
      <ChatSidebar
        sidebarVisible={sidebarVisible}
        toggleSidebar={toggleSidebar}
        chatHistory={chatHistory}
        setPagination={setPagination}
        chat={chat}
        pagination={pagination}
        handleSelectChat={handleSelectChat}
        handleNewChat={handleNewChat}
        navigate={() => navigate("/")}
        handleDeleteChat={handleDeleteChat}
      />
      <div
        className={`
          ${sidebarVisible ? "flex-[7]" : "w-full"}
          h-full flex md:justify-between flex-col
        `}
      >
        {/* chat navbar  */}
        <ChatNavbar
          sidebarVisible={sidebarVisible}
          handleNewChat={handleNewChat}
          handleShareLink={handleShareLink}
          chat={chat}
          toggleSidebar={toggleSidebar}
          isDesktopScreen={isDesktopScreen}
          showSettings={showSettings}
          setShowSettings={setShowSettings}
          modelName={modelValueToLabel[settings.model.value]}
        />
        <div
          className={`w-full -md:h-full overflow-hidden flex ${
            !sidebarVisible && "justify-center"
          } md:flex-[11]`}
        >
          <div
            className={`flex flex-col md:px-10 justify-center ${
              !sidebarVisible ? "md:w-4/5 -md:w-full" : "w-full"
            }`}
          >
            <div
              className="flex-[10]  p-3 md:py-8 md:px-9 flex flex-col gap-8 overflow-y-scroll -md:max-h-[79vh] md:max-h-[80vh]"
              onScroll={handleScroll}
              id="chat-display"
              style={
                responseLoading && !isDesktopScreen ? { maxHeight: "75vh" } : {}
              }
            >
              {chat.messages.length === 0 && (
                <div className="flex justify-center w-full">
                  <ChatSettingsForm
                    formValues={settings}
                    setFormValues={setSettings}
                    isDesktopScreen={isDesktopScreen}
                  />
                </div>
              )}
              {chat.messages
                .filter((message) => message.role !== "system")
                .map((message, index) => {
                  if (message.role === "user") {
                    return (
                      <UserBlock
                        key={index}
                        content={message?.content}
                        // audioURL={message?.audio}
                        editing={editIndex === index}
                        onEditClick={() => {
                          setEditIndex(index);
                        }}
                        onEditCancel={() => setEditIndex(null)}
                        onEditSubmit={(text) => {
                          handleEdit(text, index);
                        }}
                      />
                    );
                  } else {
                    return (
                      <AIBlock
                        key={index}
                        index={index}
                        content={message.content}
                        // audioURL={message.audio}
                        showRegenerate={
                          index === chat.messages.length - 2 && !responseLoading
                        }
                        audioIndex={audioIndex}
                        triggerRegenerate={handleRegenerate}
                        generateAudio={(index) => {
                          setAudioIndex(index);
                          textToSpeech({
                            index: index + 1,
                            chat_id: chat.id,
                            org_id: selectedOrg,
                          })
                            .then((data) => {
                              // update the corresponding message with the audio url
                              let newMessages = chat.messages;
                              newMessages[index + 1].audio = data.audio_url;
                              setChat({
                                id: chat.id,
                                messages: newMessages,
                              });
                            })
                            .catch((err) => {
                              errorToast(
                                "Failed to generate audio. Please try again later."
                              );
                            })
                            .finally(() => {
                              setAudioIndex(-1);
                            });
                        }}
                        showAudio={false}
                      />
                    );
                  }
                })}
              {responseLoading && !scrollUp && (
                <div className="flex justify-center mt-4">
                  <AlwaysScrollToBottom chat={chat.messages} />
                  <BeatLoader size={theme.iconSize.large} color={"#CED0D4"} />
                </div>
              )}
            </div>
           <div className="chat-input-container">
            <ChatInput
              loading={responseLoading}
              handleStop={handleStop}
              currentPrompt={currentPrompt}
              setCurrentPrompt={setCurrentPrompt}
              handleKeyDown={handleKeyDown}
              getAudioBlob={getAudioBlob}
              handleChatSubmit={handleChatSubmit}
              showAudio={false}
            />
            </div>
          </div>
        </div>
      </div>
      {showShareChat && (
        <div className="absolute w-screen h-dvh md:bg-white md:opacity-90">
          <div
            className={`absolute top-16 -md:left-1/2 -md:transform -md:-translate-x-1/2 -md:top-[6rem] -md:max-w-[95vw] -md:w-full -md:shadow  -md:bg-white -md:rounded-xl right-16 p-6 flex flex-col bg-[#f9f9f9] md:border md:border-[#D1D1D1] rounded-md gap-4 z-[50] w-[500px]`}
          >
            <span>Share Link to this Chat</span>
            <div className="max-h-[320px] overflow-y-auto bg-white z-[999] w-full">
              {chat.messages
                .filter((item) => item.role !== "system")
                .map((item, index) => {
                  return (
                    <>
                      {item.role === "user" ? (
                        <UserBlock
                          key={index}
                          content={item.content}
                          showEdit={false}
                        />
                      ) : (
                        <AIBlock
                          index={index}
                          content={item.content}
                          showAudio={false}
                        />
                      )}
                    </>
                  );
                })}
            </div>
            <span className="text-[#666]">
              Anyone with the URL will be able to view the shared chat but wont
              be able to make any changes.
            </span>
            <div className="flex items-center justify-center gap-4">
              <button
                className="px-2 py-3 border text-[#798897] border-[#798897] rounded-md"
                onClick={() => toggleShareChat()}
              >
                Cancel
              </button>
              <button
                className="px-2 py-3 bg-[#798897] text-white rounded-md"
                onClick={() => {
                  navigator.clipboard.writeText(sharedLink);
                  successToast("Link copied to clipboard");
                }}
              >
                Copy Link
              </button>
            </div>
          </div>
        </div>
      )}

      {showSettings && (
        <div
          className="absolute w-screen h-dvh flex justify-center"
          onClick={() => {
            setShowSettings(false);
          }}
        >
          <div
            className="absolute top-20 border-[#f0f0f0] border rounded-lg bg-[#f9f9f9] w-96 p-6 flex flex-col gap-8"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="flex flex-col gap-2">
              <span>Language Model</span>
              <span className="px-2 py-3 border border-[#f0f0f0] bg-white rounded-md">
                {settings.model.label}
              </span>
            </div>
            <div className="flex flex-col gap-2">
              <span>Provider</span>
              <span className="px-2 py-3 border border-[#f0f0f0] bg-white rounded-md">
                {modelToProvider[settings.model.value]}
              </span>
            </div>
            <div className="flex flex-col gap-2">
              <span>System Prompt</span>
              <span className="px-2 py-3 border border-[#f0f0f0] bg-white rounded-md">
                {getActualSystemPrompt(settings.system_prompt)
                  ? getActualSystemPrompt(settings.system_prompt)
                  : "-----"}
              </span>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
}

export default withOrg(Main);
