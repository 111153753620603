import React from 'react';
import { MasterOptionsData } from "./masterPersonaOptions";
// import { ReactComponent as CircleDown} from "../../../assets/icons/caret-circle-down-master.svg"

export const getLabelByValue = (value) => {
  const option = MasterOptionsData.find((option) => option.value === value);
  return option ? option.label : null;
};

export const ActivePersona = ({persona}) => {
if(!persona) return null
return (
    <div className="min-w-[205px] whitespace-nowrap h-[41px] px-3 bg-white rounded-md border border-zinc-100 justify-start items-center gap-1 inline-flex">
      <div className="text-stone-900 text-sm font-normal font-['Inter']">
        {`Chatting with : ${persona}`}
      </div>
    </div>
  );
};

const Prompts = ({ HandlePromptClick , masterPersona }) => {
  const handleClick = (prompt, value) => {
    // need to add prompt as well 
    HandlePromptClick(value);
  };
  return (
    <div className="flex max-w-[550px] items-center overflow-x-auto py-2 space-x-4">
      {MasterOptionsData.filter((option)=>option.value!==masterPersona).map((option) => (
        <React.Fragment key={option.value}>
          {option.prompts.map((prompt, index) => (
            <div
              key={index}
              className="whitespace-nowrap flex-1 h-[42px] px-3.5 py-3 rounded-3xl border border-stone-900 justify-center items-center gap-1.5 inline-flex cursor-pointer"
              onClick={() => handleClick(prompt, option.value)}
            >
              {option.switchIcon}
              <div className="text-stone-900 text-sm font-normal font-['Inter']">
                {prompt}
              </div>
            </div>
          ))}
        </React.Fragment>
      ))}
    </div>
  );
};

const MasterPersonaPrompts = ({ setMasterPersona, masterPersona }) => {
  const HandlePromptClick = (value) => {
    setMasterPersona(value)
  }
  return (
    <div className='flex md:gap-3 gap-2'>
    <div className='w-8 h-8'></div>
    <div className="flex justify-end flex-1 max-w-[90%] items-center">
      {/* <ActivePersona persona={getLabelByValue(masterPersona)} /> */}
      <Prompts HandlePromptClick={HandlePromptClick} masterPersona={masterPersona}/>
    </div>
    <div className='w-8 h-8'></div>
    </div>
  );
};

export default MasterPersonaPrompts 
