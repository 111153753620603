import ReactMarkdown from "react-markdown";
import { CodeBlock } from "../codeblock";
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";
import rehypeMathjax from "rehype-mathjax";

export const RenderMarkdown = ({ content, index }) => {
  return (
    <ReactMarkdown
      linkTarget={"_blank"}
      remarkPlugins={[remarkGfm, remarkMath]}
      rehypePlugins={[rehypeMathjax]}
      className={`prose dark:prose-invert ml-2.5 pt-0 tracking-[0.14px]`}
      components={{
        code({ node, inline, className, children, ...props }) {
          const match = /language-(\w+)/.exec(className || "");

          return !inline ? (
            <CodeBlock
              key={index}
              language={(match && match[1]) || ""}
              value={String(children).replace(/\n$/, "")}
              {...props}
            />
          ) : (
            <code className={className} {...props}>
              {children}
            </code>
          );
        },
      }}
    >
      {content}
    </ReactMarkdown>
  );
};
