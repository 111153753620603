import { Outlet, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import SidebarAlt from "./sidebarAlt";
import { ToastContainer } from "react-toastify";
import useDarkMode from "../../hooks/useDarkMode";
import { withOrg } from "../organisation/withOrg";
import { CaretCircleLeft } from "@phosphor-icons/react";
import useWindowSize from "../../hooks/useWindowSize";

const style = {
  color: "#6e6e81",
  fontSize: "28px",
  selectedColor: "#666666",
};

function LayoutAlt() {
  const { darkMode } = useDarkMode();
  const { isMobileScreen } = useWindowSize();
  const [sideBarOpen, setSidebarOpen] = useState(!isMobileScreen);
  let navigate = useNavigate();

  // if window size changes, close the sidebar
  useEffect(() => {
    if (isMobileScreen) {
      setSidebarOpen(false);
    } else {
      setSidebarOpen(true);
    }
  }, [isMobileScreen]);

  return (
    <div className="flex flex-row h-dvh w-full">
      {isMobileScreen && (
        <nav className="w-screen fixed top-0 bg-white dark:bg-background-sidebar-alt px-4 pb-4 pt-3 z-30 text-base text-[#1E1E1E] border-b border-[#F0F0F0]">
          <div
            className={`cursor-pointer flex gap-2 items-center w-fit text-[${
              !sideBarOpen ? style.color : "#1E1E1E"
            }]`}
            onClick={() => {
              if (sideBarOpen) {
                navigate("/");
                return;
              }
              setSidebarOpen(true);
            }}
          >
            <CaretCircleLeft size={style.fontSize} />
            <span> Back to {sideBarOpen ? "dashboard" : "settings"}</span>
          </div>
        </nav>
      )}
      <SidebarAlt sideBarOpen={sideBarOpen} setSidebarOpen={setSidebarOpen} />
      <main
        className={`w-full max-h-dvh overflow-y-auto ${
          darkMode && "bg-background-secondary-alt"
        }`}
      >
        <Outlet></Outlet>
      </main>
      <ToastContainer
        toastClassName={({ type }) =>
          type === "error"
            ? "w-[340px] border-l-[12px] border-[#DA3125] rounded-md shadow-lg bg-[#FFF]"
            : type === "success"
            ? "w-[340px] border-l-[12px] border-[#03C04A] rounded-md shadow-lg bg-[#FFF]"
            : type === "warning"
            ? "w-[340px] border-l-[12px] border-[#EA8700] rounded-md shadow-lg bg-[#FFF]"
            : ""
        }
        className="space-y-4  "
      />
    </div>
  );
}

export default withOrg(LayoutAlt);
