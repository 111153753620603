import { getOrganisationsFromKavach } from "../../actions/organisation";
import { ADD_ORGS, SELECT_ORG, SET_ORGS_LOADING } from "../constants/orgs";

export const getOrgs = (navigate) => {
  return (dispatch, getState) => {
    dispatch(loadingOrgs());
    return getOrganisationsFromKavach()
      .then((response) => {
        if (response?.length === 0) {
          navigate("/createOrg");
        }
        dispatch({
          type: "ADD_ORGS",
          payload: response?.map((org) => ({
            ...org?.organisation,
            role: org?.permission?.role,
          })),
        });
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(stopOrgsLoading());
      });
  };
};

export const loadingOrgs = () => ({
  type: SET_ORGS_LOADING,
  payload: true,
});

export const stopOrgsLoading = () => ({
  type: SET_ORGS_LOADING,
  payload: false,
});
