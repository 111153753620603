export const theme = {
  iconSize: {
    small: "16px",
    medium: "24px",
    large: "32px",
  },
  iconColor: {
    primary: "#000",
    secondary: "#798897",
  },
};
