import { useEffect, useState } from "react";
import { Input } from "../../../components/inputs/Input";
import Wrapper from "../../../components/layout/wrapper";
import { getProfile, updateProfile } from "../../../actions/profile";
import { errorToast, successToast } from "../../../util/toasts";
import { useDispatch } from "react-redux";

function Profile() {
  const dispatch = useDispatch();
  const [profileData, setProfileData] = useState({});

  const [formData, setFormData] = useState({
    firstName: {
      value: "" || profileData?.first_name,
      error: "",
    },
    lastName: {
      value: "" || profileData?.last_name,
      error: "",
    },
    displayName: {
      value: "" || profileData?.display_name,
      error: "",
    },
    description: {
      value: "" || profileData?.description,
      error: "",
    },
  });

  useEffect(() => {
    setFormData({
      firstName: {
        value: "" || profileData?.first_name,
        error: "",
      },
      lastName: {
        value: "" || profileData?.last_name,
        error: "",
      },
      displayName: {
        value: "" || profileData?.display_name,
        error: "",
      },
      description: {
        value: "" || profileData?.description,
        error: "",
      },
    });
  }, [profileData]);

  const profileForm = [
    {
      label: "First name",
      labelDescription: "Your first name is to be Given",
      placeholder: "Enter your first name",
      type: "input",
      required: true,
      initialValue: formData?.firstName?.value,
      error: formData?.firstName?.error,
      onChange: (e) => {
        const value = e.target.value;
        if (value) {
          setFormData((prev) => ({
            ...prev,
            firstName: {
              value,
              error: "",
            },
          }));
        } else {
          setFormData((prev) => ({
            ...prev,
            firstName: {
              value,
              error: "First name cannot be empty",
            },
          }));
        }
      },
    },
    {
      label: "Last name",
      labelDescription: "Your last name is to be Given",
      placeholder: "Enter your last name",
      type: "input",
      required: true,
      initialValue: formData?.lastName?.value,
      error: formData?.lastName?.error,
      onChange: (e) => {
        const value = e.target.value;
        if (value) {
          setFormData((prev) => ({
            ...prev,
            lastName: {
              value,
              error: "",
            },
          }));
        } else {
          setFormData((prev) => ({
            ...prev,
            lastName: {
              value,
              error: "Last name cannot be empty",
            },
          }));
        }
      },
    },
    {
      label: "Display name",
      labelDescription:
        "This could be your first name, a nickname or a name you prefer to be called",
      placeholder: "What would you like to be called?",
      type: "input",
      required: true,
      initialValue: formData?.displayName?.value,
      error: formData?.displayName?.error,
      onChange: (e) => {
        const value = e.target.value;
        if (value) {
          setFormData((prev) => ({
            ...prev,
            displayName: {
              value,
              error: "",
            },
          }));
        } else {
          setFormData((prev) => ({
            ...prev,
            displayName: {
              value,
              error: "Display name cannot be empty",
            },
          }));
        }
      },
    },
    // {
    //   label: "Description",
    //   placeholder: "Enter your description",
    //   type: "textarea",
    //   required: false,
    //   initialValue: formData?.description?.value,
    //   error: formData?.description?.error,
    //   onChange: (e) => {
    //     const value = e.target.value;
    //     setFormData((prev) => ({
    //       ...prev,
    //       description: {
    //         value,
    //         error: "",
    //       },
    //     }));
    //   },
    // },
  ];

  const validateForm = () => {
    let isValid = false;
    Object.keys(formData).forEach((key) => {
      // if formData value is empty
      if (!formData[key].value) {
        isValid = true;
        setFormData((prev) => ({
          ...prev,
          [key]: {
            ...prev[key],
            error: `${key} cannot be empty`,
          },
        }));
      }
    });
    return isValid;
  };

  const handleProfileSubmit = () => {
    if (validateForm()) return;

    const payload = {
      first_name: formData.firstName.value,
      last_name: formData.lastName.value,
      display_name: formData.displayName.value,
      description: formData.description.value,
    };

    updateProfile(payload)
      .then(() => {
        successToast("Profile updated successfully");
      })
      .catch(() => {
        errorToast("Unable to update profile. Please try again later.");
      });
  };

  const fetchProfile = async () => {
    getProfile()
      .then((res) => {
        setProfileData(res);
        dispatch({
          type: "ADD_PROFILE",
          payload: res,
        });
      })
      .catch(() => {
        errorToast("Unable to fetch profile. Please try again later.");
      });
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  return (
    <div className="flex flex-col gap-y-4 w-full">
      <div className="flex flex-row justify-end items-end w-full pr-10 py-7 border-b border-[#D1D1D1]">
        <button
          className="bg-[#1E1E1E] text-white rounded-lg py-3 px-2 font-normal w-[144px] h-12"
          onClick={handleProfileSubmit}
        >
          Save
        </button>
      </div>
      <div className="flex flex-col gap-6 mx-auto">
        <div className="w-[60vw] flex flex-col gap-4 mx-auto">
          <div className="flex flex-row justify-between py-6">
            <div className="flex flex-col gap-y-2">
              <h2 className="text-xl font-semibold">Profile Photo</h2>
              <span className="text-[#666] font-normal">
                A Photo or Avatar that represents you
              </span>
            </div>
            <div className="w-[362px]">
              <img
                className="inline-block h-16 w-16 rounded-full ring-2 ring-white"
                src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                alt=""
              />
              <button className="w-[144px] h-12 rounded-lg px-4 bg-[transparent]">
                Upload Photo
              </button>
            </div>
          </div>
          {profileForm.map((form, index) => {
            return (
              <div className="flex flex-row justify-between py-6" key={index}>
                <div className="flex flex-col gap-y-2">
                  <h2 className="text-xl font-semibold">{form.label}</h2>
                  <span className="text-[#666] font-normal">
                    {form.labelDescription}
                  </span>
                </div>
                <input
                  className="w-[362px] h-12 rounded-lg border border-[#D1D1D1] px-4 outline-none focus:ring-1 ring-black"
                  placeholder={form.placeholder}
                  type={form.type}
                  required={form.required}
                  onChange={form.onChange}
                  value={form.value}
                  error={form.error}
                />
              </div>
            );
          })}
          <div className="flex flex-row justify-between py-6">
            <div className="flex flex-col gap-y-2">
              <h2 className="text-xl font-semibold">Delete Account</h2>
              <span className="text-[#666] font-normal">
                Remember, this action is irreversible
              </span>
            </div>
            <div className="w-[362px]">
              <button className="w-[144px] h-12 rounded-lg px-4 bg-red-500 text-white">
                Delete Account
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
