import Layout from "../components/layout/layout";
import LayoutAlt from "../components/layout/layoutAlt";
import { HomePage } from "../pages";
import DocumentPage from "../pages/documents";
import Document from "../pages/documents/create";
import ImagePage from "../pages/images";
import History from "../pages/history/history";
import Playground from "../pages/natdev/Playground";
import Compare from "../pages/natdev/Compare";
import Personas from "../pages/personas";
import CreatePersona from "../pages/personas/create";
import EditPersona from "../pages/personas/edit";
import PersonaChat from "../pages/personas/chat";
import { Workflow } from "../pages/workflow";
import Usage from "../pages/usage";
import Profile from "../pages/settings/profile";
import SettingsOrganisation from "../pages/settings/organisation";
import OrgMembers from "../pages/settings/organisation/members";
import InviteMembers from "../pages/settings/organisation/inviteMembers";
import Invitations from "../pages/settings/profile/invitations";
import Login from "../pages/auth/login";
import AuthLayout from "../pages/auth/authlayout";
import Signup from "../pages/auth/signup";
import Authentication from "../pages/settings/authentication";
import { VerifyEmail } from "../pages/verifyEmail";
import { Verification } from "../pages/auth/verification";
import { ForgotPassword } from "../pages/auth/forgotPassword";
import Organisation from "../pages/organisation/organisation";
import ShareChat from "../pages/chats/shareChat";
import Main from "../pages/chats/main";
import Preferences from "../pages/settings/profile/preferences";
import NotificationSettings from "../pages/settings/profile/notificationSettings";
import AssistantChat from "../pages/assistants/chat";
import FactlyPersona from "../pages/personas/factlyPersona";
import PersonaAccess from "../pages/personas/access";
import Auth from "../auth";
import { FactlyShareChat } from "../pages/personas/factly-share";

export const routes = [
  {
    path: "/",
    element: <Auth />,
    children: [
      {
        path: "/",
        element: <Layout />,
        children: [
          {
            path: "/",
            element: <HomePage />,
          },
          {
            path: "/documents",
            element: <DocumentPage />,
          },
          {
            path: "*",
            element: <>error</>,
          },
          {
            path: "/images",
            element: <ImagePage />,
          },
          {
            path: "/personas",
            element: <Personas />,
          },
          {
            path: "/workflows",
            element: <Workflow />,
          },
          {
            path: "/personas/access",
            element: <PersonaAccess />,
          },
        ],
      },
      {
        path: "/",
        element: <LayoutAlt />,
        children: [
          {
            path: "/history",
            element: <History />,
          },
          {
            path: "/usage",
            element: <Usage />,
          },
          {
            path: "/profile",
            element: <Profile />,
          },
          {
            path: "/org",
            element: <SettingsOrganisation />,
          },
          {
            path: "/profile/preferences",
            element: <Preferences />,
          },
          {
            path: "/profile/notification-settings",
            element: <NotificationSettings />,
          },
          {
            path: "/org/members",
            element: <OrgMembers />,
          },
          {
            path: "/org/members/invite",
            element: <InviteMembers />,
          },
          {
            path: "/profile/invitations",
            element: <Invitations />,
          },
          {
            path: "/passwords",
            element: <Authentication />,
          },
        ],
      },
      {
        path: "/personas/create",
        element: <CreatePersona />,
      },
      {
        path: "/personas/:id/edit",
        element: <EditPersona />,
      },
      {
        path: "/documents/create",
        element: <Document />,
      },

      {
        path: "/chats/dashboard",
        element: <Main />,
      },
      {
        path: "personas/:id/chat",
        element: <PersonaChat />,
      },
      {
        path: "/playground",
        element: <Playground />,
      },
      {
        path: "/compare",
        element: <Compare />,
      },
      {
        path: "/verifyEmail",
        element: <VerifyEmail />,
      },
      {
        path: "/",
        element: <AuthLayout />,
        children: [
          {
            path: "/auth/login",
            element: <Login title="Welcome Back! Sign in" />,
          },
          {
            path: "/auth/registration",
            element: <Signup title="Welcome! Sign up" />,
          },
          {
            path: "/auth/verification",
            element: <Verification title={"Verify your Email"} />,
          },
          {
            path: "/auth/recovery",
            element: <ForgotPassword title="Forgot Password" />,
          },
        ],
      },
      {
        path: "/createOrg",
        element: <Organisation />,
      },
      {
        path: "/chat/shared/:id",
        element: <ShareChat />,
      },
      {
        path: "/persona/shared/:id",
        element: <FactlyShareChat />,
      },
      {
        path: "/assistants/chat",
        element: <AssistantChat />,
      },
      {
        path: "/personas/factly",
        element: <FactlyPersona />,
      },
    ],
  },
];

// export const routes =
