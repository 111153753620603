export const langMap = {
    cmn: 'zh-CN', // Mandarin Chinese
    spa: 'es-ES', // Spanish
    eng: 'en-US', // English
    rus: 'ru-RU', // Russian
    arb: 'ar-SA', // Standard Arabic
    ben: 'bn-BD', // Bengali
    hin: 'hi-IN', // Hindi
    por: 'pt-PT', // Portuguese
    ind: 'id-ID', // Indonesian
    jpn: 'ja-JP', // Japanese
    fra: 'fr-FR', // French
    deu: 'de-DE', // German
    jav: 'jv-ID', // Javanese
    kor: 'ko-KR', // Korean
    tel: 'te-IN', // Telugu
    vie: 'vi-VN', // Vietnamese
    mar: 'mr-IN', // Marathi
    ita: 'it-IT', // Italian
    tam: 'ta-IN', // Tamil
    tur: 'tr-TR', // Turkish
    urd: 'ur-PK', // Urdu
    guj: 'gu-IN', // Gujarati
    pol: 'pl-PL', // Polish
    ukr: 'uk-UA', // Ukrainian
    kan: 'kn-IN', // Kannada
    mai: 'mai-IN', // Maithili
    mal: 'ml-IN', // Malayalam
    pes: 'fa-IR', // Iranian Persian
    mya: 'my-MM', // Burmese
    swh: 'sw-TZ', // Swahili
    sun: 'su-ID', // Sundanese
    ron: 'ro-RO', // Romanian
    pan: 'pa-IN', // Panjabi
    bho: 'bho-IN', // Bhojpuri
    amh: 'am-ET', // Amharic
    hau: 'ha-NG', // Hausa
    fuv: 'fuv-NG', // Nigerian Fulfulde
    bos: 'bs-BA', // Bosnian
    hrv: 'hr-HR', // Croatian
    nld: 'nl-NL', // Dutch
    srp: 'sr-RS', // Serbian
    tha: 'th-TH', // Thai
    ckb: 'ku-IQ', // Central Kurdish
    yor: 'yo-NG', // Yoruba
    uzn: 'uz-UZ', // Northern Uzbek
    zlm: 'ms-MY', // Malay
    ibo: 'ig-NG', // Igbo
    npi: 'ne-NP', // Nepali
    ceb: 'ceb-PH', // Cebuano
    skr: 'skr-PK', // Saraiki
    tgl: 'tl-PH', // Tagalog
    hun: 'hu-HU', // Hungarian
    azj: 'az-AZ', // North Azerbaijani
    sin: 'si-LK', // Sinhala
    koi: 'koi-RU', // Komi-Permyak
    ell: 'el-GR', // Modern Greek
    ces: 'cs-CZ', // Czech
    mag: 'mag-IN', // Magahi
    run: 'rn-BI', // Rundi
    bel: 'be-BY', // Belarusian
    plt: 'plt-MG', // Plateau Malagasy
    qug: 'qug-EC', // Chimborazo Highland Quichua
    mad: 'mad-ID', // Madurese
    nya: 'ny-MW', // Nyanja
    zyb: 'zyb-CN', // Yongbei Zhuang
    pbu: 'pbu-PK', // Northern Pashto
    kin: 'rw-RW', // Kinyarwanda
    zul: 'zu-ZA', // Zulu
    bul: 'bg-BG', // Bulgarian
    swe: 'sv-SE', // Swedish
    lin: 'ln-CD', // Lingala
    som: 'so-SO', // Somali
    hms: 'hms-CN', // Southern Qiandong Miao
    hnj: 'hnj-CN', // Hmong Njua
    ilo: 'ilo-PH', // Iloko
    kaz: 'kk-KZ', // Kazakh
  };

  