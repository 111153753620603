import { Trash } from "@phosphor-icons/react/dist/ssr";
import { theme } from "../../commons/styles";
import { ButtonWithIcon } from "../buttons/buttonWithIcon";
import {
  CaretCircleLeft,
  ChatTeardrop,
  MagnifyingGlass,
  PlusCircle,
  SidebarSimple,
  Check,
  X,
} from "@phosphor-icons/react";
import { memo, useState } from "react";

function areEqual(prevProps, nextProps) {
  return (
    prevProps?.chatHistory === nextProps?.chatHistory &&
    prevProps?.chat === nextProps?.chat
  );
}

export const ChatSidebar =
  //  memo(
  ({
    sidebarVisible,
    toggleSidebar,
    chatHistory,
    setPagination,
    enableSearch = true,
    chat,
    pagination,
    handleSelectChat,
    handleNewChat,
    navigate,
    handleDeleteChat,
    disableNewChat = false,
    containerStyles
  }) => {
    const [deleteIndex, setDeleteIndex] = useState(null);

    return (
      <div
        className={`max-w-[344px] -md:p-4   -md:z-[100] -md:absolute -md:w-[75vw] ${
          sidebarVisible ? "flex-[3] px-10" : "w-0 px-0"
        } bg-sidebar h-full border-r border-r-primary-border  py-6 flex flex-col transition-transform ${
          sidebarVisible ? "translate-x-0" : "-translate-x-full"
        } ease-in-out duration-300 gap-7`}
        key={"history-" + chatHistory?.results?.length}
        style={containerStyles}
      >
        {sidebarVisible && (
          <>
            <div className="w-full grid grid-cols-[8fr_2fr] items-center">
              <ButtonWithIcon
                icon={<PlusCircle size={24} color="#fff" />}
                isPrimary={true}
                onClick={() => {
                  handleNewChat();
                }}
                disabled={
                  disableNewChat
                    ? disableNewChat
                    : chat?.messages && chat?.messages?.length === 0
                }
              >
                New Chat
              </ButtonWithIcon>
              <div className="p-3">
                <SidebarSimple
                  weight="duotone"
                  className="text-black-25 border rounded-full p-1"
                  size={32}
                  cursor={"pointer"}
                  onClick={() => toggleSidebar()}
                />
              </div>
            </div>
            {enableSearch && (
              <div className="flex items-center gap-2 px-2 py-3 border border-[#d1d1d1] bg-white rounded-lg">
                <MagnifyingGlass size={theme.iconSize.medium} color={"#666"} />
                <input
                  className="w-full text-[#1E1E1E] text-base outline-none"
                  placeholder="Try using search"
                  onChange={(e) => {
                    setPagination({
                      ...pagination,
                      search_query: e.target.value,
                    });
                  }}
                />
              </div>
            )}
            <div className="flex flex-col w-full overflow-y-scroll mb-6">
              <p className="font-semibold">
                {pagination?.search_query
                  ? `Search results for: ${pagination?.search_query}`
                  : "Recent Chats"}
              </p>
              {chatHistory?.results?.map((chatItem, index) => {
                return (
                  <div
                    className={`p-2 w-full flex items-center gap-2 ${
                      chatItem?.id === chat?.id ? "bg-[#F3EED9]" : ""
                    } rounded-md cursor-pointer`}
                    onClick={() => handleSelectChat(chatItem)}
                  >
                    <ChatTeardrop
                      size={theme.iconSize.medium}
                      color={chatItem?.id === chat?.id ? "#000" : "#666"}
                      weight={chatItem?.id === chat?.id ? "fill" : "regular"}
                    />
                    <span className="text-base max-w-[70%] truncate">
                      {chatItem.title}
                    </span>
                    {deleteIndex === index ? (
                      <div className="flex items-center gap-1 ml-auto">
                        <Check
                          size={theme.iconSize.small}
                          color={"#000"}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteChat(chatItem.id);
                            setDeleteIndex(null);
                          }}
                        />
                        <X
                          size={theme.iconSize.small}
                          color={"#000"}
                          onClick={(e) => {
                            e.stopPropagation();
                            setDeleteIndex(null);
                          }}
                        />
                      </div>
                    ) : (
                      <Trash
                        size={theme.iconSize.small}
                        color={chatItem?.id === chat?.id ? "#000" : "#666"}
                        weight={chatItem?.id === chat?.id ? "fill" : "regular"}
                        className="ml-auto"
                        onClick={(e) => {
                          e.stopPropagation();
                          setDeleteIndex(index);
                          // handleDeleteChat(chatItem.id);
                        }}
                      />
                    )}
                  </div>
                );
              })}
            </div>
            <div
              className="flex items-center p-2 justify-center gap-2 cursor-pointer bottom-2 fixed bg-sidebar"
              onClick={() => {
                navigate();
              }}
            >
              <CaretCircleLeft
                size={theme.iconSize.medium}
                color={"#798897"}
                weight="fill"
              />
              <span className="text-[#798897] hover:scale-105 transition-colors duration-200">
                Back to dashboard
              </span>
            </div>
          </>
        )}
      </div>
    );
  };
//   areEqual
// );
